export class CounterSignatureResult {
  static readonly MAX_NUMBER_OF_SIGNATURES = 20;

  userSuccessfullySigned = false;
  isError = false;
  numberOfSignature = 0;

  setUserSuccessfullySigned(userSuccessfullySigned: boolean): CounterSignatureResult {
    this.userSuccessfullySigned = userSuccessfullySigned;
    return this;
  }

  setNumberOfSignature(numberOfSignature: number): CounterSignatureResult {
    this.numberOfSignature = numberOfSignature;
    return this;
  }

  setError(isError: boolean): CounterSignatureResult {
    this.isError = isError;
    return this;
  }

  hasInvalidNumberOfSignatures(): boolean {
    return this.numberOfSignature === CounterSignatureResult.MAX_NUMBER_OF_SIGNATURES;
  }
}
