import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../service/calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeadlinesEvent } from '../../../common/model/deadlines-event';
import { LocalDate } from '@js-joda/core';
import { CalendarDeadlinesActionType } from '../../model/calendar-deadlines-action-type';
import { Eakte } from '../../../common/model/eakte';

@Component({
  selector: 'app-calendar-event-details',
  templateUrl: './calendar-event-details.component.html',
  styleUrls: ['./calendar-event-details.component.css']
})
export class CalendarEventDetailsComponent implements OnInit {
  calendarNavigationLink = '';
  eventDetailsLink = '';
  deadlinesEvent?: DeadlinesEvent | null;
  dependingOnEvent?: DeadlinesEvent | null;
  superiorToEvents: DeadlinesEvent[] = [];
  isErrorOnLoad = false;
  isLoading = false;
  canEventDeleted = false;
  isErrorOnDelete = false;
  eakte: Eakte;

  constructor(private calendarService: CalendarService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.calendarService.setContextAndId(this.activatedRoute.snapshot.paramMap.get('context'), this.activatedRoute.snapshot.paramMap.get('id'));

    if (this.calendarService.shouldRedirect()) {
      this.router.navigate(['/deadlines/search']);
    } else {
      this.isLoading = true;
      this.calendarNavigationLink = this.calendarService.getCalendarNavigationLink();
      this.calendarService
        .loadDeadlineEventDetails(this.activatedRoute, CalendarDeadlinesActionType.EVENT_DETAILS)
        .subscribe((calendarDeadlinesDetails) => {
          this.isErrorOnLoad = calendarDeadlinesDetails.isErrorOnLoad;
          this.eventDetailsLink = calendarDeadlinesDetails.eventDetailsLink;
          this.deadlinesEvent = calendarDeadlinesDetails.deadlinesEvent;
          this.dependingOnEvent = calendarDeadlinesDetails.dependingOnEvent;
          this.superiorToEvents = calendarDeadlinesDetails.superiorToEvents;
          this.canEventDeleted = this.superiorToEvents.length === 0;
          this.eakte = calendarDeadlinesDetails.eakte;
          this.isLoading = false;
        });
    }
  }

  formatDate(localDate?: LocalDate): string {
    return this.calendarService.formatDate(localDate);
  }

  convertCategory(category?: string): string {
    if (category) {
      return DeadlinesEvent.getCategory(category);
    }
    return '';
  }

  convertState(state?: string): string {
    if (state) {
      return DeadlinesEvent.getState(state);
    }
    return '';
  }

  deleteDeadline(): void {
    this.isLoading = true;
    if (this.deadlinesEvent && this.deadlinesEvent.id) {
      this.calendarService.deleteDeadline(this.deadlinesEvent.id).subscribe((deadlinesEventResponse) => {
        this.isLoading = false;
        if (deadlinesEventResponse.isError) {
          this.isErrorOnDelete = true;
        } else {
          this.router.navigate([this.calendarNavigationLink]);
        }
      });
    }
  }

  edit(): void {
    this.router.navigate([this.calendarService.getEventEditLink(this.deadlinesEvent?.id)]);
  }
}
