export class DeadlinesNavigationActiveLink {
  activeSearchLink = false;
  activeAgendaLink = false;
  activeCalendarLink = false;

  constructor(activeSearchLink: boolean, activeAgendaLink: boolean, activeCalendarLink: boolean) {
    this.activeSearchLink = activeSearchLink;
    this.activeAgendaLink = activeAgendaLink;
    this.activeCalendarLink = activeCalendarLink;
  }
}
