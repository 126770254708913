import { DeadlinesEvent } from './deadlines-event';

export class DeadlinesEventResponse {
  isError: boolean;
  deadlinesEvent: DeadlinesEvent | null;

  constructor(isError: boolean, deadlinesEvent: DeadlinesEvent | null) {
    this.isError = isError;
    this.deadlinesEvent = deadlinesEvent;
  }
}
