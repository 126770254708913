<div class='calendar-nav-container'>
  <div class='calendar-nav-new-event'>
    <button mat-raised-button color='primary' (click)='newCalendarEvent()'>Neu</button>
  </div>
  <div class='calendar-nav-date-selection'>
    <button mat-button color='primary' (click)='previousView()'>
      <img src='assets/images/chevron_left_black_24dp.svg' alt='left'>
    </button>
    <button mat-button color='primary' (click)='nextView()'>
      <img src='assets/images/chevron_right_black_24dp.svg' alt='left'>
    </button>
    <span>{{calendarLabel}}</span>
    <mat-form-field appearance='fill'>
      <mat-label>Datum</mat-label>
      <input matInput [matDatepicker]='picker' readonly [formControl]='date' (dateChange)='pickDateView()'>
      <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class='calendar-nav-search'>
    <mat-form-field appearance='fill'>
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Suche Fristen</mat-label>
      <input matInput type='search' [formControl]='searchFieldControl' autocomplete='off'>
    </mat-form-field>
  </div>
  <div class='calendar-nav-actions'>
    <button *ngIf='isWeeklyWorkingView' mat-raised-button color='primary' (click)='weeklyWorkingView()'>Arbeitswoche</button>
    <button *ngIf='!isWeeklyWorkingView' mat-button color='primary' (click)='weeklyWorkingView()'>Arbeitswoche</button>
    <button *ngIf='isWeeklyView' mat-raised-button color='primary' (click)='weeklyView()'>Woche</button>
    <button *ngIf='!isWeeklyView' mat-button color='primary' (click)='weeklyView()'>Woche</button>
    <button *ngIf='isMonthlyView' mat-raised-button color='primary' (click)='monthlyView()'>Monat</button>
    <button *ngIf='!isMonthlyView' mat-button color='primary' (click)='monthlyView()'>Monat</button>
    <button *ngIf='isActualDayView' mat-raised-button color='primary' (click)='actualDayView()'>Heute</button>
    <button *ngIf='!isActualDayView' mat-button color='primary' (click)='actualDayView()'>Heute</button>
  </div>
</div>
<mat-divider></mat-divider>
