import { Component, Input, OnInit } from '@angular/core';
import { CalendarDeadlinesMonthlyModel, DailyEventsOfMonth } from '../../model/calendar-deadlines-monthly-model';
import { DeadlinesEvent } from '../../../common/model/deadlines-event';
import { CalendarService } from '../../service/calendar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-monthly-view',
  templateUrl: './calendar-monthly-view.component.html',
  styleUrls: ['./calendar-monthly-view.component.css']
})
export class CalendarMonthlyViewComponent implements OnInit {
  @Input() calendarDeadlinesMonthlyModel?: CalendarDeadlinesMonthlyModel;

  selectedDailyEventsOfMonth?: DailyEventsOfMonth | null;
  selectedDeadlinesEvent?: DeadlinesEvent | null;

  constructor(private calendarService: CalendarService, private router: Router) {}

  ngOnInit(): void {
    this.selectedDailyEventsOfMonth = this.calendarDeadlinesMonthlyModel?.dailyEventsOfMonth;
  }

  showDailyEventsOfMonth(dailyEventsOfMonth: DailyEventsOfMonth): void {
    this.selectedDeadlinesEvent = null;
    this.selectedDailyEventsOfMonth = dailyEventsOfMonth;
    this.calendarService.saveCalendarDate(this.selectedDailyEventsOfMonth.localDate);
    this.calendarService.triggerUpdateNavigationDate();
  }

  newEvent(dailyEventsOfMonth: DailyEventsOfMonth): void {
    this.showDailyEventsOfMonth(dailyEventsOfMonth);
    this.router.navigate([this.calendarService.getEventNewLink()]);
  }

  showDailyEvent(deadlinesEvent: DeadlinesEvent): void {
    this.selectedDeadlinesEvent = deadlinesEvent;
    this.router.navigate([this.calendarService.getEventDetailsLink(deadlinesEvent.id)]);
  }
}
