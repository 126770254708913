<div class="dashlet-search-container">
  <button mat-button [hidden]="true" [ngClass]="isRecentSearchSelected ? 'selected-search-type' : ''" (click)="viewRecentLibraries()">Letzte</button>
  <button
    mat-button
    [ngClass]="isFavoriteSearchSelected ? 'selected-search-type' : ''"
    (click)="viewFavoriteLibraries()"
  >Favoriten</button>
  <mat-form-field>
    <mat-label>Suche</mat-label>
    <input matInput [placeholder]="'Suche'" [formControl]="searchFieldControl" />
  </mat-form-field>
  <mat-select class="search-type-select" [(value)]="selectedSearchFilter" (selectionChange)="changeSelectedSearchFilter()">
    <mat-option *ngFor="let option of searchFilterOptions" value="{{ option.key }}">{{ option.value }}</mat-option>
  </mat-select>
</div>
