<div class="dialog-container">
  <div class="dialog-column">
    <h3>Dokument in PDF konvertieren</h3>
  </div>

  <ng-template [ngIf]="isLoading" [ngIfElse]="dialogContent">
    <div>
      <mat-progress-spinner mode="indeterminate" class="dialog-spinner"></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #dialogContent>
    <div *ngIf="!convertedNodeId && !isError" class="dialog-column dialog-column-content">
      Klicken Sie auf "Konvertieren", um die Konvertierung zu starten.
    </div>
    <div *ngIf="convertedNodeId && !isError" class="dialog-column dialog-column-content">
      Konvertierung erfolgreich abgeschlossen.
    </div>
    <div *ngIf="isError" class="dialog-column dialog-column-content">
      Ein Fehler ist aufgetreten. Das Dokument konnte nicht konvertiert werden.
    </div>
    <div class="dialog-column dialog-column-actions">
      <button mat-button mat-dialog-close class="dialog-actions dialog-close-action" (click)="reload()">
        Schließen
      </button>
      <button *ngIf="!convertedNodeId && !isError"
              mat-raised-button
              color="primary"
              class="dialog-actions"
              (click)="convertDocument()">
        Konvertieren
      </button>
    </div>
  </ng-template>
</div>
