import { Injectable } from '@angular/core';
import { AppConfigService, AuthenticationService } from '@alfresco/adf-core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CounterSignatureResult } from '../model/counter-signature-result';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CounterSignatureService {
  constructor(private authenticationService: AuthenticationService, private appConfig: AppConfigService, private http: HttpClient) {}

  signDocument(nodeId: string, comment: string): Observable<CounterSignatureResult> {
    return this.http
      .post<CounterSignatureResult>(
        `${this.getPzsUisApi()}${CounterSignatureService.getCounterSignaturesApiPath(nodeId)}`,
        {
          comment
        },
        this.createHttpOptions()
      )
      .pipe(
        map((result) => {
          if (result.numberOfSignature === CounterSignatureResult.MAX_NUMBER_OF_SIGNATURES) {
            return new CounterSignatureResult().setError(true).setNumberOfSignature(result.numberOfSignature);
          }
          return new CounterSignatureResult().setUserSuccessfullySigned(true);
        }),
        catchError(() => of(new CounterSignatureResult().setError(true)))
      );
  }

  private static getCounterSignaturesApiPath(nodeId: string): string {
    return `/documents/${nodeId}/unterschrift/intern`;
  }

  private getPzsUisApi(): string {
    return `${this.appConfig.get<string>('ecmHost')}/api/ui`;
  }

  private createHttpOptions(): { headers: HttpHeaders } {
    const token = this.authenticationService.getToken();
    return {
      headers: new HttpHeaders().set('content-type', 'application/json').set('Authorization', `Bearer ${token}`)
    };
  }
}
