import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterSignatureContextItemComponent } from './counter-signature-context-item/counter-signature-context-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { CounterSignatureService } from './service/counter-signature.service';
import { CounterSignatureDialogComponent } from './counter-signature-dialog/counter-signature-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExtensionService } from '@alfresco/adf-extensions';
import * as counterSignatureRules from '../../../counter-signature/src/rules/counter-signature-rules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [CounterSignatureContextItemComponent, CounterSignatureDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  providers: [CounterSignatureService]
})
export class CounterSignatureModule {
  constructor(extensions: ExtensionService) {
    extensions.setComponents({
      'app.context.menu.counterSignature': CounterSignatureContextItemComponent
    });

    extensions.setEvaluators({
      canCounterSignature: counterSignatureRules.canCounterSignature
    });
  }
}
