import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeadlinesAgendaService } from '../service/deadlines-agenda.service';
import { DeadlinesAgendaTimeRange } from '../../common/model/deadlines-agenda-time-range';
import { DeadlinesEvent } from '../../common/model/deadlines-event';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocalDate } from '@js-joda/core';
import { CalendarDeadlinesSelectedViewType } from '../../calendar/model/calendar-deadlines-selected-view-type';
import { DeadlinesNavigation } from '../../common/model/deadlines-navigation';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-deadlines-agenda',
  templateUrl: './deadlines-agenda.component.html',
  styleUrls: ['./deadlines-agenda.component.css']
})
export class DeadlinesAgendaComponent implements OnInit, OnDestroy {
  timeRange = DeadlinesAgendaTimeRange.TOTAL;
  isLoading = false;
  deadlineEventStep = -1;
  deadlinesEvents: DeadlinesEvent[] = [];
  showPrintPreview = false;
  eventNewLink = '';
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  skipCount = 0;
  maxItems = 10;
  userRoleTrigger$: Subscription;

  constructor(
    private deadlinesAgendaService: DeadlinesAgendaService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.deadlinesAgendaService.setContextAndId(
      this.activatedRoute.snapshot.paramMap.get('context'),
      this.activatedRoute.snapshot.paramMap.get('id')
    );

    if (this.deadlinesAgendaService.shouldRedirect()) {
      this.router.navigate(['/deadlines/electronic-file-lookup']);
    } else {
      this.timeRange = this.deadlinesAgendaService.getAgendaTimeRange();
      this.pageIndex = this.skipCount / this.maxItems;
      this.pageSize = this.maxItems;

      this.eventNewLink = this.deadlinesAgendaService.getEventNewLink();
      this.deadlinesAgendaService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);
      this.deadlinesAgendaService.setContextNavigation(DeadlinesNavigation.AGENDA);

      this.activatedRoute.queryParams.subscribe((params) => {
        let timeRangeParam = params['timeRange'];
        if (!timeRangeParam) {
          timeRangeParam = this.timeRange;
        }
        this.searchDeadlinesByTimeRange(timeRangeParam);
      });

      this.userRoleTrigger$ = this.deadlinesAgendaService.getUserRoleTrigger().subscribe(() => {
        this.searchDeadlinesByTimeRange(this.timeRange);
      });

      this.deadlinesAgendaService.searchDeadlinesByTimeRangeSubject$.subscribe((deadlinesEventsSearchResponse) => {
        this.deadlinesEvents = deadlinesEventsSearchResponse.items;
        this.deadlineEventStep = -1;
        this.length = deadlinesEventsSearchResponse.pagination.total;
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.userRoleTrigger$.unsubscribe();
  }

  getEventEditLink(deadlineId: string): string {
    return this.deadlinesAgendaService.getEventEditLink(deadlineId);
  }

  printPreview(): void {
    this.showPrintPreview = true;
  }

  closePreview(): void {
    this.showPrintPreview = false;
  }

  setDeadlineEventStep(index: number) {
    this.deadlineEventStep = index;
  }

  nextDeadlineEventStep() {
    this.deadlineEventStep++;
  }

  prevDeadlineEventStep() {
    this.deadlineEventStep--;
  }

  formatDate(localDate: LocalDate): string {
    return this.deadlinesAgendaService.formatDate(localDate);
  }

  convertCategory(category: string): string {
    return DeadlinesEvent.getCategory(category);
  }

  convertState(state: string): string {
    return DeadlinesEvent.getState(state);
  }

  setTimeRange(timeRange: string): void {
    switch (timeRange) {
      case DeadlinesAgendaTimeRange.WEEK: {
        this.timeRange = DeadlinesAgendaTimeRange.WEEK;
        this.deadlinesAgendaService.setAgendaTimeRange(this.timeRange);
        break;
      }
      case DeadlinesAgendaTimeRange.MONTH: {
        this.timeRange = DeadlinesAgendaTimeRange.MONTH;
        this.deadlinesAgendaService.setAgendaTimeRange(this.timeRange);
        break;
      }
      case DeadlinesAgendaTimeRange.TOTAL: {
        this.timeRange = DeadlinesAgendaTimeRange.TOTAL;
        this.deadlinesAgendaService.setAgendaTimeRange(this.timeRange);
        break;
      }
      default: {
        this.timeRange = DeadlinesAgendaTimeRange.TODAY;
        this.deadlinesAgendaService.setAgendaTimeRange(this.timeRange);
      }
    }
  }

  dailyTimeRange(): string {
    return DeadlinesAgendaTimeRange.TODAY;
  }

  weeklyTimeRange(): string {
    return DeadlinesAgendaTimeRange.WEEK;
  }

  monthlyTimeRange(): string {
    return DeadlinesAgendaTimeRange.MONTH;
  }

  totalTimeRange(): string {
    return DeadlinesAgendaTimeRange.TOTAL;
  }

  getPageEvent(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.skipCount = pageEvent.pageIndex * pageEvent.pageSize;
    this.maxItems = pageEvent.pageSize;

    this.searchDeadlinesByTimeRange(this.timeRange);
  }

  searchDeadlinesByTimeRange(timeRange: string): void {
    this.isLoading = true;
    this.setTimeRange(timeRange);
    this.updateURLWithNewParamsWithoutReloading();
    this.deadlinesAgendaService.triggerSearchDeadlinesByTimeRange(timeRange, this.skipCount, this.maxItems);
  }

  private updateURLWithNewParamsWithoutReloading(): void {
    let path = location.href.split('#')[1];

    if (path.includes('?')) {
      path = path.split('?')[0];
    }

    this.location.replaceState(`${path}?timeRange=${this.timeRange}`);
  }
}
