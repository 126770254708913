import { Component, Inject } from '@angular/core';
import { Node, NodeChildAssociation } from '@alfresco/js-api';
import { PdfConversionService } from '../service/pdf-conversion.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'pdf-conversion-dialog',
  templateUrl: './pdf-conversion-dialog.component.html',
  styleUrls: ['./pdf-conversion-dialog.component.scss'],
})
export class PdfConversionDialogComponent {
  selectedNode: NodeChildAssociation | Node;
  isLoading = false;
  convertedNodeId?: string;
  isError = false;

  constructor(
    private pdfConversionService: PdfConversionService,
    private router: Router,
    public dialogRef: MatDialogRef<PdfConversionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public node: NodeChildAssociation | Node,
  ) {
    this.selectedNode = node;
  }

  convertDocument(): void {
    this.isLoading = true;
    this.pdfConversionService
      .convertDocument(this.selectedNode.id)
      .subscribe((result) => {
        this.convertedNodeId = result.nodeId;
        this.isError = result.isError;
        this.isLoading = false;
      });
  }

  reload(): void {
    if (this.convertedNodeId && !this.isError) {
      if (this.selectedNode instanceof NodeChildAssociation) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([this. router. url]);
      } else {
        const actualUrl = this.router.url;
        const newUrl = actualUrl.replace(
          this.selectedNode.id,
          this.convertedNodeId,
        );
        this.router.navigateByUrl(newUrl, {skipLocationChange: true});
      }
    }
  }
}
