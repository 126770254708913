import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@alfresco/adf-core';

@Injectable()
export class AuthService {
  constructor(private authenticationService: AuthenticationService) {}

  createHttpOptions(): { headers: HttpHeaders } {
    const token = this.authenticationService.getToken();
    return {
      headers: new HttpHeaders().set('content-type', 'application/json').set('Authorization', `Bearer ${token}`)
    };
  }
}
