import { DeadlinesEvent } from '../../common/model/deadlines-event';
import { LocalDate } from '@js-joda/core';

export class CalendarDeadlinesMonthlyModel {
  dailyHeaderOfMonth: string[];
  dailyDeadlines: DailyEventsOfMonth[][];
  dailyEventsOfMonth: DailyEventsOfMonth | null;

  constructor(
    dailyHeaderOfMonth: string[],
    dailyDeadlines: DailyEventsOfMonth[][],
    dailyEventsOfMonth: DailyEventsOfMonth | null,
  ) {
    this.dailyHeaderOfMonth = dailyHeaderOfMonth;
    this.dailyDeadlines = dailyDeadlines;
    this.dailyEventsOfMonth = dailyEventsOfMonth;
  }
}

export class DailyEventsOfMonth {
  localDate: LocalDate;
  dailyLabel: string;
  extendedDailyLabel: string;
  isActualDay: boolean;
  dailyDeadlinesWithOutTime: DeadlinesEvent[];
  dailyDeadlinesWithTime: DeadlinesEvent[];
  totalDayEvents: number;
  firstEvent?: DeadlinesEvent;

  constructor(
    localDate: LocalDate,
    dailyLabel: string,
    extendedDailyLabel: string,
    isActualDay: boolean,
    dailyDeadlinesWithOutTime: DeadlinesEvent[],
    dailyDeadlinesWithTime: DeadlinesEvent[],
  ) {
    this.localDate = localDate;
    this.dailyLabel = dailyLabel;
    this.extendedDailyLabel = extendedDailyLabel;
    this.isActualDay = isActualDay;
    this.dailyDeadlinesWithOutTime = dailyDeadlinesWithOutTime;
    this.dailyDeadlinesWithTime = dailyDeadlinesWithTime;
    this.totalDayEvents = this.dailyDeadlinesWithOutTime.length + this.dailyDeadlinesWithTime.length;

    if (this.dailyDeadlinesWithOutTime.length > 0) {
      this.firstEvent = this.dailyDeadlinesWithOutTime[0];
    }

    if (this.dailyDeadlinesWithTime.length > 0 && !this.firstEvent) {
      this.firstEvent = this.dailyDeadlinesWithTime[0];
    }
  }
}
