import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionState } from '@alfresco/adf-extensions';
import { Store } from '@ngrx/store';
import { AppStore, getAppSelection } from '@alfresco/aca-shared/store';
import { DashletActionService } from '../../services/dashlet.action.service';

@Component({
  selector: 'aca-toggle-dashlet-favorite',
  template: `
    <button mat-menu-item #favorites="adfFavorite" (toggle)="onToggleEvent()" [adf-node-favorite]="(selection$ | async).nodes">
      <mat-icon *ngIf="favorites.hasFavorites()">star</mat-icon>
      <mat-icon *ngIf="!favorites.hasFavorites()">star_border</mat-icon>
      <span>{{ (favorites.hasFavorites() ? 'APP.ACTIONS.REMOVE_FAVORITE' : 'APP.ACTIONS.FAVORITE') | translate }}</span>
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-toggle-dashlet-favorite' }
})
export class ToggleDashletFavoriteComponent {
  selection$: Observable<SelectionState>;

  constructor(private store: Store<AppStore>, private dashletActionService: DashletActionService) {
    this.selection$ = this.store.select(getAppSelection);
  }

  onToggleEvent() {
    this.dashletActionService.triggerFavoriteDashletViewReload();
  }
}
