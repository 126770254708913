<div class="dialog-container">
  <div class="dialog-column">
    <h3>Dokument unterschreiben</h3>
  </div>

  <ng-template [ngIf]="isLoading" [ngIfElse]="dialogContent">
    <div>
      <mat-progress-spinner mode="indeterminate" class="dialog-spinner"></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #dialogContent>
    <ng-template [ngIf]="!signedDocument" [ngIfElse]="signDocumentResult">
      <div class="dialog-column dialog-column-content">Diese Unterschrift ist keine digitale qualifizierte Signatur!</div>
      <div>
        <form [formGroup]="counterSignatureForm" autocomplete="off" novalidate (ngSubmit)="signDocument(counterSignatureForm.value)">
          <div class="dialog-column dialog-column-actions">
            <mat-form-field appearance="fill">
              <mat-label>Rolle</mat-label>
              <input matInput formControlName="role" id="roleInput" />
              <mat-error *ngIf="hasError('role', 'required') || hasError('role', 'maxlength')">
                Rolle ist ein Plichtfeld mit max. 75 Zeichen.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="dialog-column dialog-column-actions">
            <button mat-button mat-dialog-close class="dialog-actions dialog-close-action" (click)="shouldReload()">Schließen</button>
            <button mat-raised-button color="primary" class="dialog-actions" [disabled]="!counterSignatureForm.valid">Unterschreiben</button>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #signDocumentResult>
      <div *ngIf="successfullySigned()" class="dialog-column dialog-column-content">Dokument erfolgreich unterschrieben.</div>
      <div *ngIf="hasErrorCounterSignatureResult() && !hasInvalidNumberOfSignatures()" class="dialog-column dialog-column-content">
        Ein Fehler ist aufgetreten. Das Dokument konnte nicht unterschrieben werden.
      </div>
      <div *ngIf="hasErrorCounterSignatureResult() && hasInvalidNumberOfSignatures()" class="dialog-column dialog-column-content">
        Ein Dokument kann nur 20 mal unterschrieben werden.
      </div>

      <div class="dialog-column dialog-column-actions">
        <button mat-button mat-dialog-close class="dialog-actions dialog-close-action" (click)="shouldReload()">Schließen</button>
      </div>
    </ng-template>
  </ng-template>
</div>
