import { RuleContext } from '@alfresco/adf-extensions';
import { hasFileSelected, isNotTrashcan } from '@alfresco/aca-shared/rules';

export const canConvertToPdf = (context: RuleContext): boolean =>
  hasFileSelected(context) && isNotTrashcan(context) && (isMimeTypeDoc(context) || isMimeTypeDocx(context) || isMimeTypeOdt(context));

const isMimeTypeDoc = (context: RuleContext): boolean => context.selection.file.entry.content.mimeType === 'application/msword';

const isMimeTypeDocx = (context: RuleContext): boolean =>
  context.selection.file.entry.content.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const isMimeTypeOdt = (context: RuleContext): boolean => context.selection.file.entry.content.mimeType === 'application/vnd.oasis.opendocument.text';
