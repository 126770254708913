<div class='calendar-container-container'>

  <header>
    <app-deadlines-main-navigation></app-deadlines-main-navigation>
    <app-calendar-nav></app-calendar-nav>
  </header>

  <ng-template [ngIf]='isLoading' [ngIfElse]='calendarView'>
    <div class='calendar-container-result'>
      <mat-progress-spinner mode='indeterminate' class='calendar-container-spinner'></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #calendarView>
    <div class='calendar-container-result'>
      <app-calendar-weekly-view *ngIf='isWeeklySelection()'
                                [calendarDeadlinesWeeklyModel]='calendarDeadlinesWeeklyModel'></app-calendar-weekly-view>
      <app-calendar-monthly-view *ngIf='isMonthlySelection()'
                                 [calendarDeadlinesMonthlyModel]='calendarDeadlinesMonthlyModel'></app-calendar-monthly-view>
    </div>
  </ng-template>
</div>
