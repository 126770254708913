export class Eakte {
  id: string;
  name: string;
  projektkoordinatoren?: EakteProjektkoordinator[];
}

export class EakteProjektkoordinator {
  firstname?: string;
  lastname?: string;
}
