import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../service/calendar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeadlinesEvent, DeadlinesEventState } from '../../../common/model/deadlines-event';
import { convert, LocalDate, LocalTime, nativeJs } from '@js-joda/core';
import { CalendarDeadlinesActionType } from '../../model/calendar-deadlines-action-type';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CalendarSearchEventDialogComponent } from '../calendar-search-event-dialog/calendar-search-event-dialog.component';
import { CalendarDeadlinesSelectedViewType } from '../../model/calendar-deadlines-selected-view-type';
import { DeadlinesContext } from '../../../common/model/deadlines-context';
import { DeadlinesEaktenSearch } from '../../../common/model/eakten-search-response';
import { DeadlinesEaktenSearchResultDialogComponent } from '../../../deadlines-eakten-search/component/deadlines-eakten-search-result-dialog.component';
import { DeadlinesNavigation } from '../../../common/model/deadlines-navigation';

@Component({
  selector: 'app-calendar-event-edit',
  templateUrl: './calendar-event-edit.component.html',
  styleUrls: ['./calendar-event-edit.component.css']
})
export class CalendarEventEditComponent implements OnInit {
  private deadlineSource = '';
  private deadlineEakte = '';
  searchValueDependentOn$!: Subscription;
  lastSearchResultDependingOnEvent?: string;
  calendarNavigationLink = '';
  calendarNavigationText = '';
  eventEditLink = '';
  isNewDeadline = true;
  isErrorOnSave = false;
  isErrorOnLoad = false;
  isLoading = false;
  deadlinesEvent?: DeadlinesEvent | null;
  dependingOnEvent?: DeadlinesEvent | null;
  searchValueEakte$!: Subscription;
  lastSearchResultEakte?: string;
  deadlinesEaktenSearch?: DeadlinesEaktenSearch | null;

  deadlineForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(4)]),
    date: new FormControl('', Validators.required),
    time: new FormControl(''),
    state: new FormControl(''),
    source: new FormControl(''),
    category: new FormControl('', Validators.required),
    dependentOn: new FormControl(''),
    isProjectEvent: new FormControl(true)
  });

  constructor(private calendarService: CalendarService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.calendarService.setContextAndId(this.activatedRoute.snapshot.paramMap.get('context'), this.activatedRoute.snapshot.paramMap.get('id'));

    if (this.calendarService.shouldRedirect()) {
      this.router.navigate(['/deadlines/electronic-file-lookup']);
    } else {
      this.calendarNavigationLink = this.calendarService.getCalendarNavigationLink();
      this.setCalendarNavigationText();
      this.isErrorOnSave = false;

      if (!this.isEakteContext()) {
        this.deadlineForm.addControl('eakteId', new FormControl('', Validators.required));
      }

      const eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
      const libraryId = this.activatedRoute.snapshot.paramMap.get('libraryId');
      const nodeId = this.activatedRoute.snapshot.paramMap.get('nodeId');

      if (eventId) {
        this.isNewDeadline = false;
        this.isLoading = true;

        this.calendarService
          .loadDeadlineEventDetails(this.activatedRoute, CalendarDeadlinesActionType.EDIT_EVENT)
          .subscribe((calendarDeadlinesDetails) => {
            this.isErrorOnLoad = calendarDeadlinesDetails.isErrorOnLoad;
            this.eventEditLink = calendarDeadlinesDetails.eventEditLink;
            this.deadlinesEvent = calendarDeadlinesDetails.deadlinesEvent;
            this.dependingOnEvent = calendarDeadlinesDetails.dependingOnEvent;

            if (!this.isErrorOnLoad && this.deadlinesEvent) {
              this.deadlineForm.controls['title'].setValue(this.deadlinesEvent.title);
              this.deadlineForm.controls['date'].setValue(convert(this.deadlinesEvent.date).toDate());

              if (this.deadlinesEvent.time) {
                this.deadlineForm.controls['time'].setValue(this.deadlinesEvent.time.withSecond(0).withNano(0));
              }

              this.deadlineForm.controls['state'].setValue(this.deadlinesEvent.state);

              if (this.deadlinesEvent.source) {
                this.deadlineForm.controls['source'].setValue(this.deadlinesEvent.source);
              }

              this.deadlineForm.controls['category'].setValue(this.deadlinesEvent.category);

              this.deadlineForm.controls['isProjectEvent'].setValue(this.deadlinesEvent.isProjectEvent);

              if (this.dependingOnEvent) {
                const title = this.dependingOnEvent.title;
                this.lastSearchResultDependingOnEvent = title;
                this.deadlineForm.controls['dependentOn'].setValue(title);
              }

              if (!this.isEakteContext()) {
                this.deadlineEakte = this.deadlinesEvent.eakteId;
                this.lastSearchResultEakte = this.deadlineEakte;
                this.deadlineForm.controls['eakteId'].setValue(this.deadlineEakte);
                this.deadlineForm.controls['eakteId'].disable();
              }
            }
            this.isLoading = false;
          });
      } else {
        this.isNewDeadline = true;

        const calendarDate = this.calendarService.getCalendarDate();
        this.deadlineForm.controls['date'].setValue(convert(calendarDate).toDate());

        if (this.calendarService.getCalendarDeadlinesSelectedViewType() === CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME) {
          const calendarTime = this.calendarService.getCalendarTime();
          this.deadlineForm.controls['time'].setValue(calendarTime.withSecond(0).withNano(0));
        }

        if (libraryId && nodeId) {
          this.isLoading = true;

          const sourceUrl = `#/libraries/${libraryId}/(viewer:view/${nodeId})?location=/libraries/${libraryId}`;
          this.deadlineSource = sourceUrl;
          this.deadlineForm.controls['source'].setValue(sourceUrl);
          this.deadlineForm.controls['source'].disable();

          this.calendarService.findEakteIdByNodeId(nodeId).subscribe((eakteId) => {
            if (eakteId) {
              this.deadlineEakte = eakteId;
              this.lastSearchResultEakte = eakteId;
              this.deadlineForm.controls['eakteId'].setValue(eakteId);
              this.deadlineForm.controls['eakteId'].disable();
              this.isLoading = false;
            }
          });
        }
      }

      this.searchValueDependentOn$ = this.deadlineForm.controls['dependentOn'].valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe((searchTerm) => {
          if (searchTerm && searchTerm.trim().length >= 3 && searchTerm !== this.lastSearchResultDependingOnEvent) {
            this.openDialogDependentOn(searchTerm);
          }

          if (!searchTerm || searchTerm.trim().length === 0) {
            this.lastSearchResultDependingOnEvent = '';
            this.dependingOnEvent = null;
          }
        });

      if (!this.isEakteContext() && this.isNewDeadline && !libraryId && !nodeId) {
        this.searchValueEakte$ = this.deadlineForm.controls['eakteId'].valueChanges
          .pipe(debounceTime(1000), distinctUntilChanged())
          .subscribe((searchTerm) => {
            if (searchTerm && searchTerm.trim().length >= 3 && searchTerm !== this.lastSearchResultEakte) {
              this.openDialogEakteSearch(searchTerm);
            }
          });
      }
    }
  }

  openDialogEakteSearch(searchTerm: string): void {
    const dialogRef = this.dialog.open(DeadlinesEaktenSearchResultDialogComponent, {
      width: '600px',
      height: '700px',
      data: { searchTerm, deadlinesEaktenSearch: null }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.deadlinesEaktenSearch) {
        this.deadlinesEaktenSearch = data.deadlinesEaktenSearch;
        const name = this.deadlinesEaktenSearch?.name;
        this.lastSearchResultEakte = name;
        this.deadlineForm.controls['eakteId'].setValue(name);
      } else {
        this.lastSearchResultEakte = '';
        this.deadlinesEaktenSearch = null;
        this.deadlineForm.controls['eakteId'].setValue('');
      }
    });
  }

  openDialogDependentOn(searchTerm: string): void {
    const eventId = !this.isNewDeadline ? this.deadlinesEvent.id : '';

    const dialogRef = this.dialog.open(CalendarSearchEventDialogComponent, {
      width: '600px',
      height: '700px',
      data: { searchTerm, deadlinesEvent: null, id: eventId }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.deadlinesEvent) {
        this.dependingOnEvent = data.deadlinesEvent;
        const title = this.dependingOnEvent?.title;
        this.lastSearchResultDependingOnEvent = title;
        this.deadlineForm.controls['dependentOn'].setValue(title);
      } else {
        this.lastSearchResultDependingOnEvent = '';
        this.dependingOnEvent = null;
        this.deadlineForm.controls['dependentOn'].setValue('');
      }
    });
  }

  hasError = (controlName: string, errorName: string) => this.deadlineForm.controls[controlName].hasError(errorName);

  reset(): void {
    this.deadlineForm.reset();
  }

  resetTime(): void {
    this.deadlineForm.controls['time'].setValue('');
  }

  isEakteContext(): boolean {
    return this.calendarService.getContextName() === DeadlinesContext.EAKTE;
  }

  createOrUpdateDeadline(deadlineForm: any): void {
    if (this.deadlineForm.valid) {
      this.isLoading = true;

      const source = deadlineForm.source ? deadlineForm.source : this.deadlineSource;

      const deadlinesEvent = new DeadlinesEvent(
        deadlineForm.title,
        LocalDate.from(nativeJs(deadlineForm.date)),
        DeadlinesEventState.offen,
        source,
        deadlineForm.category,
        deadlineForm.isProjectEvent,
        ''
      );

      if (this.isEakteContext()) {
        deadlinesEvent.setEakteId(this.calendarService.getContextId());
      } else {
        const eakte = this.isNewDeadline && deadlineForm.eakteId ? deadlineForm.eakteId : this.deadlineEakte;
        deadlinesEvent.setEakteId(eakte);
      }

      if (deadlineForm.time) {
        deadlinesEvent.setTime(LocalTime.parse(deadlineForm.time.toString()));
      }

      if (this.deadlinesEvent?.id) {
        deadlinesEvent.setId(this.deadlinesEvent.id);
      }

      if (this.dependingOnEvent && this.dependingOnEvent?.id) {
        deadlinesEvent.setDependingOn(this.dependingOnEvent.id);
      }

      if (!this.isNewDeadline) {
        deadlinesEvent
          .setState(deadlineForm.state)
          .setParentToIds(this.deadlinesEvent?.parentToIds)
          .setParentToEvents(this.deadlinesEvent?.parentToEvents);
      }

      this.calendarService.createOrUpdateDeadline(deadlinesEvent, this.isNewDeadline).subscribe((deadlinesEventResponse) => {
        this.isLoading = false;
        if (deadlinesEventResponse.isError) {
          this.isErrorOnSave = true;
        } else {
          this.router.navigate([this.calendarNavigationLink]);
        }
      });
    }
  }

  private setCalendarNavigationText(): void {
    this.calendarNavigationText = 'Zurück zum Kalender';
    if (this.calendarService.getContextNavigation() === DeadlinesNavigation.AGENDA) {
      this.calendarNavigationText = 'Zurück zur Agenda';
    }
  }
}
