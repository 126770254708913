import { Action } from '@ngrx/store';

export enum DashletRouterTypes {
  PARAM_SEARCH_TYPE = 'type',
  PARAM_SEARCH_SCIP_COUNT = 'skipCount',
  PARAM_SEARCH_MAX_ITEMS = 'maxItems',
  PARAM_SEARCH_TERM = 'term',
  SEARCH_TYPE_RECENT_LIBRARIES = 'rl',
  SEARCH_TYPE_FAVORITE_LIBRARIES = 'fl',
  SEARCH_TYPE_SEARCH_LIBRARIES = 'sl'
}

export enum DashletActionTypes {
  LOAD_RECENT_LIBRARIES = 'LOAD_RECENT_LIBRARIES',
  LOAD_FAVORITES_LIBRARIES = 'LOAD_FAVORITES_LIBRARIES',
  LOAD_SEARCH_LIBRARIES = 'LOAD_SEARCH_LIBRARIES'
}

export class LoadRecentLibraries implements Action {
  readonly type = DashletActionTypes.LOAD_RECENT_LIBRARIES;

  constructor(public payload: { skipCount: number; maxItems: number }) {}
}

export class LoadFavoriteLibraries implements Action {
  readonly type = DashletActionTypes.LOAD_FAVORITES_LIBRARIES;

  constructor(public payload: { skipCount: number; maxItems: number; where?: string }) {}
}

export class LoadSearchLibraries implements Action {
  readonly type = DashletActionTypes.LOAD_SEARCH_LIBRARIES;

  constructor(public payload: { term: string; searchFilter: string; skipCount: number; maxItems: number }) {}
}
