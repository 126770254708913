<div class='calendar-monthly-view-container'>

  <div class='calendar-monthly-view-table'>
    <table>
      <thead>
      <tr>
        <td *ngFor='let header of calendarDeadlinesMonthlyModel?.dailyHeaderOfMonth'
            class='calendar-monthly-view-table-header-cell'>{{header}}</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let row of calendarDeadlinesMonthlyModel?.dailyDeadlines'>
        <td *ngFor='let data of row' (click)='showDailyEventsOfMonth(data)'
            class='calendar-monthly-view-table-data-cell'
            [ngClass]="data === selectedDailyEventsOfMonth ? 'calendar-monthly-view-table-data-cell selected-table-item': 'calendar-monthly-view-table-data-cell'">

          <div *ngIf='data.isActualDay' class='data-cell-date-today'></div>
          <div *ngIf='!data.isActualDay' class='data-cell-date-not-today'></div>

          <div class='data-cell-date'>
            <div class='data-cell-date-label'>{{data.dailyLabel}}</div>
            <div>
              <button (click)='newEvent(data)'>+</button>
            </div>
          </div>

          <ng-template [ngIf]='data.firstEvent' [ngIfElse]='noFirstEvent'>
            <div class='data-cell-event'>

              <ng-template [ngIf]='data.firstEvent.time' [ngIfElse]='noEventTime'>
                <span class='data-cell-event-time'>{{data.firstEvent.time}}</span>
                <span>&nbsp;</span>
                <span>{{ (data.firstEvent.title.length > 15) ? (data.firstEvent.title | slice:0:15) + '...' : (data.firstEvent.title) }}</span>
              </ng-template>
              <ng-template #noEventTime>
                <span>{{ (data.firstEvent.title.length > 20) ? (data.firstEvent.title | slice:0:20) + '...' : (data.firstEvent.title) }}</span>
              </ng-template>

            </div>
          </ng-template>
          <ng-template #noFirstEvent>
            <div class='data-cell-event'></div>
          </ng-template>

          <ng-template [ngIf]='data.totalDayEvents > 1' [ngIfElse]='noTotalEvents'>
            <div class='data-cell-total'>+ {{data.totalDayEvents - 1}}</div>
          </ng-template>
          <ng-template #noTotalEvents>
            <div class='data-cell-total'></div>
          </ng-template>

        </td>
      </tr>
      </tbody>
    </table>

  </div>

  <div class='calendar-monthly-view-details'>

    <table>
      <thead>
      <tr>
        <td class='calendar-monthly-view-table-header-cell'>{{selectedDailyEventsOfMonth?.extendedDailyLabel}}</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let data of selectedDailyEventsOfMonth?.dailyDeadlinesWithOutTime'>
        <td
          [ngClass]="data === selectedDeadlinesEvent ? 'calendar-monthly-view-details-data-cell selected-table-item': 'calendar-monthly-view-details-data-cell'"
          (click)='showDailyEvent(data)'>
          <table class='details-entry-table'>
            <tr>
              <td class='details-entry-img'>
                <img src='assets/images/ft_ic_folder.svg' alt='folder'>
              </td>
              <td class='details-entry-time'><img src='assets/images/calendar_today.svg' alt='all_day'></td>
              <td class='details-entry-title'>{{ (data.title.length > 20) ? (data.title | slice:0:20) + '...' : (data.title) }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngFor='let data of selectedDailyEventsOfMonth?.dailyDeadlinesWithTime'>
        <td
          [ngClass]="data === selectedDeadlinesEvent ? 'calendar-monthly-view-details-data-cell selected-table-item': 'calendar-monthly-view-details-data-cell'"
          (click)='showDailyEvent(data)'>
          <table class='details-entry-table'>
            <tr>
              <td class='details-entry-img'>
                <img src='assets/images/ft_ic_folder.svg' alt='folder'>
              </td>
              <td class='details-entry-time'>{{data.time}}</td>
              <td class='details-entry-title'>{{ (data.title.length > 20) ? (data.title | slice:0:20) + '...' : (data.title) }}</td>
            </tr>
          </table>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
