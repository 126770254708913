import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionState } from '@alfresco/adf-extensions';
import { Node, NodeChildAssociation } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { AppStore, getAppSelection } from '@alfresco/aca-shared/store';

@Component({
  selector: 'lib-open-selectedNode-in-new-tab',
  templateUrl: './open-node-in-new-tab.component.html'
})
export class OpenNodeInNewTabComponent implements OnInit {
  private url: string;
  private selection$: Observable<SelectionState>;
  private selectedNode?: NodeChildAssociation | Node;
  constructor(private store: Store<AppStore>) {
    this.selection$ = this.store.select(getAppSelection);
  }

  ngOnInit(): void {
    this.selection$.subscribe((selectionState) => {
      if (selectionState && selectionState.file && selectionState.file.entry) {
        this.selectedNode = selectionState.file.entry;
      }
    });
    this.url = window.location.href;
  }

  openNodeInNewTab(): void {
    let libraryNodeId = '';
    if (this.url) {
      try {
        const splitUrl = this.url.split('/');
        libraryNodeId = splitUrl[splitUrl.length - 1];
      } catch (error) {
        console.warn('Can not get id from library.');
      }
    }

    if (libraryNodeId) {
      const viewerUrl = `${this.url}/(viewer:view/${this.selectedNode.id})?location=/libraries/${libraryNodeId}`;
      window.open(viewerUrl, '_blank');
    } else {
      console.warn(`Can not open document with id: ${this.selectedNode.id} in new tab.`);
    }
  }
}
