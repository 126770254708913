import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalDate, LocalTime } from '@js-joda/core';
import { ErrorHandler } from '../error/error.handler';
import { DeadlinesAgendaTimeRange } from '../model/deadlines-agenda-time-range';
import { DeadlinesCalendarType } from '../model/deadlines-calendar-type';
import { DeadlinesEvent } from '../model/deadlines-event';
import { DeadlinesEventResponse } from '../model/deadlines-event-response';
import { DateTime } from '../model/date-time';
import { DateTuple } from '../model/date-tuple';
import { CalendarDeadlinesSelectedViewType } from '../../calendar/model/calendar-deadlines-selected-view-type';
import { DeadlinesEventsSearchRequest, PaginationSearchRequest, SortSearch } from '../model/deadlines-events-search-request';
import { DeadlinesEventsSearchResponse } from '../model/deadlines-events-search-response';
import { AppConfigService, StorageService, UserPreferencesService } from '@alfresco/adf-core';
import { DeadlinesContext } from '../model/deadlines-context';

@Injectable()
export class DeadlinesService {
  private readonly uri: string;
  private readonly EVENT_PATH = '/api/fristenkalender/fristen';
  private readonly EVENT_SEARCH_PATH = '/search';

  private readonly USER_NAME_KEY = 'USERNAME';

  private selectedViewType: CalendarDeadlinesSelectedViewType;
  private calendarDateTime: DateTime;
  private calendarDateTuple: DateTuple;
  private calendarType: DeadlinesCalendarType;
  private agendaTimeRange: DeadlinesAgendaTimeRange;
  private searchTerm: string;
  private contextName: string;
  private contextId: string;
  private contextTitle: string;
  private contextNavigation: string;
  private userRole: string;
  private triggerUserRole$;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private userPreferenceService: UserPreferencesService,
    private storageService: StorageService
  ) {
    this.uri = this.appConfig.get<string>('ecmHost');
    this.selectedViewType = CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME;
    this.calendarDateTime = new DateTime(LocalDate.now(), LocalTime.now());
    this.calendarDateTuple = new DateTuple(LocalDate.now().minusYears(1), LocalDate.now().minusYears(1));
    this.calendarType = DeadlinesCalendarType.WEEKLY_WORKING_TODAY;
    this.agendaTimeRange = DeadlinesAgendaTimeRange.TOTAL;
    this.searchTerm = '';
    this.contextName = '';
    this.contextId = '';
    this.contextTitle = '';
    this.contextNavigation = '';
    this.userRole = '';
    this.triggerUserRole$ = new BehaviorSubject<boolean>(false);
  }

  hasRole(): boolean {
    return this.authService.hasRole();
  }

  triggerUserRole(value: boolean): void {
    this.triggerUserRole$.next(value);
  }

  getUserRoleTrigger(): Observable<boolean> {
    return this.triggerUserRole$.asObservable();
  }

  setUserRole(role: string): void {
    this.userRole = role;
  }

  getUserRole(): string {
    return this.userRole;
  }

  setContextNavigation(navigation: string): void {
    this.contextNavigation = navigation;
  }

  getContextNavigation(): string {
    return this.contextNavigation;
  }

  setContextProperties(context: string, id: string, title: string) {
    this.setContextAndId(context, id);
    this.contextTitle = title;
  }

  setContextAndId(context: string, id: string): void {
    this.contextName = context;
    this.contextId = id;
  }

  getContextName(): string {
    return this.contextName;
  }

  getContextId(): string {
    return this.contextId;
  }

  getContextTitle(): string {
    return this.contextTitle;
  }

  setSearchTerm(searchTerm: string): void {
    this.searchTerm = searchTerm;
  }

  getSearchTerm(): string {
    return this.searchTerm;
  }

  setAgendaTimeRange(agendaTimRange: DeadlinesAgendaTimeRange): void {
    this.agendaTimeRange = agendaTimRange;
  }

  getAgendaTimeRange(): DeadlinesAgendaTimeRange {
    return this.agendaTimeRange;
  }

  setCalendarType(calendarType: DeadlinesCalendarType): void {
    this.calendarType = calendarType;
  }

  getCalendarType(): DeadlinesCalendarType {
    return this.calendarType;
  }

  getLocale(): string {
    return this.userPreferenceService.getDefaultLocale();
  }

  setCalendarDateTime(date: LocalDate, time?: LocalTime): void {
    if (!time) {
      time = LocalTime.now();
    }
    this.calendarDateTime = new DateTime(date, time);
  }

  getCalendarDateTime(): DateTime {
    return this.calendarDateTime;
  }

  setCalendarDateTuple(dateTuple: DateTuple): void {
    this.calendarDateTuple = dateTuple;
  }

  getCalendarDateTuple(): DateTuple {
    return this.calendarDateTuple;
  }

  setCalendarDeadlinesSelectedViewType(selectedViewType: CalendarDeadlinesSelectedViewType): void {
    this.selectedViewType = selectedViewType;
  }

  getCalendarDeadlinesSelectedViewType(): CalendarDeadlinesSelectedViewType {
    return this.selectedViewType;
  }

  getDeadlineEvent(id: string): Observable<DeadlinesEventResponse> {
    return of(id).pipe(
      switchMap((deadlineId) => {
        const params = new HttpParams().set('includeDependentFristen', 'true');
        return of({
          deadlineId,
          options: {
            params,
            headers: this.authService.createHttpOptionsWithUser(this.storageService.getItem(this.USER_NAME_KEY)).headers
          }
        });
      }),
      switchMap((options) =>
        this.http.get<DeadlinesEvent>(`${this.uri}${this.EVENT_PATH}/${options.deadlineId}`, options.options).pipe(
          map((result) => new DeadlinesEventResponse(false, DeadlinesEvent.of(result))),
          catchError(ErrorHandler.handleError<DeadlinesEventResponse>('getDeadlineEvent', new DeadlinesEventResponse(true, null)))
        )
      )
    );
  }

  createOrUpdateDeadline(deadlinesEvent: DeadlinesEvent, isNewDeadline: boolean): Observable<DeadlinesEventResponse> {
    return of(deadlinesEvent).pipe(
      switchMap((createOrUpdateDeadlinesEvent) => {
        deadlinesEvent.setCreatedBy(this.storageService.getItem(this.USER_NAME_KEY));

        let time = createOrUpdateDeadlinesEvent.time;
        if (!time) {
          time = LocalTime.of(0, 0);
          this.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);
        } else {
          this.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME);
        }
        this.setCalendarDateTime(createOrUpdateDeadlinesEvent.date, time);

        return of(createOrUpdateDeadlinesEvent.toFrist());
      }),
      switchMap((frist) => {
        if (isNewDeadline) {
          return this.http
            .post<DeadlinesEvent>(
              `${this.uri}${this.EVENT_PATH}`,
              frist,
              this.authService.createHttpOptionsWithUser(this.storageService.getItem(this.USER_NAME_KEY))
            )
            .pipe(
              map((result) => new DeadlinesEventResponse(false, DeadlinesEvent.of(result))),
              catchError(ErrorHandler.handleError<DeadlinesEventResponse>('createDeadline', new DeadlinesEventResponse(true, null)))
            );
        } else {
          return this.http
            .put<DeadlinesEvent>(
              `${this.uri}${this.EVENT_PATH}/${frist.id}`,
              frist,
              this.authService.createHttpOptionsWithUser(this.storageService.getItem(this.USER_NAME_KEY))
            )
            .pipe(
              map((result) => new DeadlinesEventResponse(false, DeadlinesEvent.of(result))),
              catchError(ErrorHandler.handleError<DeadlinesEventResponse>('updateDeadline', new DeadlinesEventResponse(true, null)))
            );
        }
      })
    );
  }

  deleteDeadline(id: string): Observable<DeadlinesEventResponse> {
    return this.http
      .delete<void>(
        `${this.uri}${this.EVENT_PATH}/${id}`,
        this.authService.createHttpOptionsWithUser(this.storageService.getItem(this.USER_NAME_KEY))
      )
      .pipe(
        map(() => new DeadlinesEventResponse(false, null)),
        catchError(ErrorHandler.handleError<DeadlinesEventResponse>('deleteDeadline', new DeadlinesEventResponse(true, null)))
      );
  }

  searchDeadlines(
    dateFrom: LocalDate,
    dateTo: LocalDate,
    searchTerm: string,
    skipCount: number,
    maxItems: number
  ): Observable<DeadlinesEventsSearchResponse> {
    let deadlinesEventsSearchRequest = new DeadlinesEventsSearchRequest(dateFrom, dateTo)
      .setTitle(searchTerm)
      .setPaging(new PaginationSearchRequest(maxItems, skipCount))
      .setSort(new SortSearch('date_time', false));

    if (this.contextName === DeadlinesContext.EAKTE) {
      deadlinesEventsSearchRequest = deadlinesEventsSearchRequest.setEakteId(this.contextId);
    }

    if (this.userRole !== '') {
      deadlinesEventsSearchRequest = deadlinesEventsSearchRequest.setRole(this.userRole);
    }

    return this.http
      .post<DeadlinesEventsSearchResponse>(
        `${this.uri}${this.EVENT_PATH}${this.EVENT_SEARCH_PATH}`,
        deadlinesEventsSearchRequest,
        this.authService.createHttpOptionsWithUser(this.storageService.getItem(this.USER_NAME_KEY))
      )
      .pipe(
        map((result) =>
          new DeadlinesEventsSearchResponse().setIsError(false).setPaginationResponse(result.pagination).setDeadlinesEvents(result.items)
        ),
        catchError(ErrorHandler.handleError<DeadlinesEventsSearchResponse>('searchDeadlines', new DeadlinesEventsSearchResponse().setIsError(true)))
      );
  }
}
