<div class='calendar-event-new-container'>
  <header>
    <app-deadlines-main-navigation></app-deadlines-main-navigation>
  </header>

  <ng-template [ngIf]='isLoading' [ngIfElse]='formInput'>
    <div class='calendar-event-new-spinner'>
      <mat-progress-spinner mode='indeterminate' class='deadlines-agenda-search-spinner'></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #formInput>
    <div class='calendar-event-new-body'>
      <div>
        <a routerLink='{{calendarNavigationLink}}'>{{calendarNavigationText}}</a>
      </div>

      <div class='calendar-event-new-form-container'>
        <div>
          <h3 *ngIf='isNewDeadline && !isErrorOnSave && !isErrorOnLoad'>Neue Frist anlegen</h3>
          <h3 *ngIf='!isNewDeadline && !isErrorOnSave && !isErrorOnLoad'>Frist bearbeiten</h3>
        </div>
        <div *ngIf='isErrorOnSave && !isErrorOnLoad' class='calendar-event-new-error-on-save'>
          <div>
            <h2>Es ist ein Fehler aufgetreten!</h2>
          </div>
          <div>Die Frist konnte leider nicht gespeichert werden.</div>
          <div>Bitte nochmal versuchen!</div>
        </div>
        <div *ngIf='!isErrorOnSave && isErrorOnLoad' class='calendar-event-new-error-on-save calendar-event-new-error-on-load'>
          <div>
            <h2>Es ist ein Fehler aufgetreten!</h2>
          </div>
          <div>Die Frist konnte leider nicht geladen werden.</div>
          <div><a routerLink='{{eventEditLink}}'>Bitte nochmal versuchen!</a></div>
        </div>
        <div>
          <form [formGroup]='deadlineForm' autocomplete='off' novalidate
                (ngSubmit)='createOrUpdateDeadline(deadlineForm.value)'>
            <div>
              <mat-form-field appearance='fill'>
                <mat-label>Titel</mat-label>
                <input matInput formControlName='title'>
                <mat-error *ngIf="hasError('title', 'required') || hasError('title','minlength')">
                  Titel ist ein Plichtfeld mit in. 4 Zeichen.
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance='fill'>
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]='picker' readonly formControlName='date'>
                <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="hasError('date', 'required')">
                  Datum ist ein Plichtfeld.
                </mat-error>
              </mat-form-field>
            </div>
            <div class='calendar-event-new-form-container-time'>
              <mat-form-field appearance='fill'>
                <mat-label>Zeit</mat-label>
                <input matInput type='time' formControlName='time'>
                <mat-hint align='start'>Wird keine Zeit angegeben, dann gilt die Frist als ganztägig.<span (click)='resetTime()'>Zeit l&ouml;schen.</span></mat-hint>
              </mat-form-field>
            </div>
            <div *ngIf='!isNewDeadline'>
              <mat-form-field appearance='fill'>
                <mat-label>Status</mat-label>
                <mat-select formControlName='state'>
                  <mat-option value='offen'>offen</mat-option>
                  <mat-option value='ueberfaellig'>&uuml;berf&auml;llig</mat-option>
                  <mat-option value='erledigt'>erledigt</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance='fill'>
                <mat-label>Quelle</mat-label>
                <input matInput formControlName='source'>
                <mat-error *ngIf="hasError('source', 'required') || hasError('source','minlength')">
                  Quelle ist ein Plichtfeld mit in. 4 Zeichen.
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance='fill'>
                <mat-label>Kategorie</mat-label>
                <mat-select formControlName='category'>
                  <mat-option value='MARKTERKUNDUNGSVERFAHREN'>Markterkundungsverfahren</mat-option>
                  <mat-option value='NACHFORDERUNGEN'>Nachforderungen</mat-option>
                  <mat-option value='MEILENSTEINPLAN'>Meilensteinplan</mat-option>
                  <mat-option value='BEWILLIGUNGSZEITRAUM'>Bewilligungszeitraum</mat-option>
                  <mat-option value='AUSWAHLVERFAHREN'>Auswahlverfahren</mat-option>
                  <mat-option value='NEBENBESTIMMUNGEN'>Individuelle Nebenbestimmungen</mat-option>
                  <mat-option value='BERICHTERSTATTUNG'>Berichterstattung (§ 11 Gigabit-Rahmenregelung)</mat-option>
                  <mat-option value='VERWENDUNGSNACHWEIS'>Verwendungsnachweis</mat-option>
                  <mat-option value='BEARBEITUNGSFRISTEN'>Bearbeitungsfristen</mat-option>
                  <mat-option value='SONSTIGE'>Sonstige (nicht im Dokument)</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('category', 'required')">
                  Kategorie ist ein Plichtfeld.
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance='fill'>
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Abhängig von</mat-label>
                <input matInput type='search' formControlName='dependentOn'>
              </mat-form-field>
            </div>
            <div *ngIf="!isEakteContext()">
              <mat-form-field appearance='fill'>
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>E-Akte</mat-label>
                <input matInput type='search' formControlName='eakteId'>
              </mat-form-field>
            </div>
            <div class="calendar-event-new-checkbox">
              <mat-checkbox formControlName='isProjectEvent' color='primary'>
                Nur f&uuml;r Projekttr&auml;ger sichtbar
              </mat-checkbox>
              <div class="calendar-event-new-checkbox-label">
                Ohne Haken f&uuml;r externe Benutzer sichtbar!
              </div>
            </div>
            <div>
              <button class='calendar-event-new-actions' mat-raised-button color='primary'
                      [disabled]='!deadlineForm.valid'>Speichern
              </button>
              <button class='calendar-event-new-actions' mat-raised-button color='primary' (click)='reset()'>
                Zurücksetzen
              </button>
            </div>
          </form>
        </div>
      </div>

      <div>
        <a routerLink='{{calendarNavigationLink}}'>{{calendarNavigationText}}</a>
      </div>
    </div>

  </ng-template>
</div>
