<div class="main-content">
    <adf-document-list
        #documentList
        acaDocumentList
        acaContextActions
        [display]="documentDisplayMode$ | async"
        [node]="list"
        [loading]="isLoading"
        selectionMode="single"
        [navigate]="false"
        [sorting]="['modifiedAt', 'desc']"
        sortingMode="client"
        (node-dbclick)="navigateTo($event)"
        [imageResolver]="imageResolver"
        (name-click)="navigateTo($event)"
    >
        <adf-custom-empty-content-template>
            <adf-empty-content
                    icon="library_books"
                    [title]="'Keine Bibliotheken gefunden.'"
                    subtitle="Legen Sie eine Bibliothek an, um sie später leichter zu finden."
            >
            </adf-empty-content>
        </adf-custom-empty-content-template>

        <data-columns>
            <ng-container *ngFor="let column of columns; trackBy: trackById">
                <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                    <data-column
                            [key]="column.key"
                            [title]="column.title"
                            [type]="column.type"
                            [format]="column.format"
                            [class]="column.class"
                            [sortable]="column.sortable"
                    >
                        <ng-template let-context>
                            <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                        </ng-template>
                    </data-column>
                </ng-container>

                <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                    <data-column
                            [key]="column.key"
                            [title]="column.title"
                            [type]="column.type"
                            [format]="column.format"
                            [class]="column.class"
                            [sortable]="column.sortable"
                    >
                    </data-column>
                </ng-container>
            </ng-container>
        </data-columns>
    </adf-document-list>

    <adf-pagination
            [target]="documentList"
            [pagination]="pagination"
            (changePageSize)="onChangePageSize($event)"
            (changePageNumber)="onChange($event)"
            (nextPage)="onChange($event)"
            (prevPage)="onChange($event)"
    >
    </adf-pagination>
</div>
