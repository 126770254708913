import { DeadlinesEvent } from '../../common/model/deadlines-event';
import { Eakte } from '../../common/model/eakte';

export class CalendarDeadlinesDetails {
  eventDetailsLink = '';
  eventEditLink = '';
  isErrorOnLoad = false;
  deadlinesEvent?: DeadlinesEvent | null;
  dependingOnEvent?: DeadlinesEvent | null;
  superiorToEvents: DeadlinesEvent[] = [];
  eakte: Eakte;

  setEventDetailsLink(eventDetailsLink: string): CalendarDeadlinesDetails {
    this.eventDetailsLink = eventDetailsLink;
    return this;
  }

  setEventEditLink(eventEditLink: string): CalendarDeadlinesDetails {
    this.eventEditLink = eventEditLink;
    return this;
  }

  setIsErrorOnLoad(isErrorOnLoad: boolean): CalendarDeadlinesDetails {
    this.isErrorOnLoad = isErrorOnLoad;
    return this;
  }

  setDeadlinesEvent(deadlinesEvent: DeadlinesEvent | null): CalendarDeadlinesDetails {
    this.deadlinesEvent = deadlinesEvent;
    return this;
  }

  setDependingOnEvent(dependingOnEvent: DeadlinesEvent | null): CalendarDeadlinesDetails {
    this.dependingOnEvent = dependingOnEvent;
    return this;
  }

  setSuperiorToEvents(superiorToEvents: DeadlinesEvent[]): CalendarDeadlinesDetails {
    this.superiorToEvents = superiorToEvents;
    return this;
  }

  setEakte(eakte: Eakte): CalendarDeadlinesDetails {
    this.eakte = eakte;
    return this;
  }
}
