import { DeadlinesEvent } from './deadlines-event';

export class DeadlinesEventsSearchResponse {
  isError = false;
  pagination: PaginationResponse = new PaginationResponse(0, 0, 0);
  items: DeadlinesEvent[] = [];

  setIsError(isError: boolean): DeadlinesEventsSearchResponse {
    this.isError = isError;
    return this;
  }

  setPaginationResponse(paginationItem: any): DeadlinesEventsSearchResponse {
    this.pagination = new PaginationResponse(paginationItem.total, paginationItem.limit, paginationItem.skip);
    return this;
  }

  setDeadlinesEvents(items: any): DeadlinesEventsSearchResponse {
    items.forEach((item: any) => {
      this.items.push(DeadlinesEvent.of(item));
    });
    return this;
  }
}

export class PaginationResponse {
  total = 0;
  limit = 0;
  skip = 0;

  constructor(total: number, limit: number, skip: number) {
    this.total = total;
    this.limit = limit;
    this.skip = skip;
  }
}
