import { Injectable } from '@angular/core';
import { DateTuple } from '../model/date-tuple';
import { ChronoUnit, LocalDate } from '@js-joda/core';
import { Observable } from 'rxjs';
import { DeadlinesCalendarType } from '../model/deadlines-calendar-type';

@Injectable()
export class DateTimeService {
  private readonly WEEK_DAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  private readonly INDEX_OF_WEEK_DAYS = 6;
  private readonly INDEX_OF_WORKING_WEEK_DAYS = 4;

  getDayLabelsOfMonth(): string[] {
    return this.WEEK_DAYS;
  }

  getDayLabelsOfWeek(calendarType: DeadlinesCalendarType): string[] {
    if (calendarType === DeadlinesCalendarType.WEEKLY_WORKING || calendarType === DeadlinesCalendarType.WEEKLY_WORKING_TODAY) {
      return this.WEEK_DAYS.slice(0, this.INDEX_OF_WORKING_WEEK_DAYS + 1);
    }
    return this.WEEK_DAYS;
  }

  getFormattedDay(day: number): string {
    return day < 10 ? `0${day.toString(10)}` : day.toString(10);
  }

  getDatePattern(locale: string): string {
    switch (locale) {
      case 'de':
        return 'dd.MM.yyyy';
      default:
        return 'MM/dd/yyyy';
    }
  }

  getDayName(weeklyDayIndex: number): string {
    return this.WEEK_DAYS[weeklyDayIndex - 1];
  }

  getMonthName(nameOfMonth: string): string {
    switch (nameOfMonth) {
      case 'JANUARY':
        return 'Januar';
      case 'FEBRUARY':
        return 'Februar';
      case 'MARCH':
        return 'März';
      case 'APRIL':
        return 'April';
      case 'MAY':
        return 'Mai';
      case 'JUNE':
        return 'Juni';
      case 'JULY':
        return 'Juli';
      case 'AUGUST':
        return 'August';
      case 'SEPTEMBER':
        return 'September';
      case 'OCTOBER':
        return 'Oktober';
      case 'NOVEMBER':
        return 'November';
      case 'DECEMBER':
        return 'Dezember';
      default:
        return '';
    }
  }

  getMonthShortcutName(nameOfMonth: string): string {
    switch (nameOfMonth) {
      case 'JANUARY':
        return 'Jan';
      case 'FEBRUARY':
        return 'Feb';
      case 'MARCH':
        return 'Mrz';
      case 'APRIL':
        return 'Apr';
      case 'MAY':
        return 'Mai';
      case 'JUNE':
        return 'Jun';
      case 'JULY':
        return 'Jul';
      case 'AUGUST':
        return 'Aug';
      case 'SEPTEMBER':
        return 'Sep';
      case 'OCTOBER':
        return 'Okt';
      case 'NOVEMBER':
        return 'Nov';
      case 'DECEMBER':
        return 'Dez';
      default:
        return '';
    }
  }

  getWeeklyStartEndDate(localDate: LocalDate): Observable<DateTuple> {
    return new Observable((subscriber) => {
      const dayOfWeek = localDate.dayOfWeek().value();
      const differenceStartOfWeek = (1 - dayOfWeek) * -1;
      const weeklyStartDate = localDate.minusDays(differenceStartOfWeek);
      const weeklyEndDate = weeklyStartDate.plusDays(this.INDEX_OF_WEEK_DAYS);
      subscriber.next(new DateTuple(weeklyStartDate, weeklyEndDate));
      subscriber.complete();
    });
  }

  getWorkingWeeklyStartEndDate(localDate: LocalDate): Observable<DateTuple> {
    return new Observable((subscriber) => {
      const dayOfWeek = localDate.dayOfWeek().value();
      const differenceStartOfWeek = (1 - dayOfWeek) * -1;
      const weeklyStartDate = localDate.minusDays(differenceStartOfWeek);
      const weeklyEndDate = weeklyStartDate.plusDays(this.INDEX_OF_WORKING_WEEK_DAYS);
      subscriber.next(new DateTuple(weeklyStartDate, weeklyEndDate));
      subscriber.complete();
    });
  }

  getMonthlyStartEndDate(localDate: LocalDate): Observable<DateTuple> {
    return new Observable((subscriber) => {
      const monthBegin = localDate.withDayOfMonth(1);
      const monthEnd = localDate.withDayOfMonth(1).plusMonths(1).minusDays(1);
      const daysBetween = monthBegin.until(monthEnd, ChronoUnit.DAYS);

      const dayOfWeek = monthBegin.dayOfWeek().value();
      const differenceStartOfWeek = (1 - dayOfWeek) * -1;
      const monthlyStartDate = monthBegin.minusDays(differenceStartOfWeek);
      let monthlyEndDate = LocalDate.now();

      for (let i = 1; i <= differenceStartOfWeek + daysBetween; i++) {
        monthlyEndDate = monthlyStartDate.plusDays(i);
      }

      const differenceEndOfWeek = 7 - monthEnd.dayOfWeek().value();

      for (let i = 1; i <= differenceEndOfWeek; i++) {
        monthlyEndDate = monthEnd.plusDays(i);
      }

      subscriber.next(new DateTuple(monthlyStartDate, monthlyEndDate));
      subscriber.complete();
    });
  }

  getDaysBetween(startDate: LocalDate, endDate: LocalDate): Observable<LocalDate[]> {
    return new Observable((subscriber) => {
      const monthlyDays = [];
      monthlyDays.push(startDate);

      let stepDate = LocalDate.of(startDate.year(), startDate.month(), startDate.dayOfMonth());
      while (stepDate.compareTo(endDate) < 0) {
        stepDate = stepDate.plusDays(1);
        monthlyDays.push(stepDate);
      }

      subscriber.next(monthlyDays);
      subscriber.complete();
    });
  }
}
