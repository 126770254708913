<div class='calendar-search-event-container'>

  <ng-template [ngIf]='isLoading' [ngIfElse]='searchResult'>
    <div class='body'>
      <mat-progress-spinner mode='indeterminate' class='calendar-search-event-spinner'></mat-progress-spinner>
    </div>
  </ng-template>

  <header>
    <button mat-button mat-dialog-close class='dialog-close-action' (click)='closeDialog()'>Schließen
    </button>
    <mat-divider></mat-divider>
  </header>

  <ng-template #searchResult>

    <ng-container *ngIf='deadlinesEvents.length !== 0'>
      <div class='body'>
        <mat-selection-list [multiple]='false'>
          <ng-container *ngFor='let deadlinesEvent of deadlinesEvents; let last = last'>
            <mat-list-option (click)='selectedSearchItem(deadlinesEvent)'>
              <div class='calendar-search-event-result-row-container'>
                <div>
                  <img src='assets/images/ft_ic_folder.svg' alt='folder'>
                </div>
                <div>{{deadlinesEvent.title}}&nbsp;
                  <span class="calendar-search-event-result-row-date-time">({{formatDate(deadlinesEvent.date)}}</span>
                  <span *ngIf="deadlinesEvent.time" class="calendar-search-event-result-row-date-time">&nbsp;-&nbsp;{{deadlinesEvent.time}}</span>
                  <span *ngIf="!deadlinesEvent.time" class="calendar-search-event-result-row-date-time">&nbsp;-&nbsp;ganzt&auml;gig</span>
                  <span class="calendar-search-event-result-row-date-time"> - E-Akte: {{deadlinesEvent.eakteId}})</span>
                </div>
              </div>
            </mat-list-option>
            <mat-divider *ngIf='!last'></mat-divider>
          </ng-container>
        </mat-selection-list>
      </div>

      <footer>
        <mat-divider></mat-divider>
        <mat-paginator [length]='length'
                       [pageSize]='pageSize'
                       [pageIndex]='pageIndex'
                       [pageSizeOptions]='pageSizeOptions'
                       [showFirstLastButtons]=true
                       (page)='getPageEvent($event)'
                       aria-label='Select page'>
        </mat-paginator>
      </footer>

    </ng-container>

    <ng-container *ngIf='deadlinesEvents.length === 0'>
      <div class='body'>
        <h3>Keine Ergebnisse.</h3>
      </div>
      <footer></footer>
    </ng-container>

  </ng-template>
</div>
