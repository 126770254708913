import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfConversionContextItemComponent } from './pdf-conversion-context-item/pdf-conversion-context-item.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PdfConversionDialogComponent } from './pdf-conversion-dialog/pdf-conversion-dialog.component';
import { PdfConversionService } from './service/pdf-conversion.service';
import { ExtensionService } from '@alfresco/adf-extensions';
import * as pdfConversionRules from '../../../pdf-conversion/src/rules/pdf-conversion-rules';

@NgModule({
  declarations: [PdfConversionContextItemComponent, PdfConversionDialogComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, TranslateModule, MatDialogModule, MatButtonModule, MatProgressSpinnerModule],
  providers: [PdfConversionService]
})
export class PdfConversionModule {
  constructor(extensions: ExtensionService) {
    extensions.setComponents({
      'app.context.menu.pdfConversion': PdfConversionContextItemComponent
    });

    extensions.setEvaluators({
      canConvertToPdf: pdfConversionRules.canConvertToPdf
    });
  }
}
