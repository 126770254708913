import { LocalDate } from '@js-joda/core';

export class DeadlinesEventsSearchRequest {
  title = '';
  role = '';
  dateFrom: LocalDate;
  dateTo: LocalDate;
  eakteIds: string[] = [];
  paging: PaginationSearchRequest = new PaginationSearchRequest(0, 10000);
  sort: SortSearch = new SortSearch('title', true);

  constructor(dateFrom: LocalDate, dateTo: LocalDate) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  setTitle(title: string): DeadlinesEventsSearchRequest {
    this.title = title;
    return this;
  }

  setRole(role: string): DeadlinesEventsSearchRequest {
    this.role = role;
    return this;
  }

  setEakteId(eakteId: string): DeadlinesEventsSearchRequest {
    this.eakteIds.push(eakteId);
    return this;
  }

  setPaging(paging: PaginationSearchRequest): DeadlinesEventsSearchRequest {
    this.paging = paging;
    return this;
  }

  setSort(sort: SortSearch): DeadlinesEventsSearchRequest {
    this.sort = sort;
    return this;
  }
}

export class PaginationSearchRequest {
  limit: number;
  skip: number;

  constructor(limit: number, skip: number) {
    this.limit = limit;
    this.skip = skip;
  }
}

export class SortSearch {
  type: string;
  ascending: boolean;

  constructor(sortField: string, ascending: boolean) {
    this.type = sortField;
    this.ascending = ascending;
  }
}
