import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { ErrorHandler } from '../common/error/error.handler';
import { catchError } from 'rxjs/operators';
import { DashletUtil } from '../common/util/dashlet.util';
import { DashletResult } from '../common/model/dashlet.result';
import { Pagination } from '@alfresco/js-api';

@Injectable()
export class FavoriteService {
  private favoritePath = '/eakten/dashlet/favorite';

  constructor(private http: HttpClient, private authService: AuthService) {}

  getFavoriteLibraries(pzsUiApi: string, pagination: Pagination): Observable<DashletResult> {
    return this.http
      .post<DashletResult>(`${pzsUiApi}${this.favoritePath}`, pagination, this.authService.createHttpOptions())
      .pipe(catchError(ErrorHandler.handleError<DashletResult>('getFavoriteLibraries', DashletUtil.createEmptyResult())));
  }
}
