<div class='deadlines-main-navigation-container'>
  <div class='deadlines-main-navigation'>

    <a routerLink='/deadlines/agenda{{navigationPath}}'>
      <div class='deadlines-main-navigation-link-container'>
        <div>
          <img src='assets/images/agenda.svg' alt='agenda'>
        </div>
        <div
          [className]="activeAgendaLink ? 'deadlines-main-navigation-link-text-active' : 'deadlines-main-navigation-link-text'">
          Agenda
        </div>
      </div>
    </a>

    <a routerLink='/deadlines/calendar{{navigationPath}}'>
      <div class='deadlines-main-navigation-link-container'>
        <div>
          <img src='assets/images/calendar.svg' alt='kalendar'>
        </div>
        <div
          [className]="activeCalendarLink ? 'deadlines-main-navigation-link-text-active' : 'deadlines-main-navigation-link-text'">
          Kalender
        </div>
      </div>
    </a>

    <a routerLink='/deadlines/electronic-file-lookup'>
      <div class='deadlines-main-navigation-link-container'>
        <div>
          <img src='assets/images/search.svg' alt='search'>
        </div>
        <div
          [className]="activeSearchLink ? 'deadlines-main-navigation-link-text-active' : 'deadlines-main-navigation-link-text'">
          Suche
        </div>
      </div>
    </a>

    <div *ngIf="hasRole()" class="deadlines-main-navigation-user-role">
      <mat-checkbox color='primary' [ngModel]="userRoleChecked" (ngModelChange)="updateUserRole($event)">
        Alle Fristen
      </mat-checkbox>
    </div>

    <div class="deadlines-main-navigation-context-info">
      {{context}}
    </div>

  </div>
  <mat-divider></mat-divider>
</div>
