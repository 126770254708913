<adf-toolbar class="app-menu" [style.background-color]="headerColor$ | async">
  <adf-toolbar-title>
    <a class="app-menu__title" title="{{ appName$ | async }}" [routerLink]="['/']">
      <img [src]="logo" alt="{{ appName$ | async }}" />
    </a>
  </adf-toolbar-title>
</adf-toolbar>

<mat-accordion multi="true" displayMode="flat">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'APP.SETTINGS.REPOSITORY-SETTINGS' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="form" novalidate (ngSubmit)="apply(form.value, form.valid)">
      <div>
        <mat-form-field class="settings-input" appearance="outline">
          <mat-label>ACS Repository URL</mat-label>
          <input matInput formControlName="ecmHost" type="text" />
          <mat-error *ngIf="form.get('ecmHost').hasError('pattern')">
            {{ 'APP.SETTINGS.INVALID-VALUE-FORMAT' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('ecmHost').hasError('required')">
            {{ 'APP.SETTINGS.REQUIRED-FIELD' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="settings-input" appearance="outline">
          <mat-label>Authentication Type</mat-label>
          <mat-select formControlName="authType">
            <mat-option value="BASIC">Basic</mat-option>
            <mat-option value="OAUTH">OAuth (Identity Service)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="settings-input" appearance="outline">
          <mat-label>Alfresco Identity Service URL</mat-label>
          <input matInput formControlName="aisHost" type="text" />
        </mat-form-field>
      </div>

      <div class="settings-buttons">
        <button mat-button (click)="reset()">
          {{ 'APP.SETTINGS.RESET' | translate }}
        </button>
        <button mat-button color="primary" type="submit" [disabled]="!form.valid">
          {{ 'APP.SETTINGS.APPLY' | translate }}
        </button>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-expansion-panel *ngFor="let group of settingGroups">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ group.name | translate }}</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="aca-settings-parameter-list">
      <ng-container *ngFor="let param of group.parameters">
        <ng-container [ngSwitch]="param.type">
          <ng-container *ngSwitchCase="'boolean'">
            <mat-checkbox [checked]="getBooleanParamValue(param)" (change)="setParamValue(param, $event.checked)">{{
              param.name | translate
            }}</mat-checkbox>
          </ng-container>

          <ng-container *ngSwitchCase="'string'">
            <mat-form-field class="settings-input" appearance="outline">
              <mat-label>{{ param.name | translate }}</mat-label>
              <input
                matInput
                type="text"
                [value]="getStringParamValue(param)"
                (blur)="onParamValueChanged($event, param)"
                (keyup.enter)="onParamValueChanged($event, param)"
              />
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <span>Unknown parameter type: {{ param.name | translate }}</span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
