import { NodePaging } from '@alfresco/js-api';
import { DashletResult } from '../model/dashlet.result';

export class DashletUtil {
  static createEmptyResult(): {
    list: {
      entries: any[];
      pagination: {
        maxItems: number;
        totalItems: number;
        hasMoreItems: boolean;
        count: number;
        skipCount: number;
      };
    };
  } {
    return {
      list: {
        pagination: {
          count: 0,
          totalItems: 0,
          hasMoreItems: false,
          skipCount: 0,
          maxItems: 0
        },
        entries: []
      }
    };
  }

  static convertToNodePaging(result: DashletResult): NodePaging {
    return new NodePaging(result);
  }
}
