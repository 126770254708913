<ng-container *ngIf="selection$ | async as selection">
  <ng-container *ngIf="!data.iconButton">
    <button mat-menu-item data-automation-id="share-action-button" (click)="editSharedNode(selection)">
      <mat-icon>link</mat-icon>
      <ng-container *ngIf="isShared(selection); else not_shared">
        <span>{{ 'APP.ACTIONS.SHARE_EDIT' | translate }}</span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="data.iconButton">
    <button
      mat-icon-button
      data-automation-id="share-action-button"
      (click)="editSharedNode(selection)"
      [attr.aria-label]="getLabel(selection) | translate"
      [attr.title]="getLabel(selection) | translate"
    >
      <mat-icon>link</mat-icon>
    </button>
  </ng-container>
</ng-container>

<ng-template #not_shared>
  <span>{{ 'APP.ACTIONS.SHARE' | translate }}</span>
</ng-template>
