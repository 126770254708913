import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@alfresco/adf-core';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AuthService {
  constructor(private authenticationService: AuthenticationService) {}

  createHttpOptions(): { headers: HttpHeaders } {
    const token = this.authenticationService.getToken();
    return {
      headers: new HttpHeaders().set('content-type', 'application/json').set('Authorization', `Bearer ${token}`)
    };
  }

  createHttpOptionsWithUser(user: string): { headers: HttpHeaders } {
    const token = this.authenticationService.getToken();
    return {
      headers: new HttpHeaders().set('content-type', 'application/json').set('Authorization', `Bearer ${token}`).set('X-Benutzername', user)
    };
  }

  hasRole(): boolean {
    try {
      const token = this.authenticationService.getToken();
      const decodedAccessToken: any = jwtDecode(token);
      const roles = decodedAccessToken?.realm_access?.roles ?? [];
      return roles.includes('PT_Global_RW') || roles.includes('PT_Global_R');
    } catch (Error) {
      return false;
    }
  }
}
