import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DeadlinesService } from '../../common/service/deadlines.service';
import { DeadlinesNavigationActiveLink } from '../model/deadlines-navigation-active-link';
import { DeadlinesNavigationLinks } from '../model/deadlines-navigation-links';

@Injectable()
export class DeadlinesNavigationService {
  constructor(private deadlinesService: DeadlinesService) {}

  hasRole(): boolean {
    return this.deadlinesService.hasRole();
  }

  triggerUserRole(value: boolean): void {
    this.deadlinesService.triggerUserRole(value);
  }

  setUserRole(role: string): void {
    this.deadlinesService.setUserRole(role);
  }

  getUserRole(): string {
    return this.deadlinesService.getUserRole();
  }

  getNavigationContext(): string {
    return this.deadlinesService.getContextName();
  }

  getNavigationId(): string {
    return this.deadlinesService.getContextId();
  }

  getNavigationTitle(): string {
    return this.deadlinesService.getContextTitle();
  }

  showNavigation(): Observable<boolean> {
    return of({}).pipe(
      switchMap(() => {
        if (this.deadlinesService.getContextName().trim() !== '' && this.deadlinesService.getContextId().trim() !== '') {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  getActiveLink(url: string): Observable<DeadlinesNavigationActiveLink> {
    return of({}).pipe(
      switchMap(() => {
        if (url.includes(DeadlinesNavigationLinks.SEARCH)) {
          return of(new DeadlinesNavigationActiveLink(true, false, false));
        } else if (url.includes(DeadlinesNavigationLinks.AGENDA)) {
          return of(new DeadlinesNavigationActiveLink(false, true, false));
        } else if (url.includes(DeadlinesNavigationLinks.CALENDAR)) {
          return of(new DeadlinesNavigationActiveLink(false, false, true));
        }
        return of(new DeadlinesNavigationActiveLink(false, false, false));
      })
    );
  }
}
