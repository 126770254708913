import { Injectable } from '@angular/core';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { DateTuple } from '../../common/model/date-tuple';
import { DateTimeService } from '../../common/service/date-time.service';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeadlinesAgendaTimeRange } from '../../common/model/deadlines-agenda-time-range';
import { DeadlinesService } from '../../common/service/deadlines.service';
import { CalendarDeadlinesSelectedViewType } from '../../calendar/model/calendar-deadlines-selected-view-type';

@Injectable()
export class DeadlinesAgendaService {
  private searchDeadlinesByTimeRangeSubject = new Subject<{ timeRange: string; skipCount: number; maxItems: number }>();
  searchDeadlinesByTimeRangeSubject$ = this.searchDeadlinesByTimeRangeSubject.asObservable().pipe(
    switchMap((params) => {
      let result: Observable<DateTuple>;

      let dateFrom: LocalDate | null;
      let dateTo: LocalDate | null;

      switch (params.timeRange) {
        case DeadlinesAgendaTimeRange.WEEK: {
          result = this.dateTimeService.getWeeklyStartEndDate(LocalDate.now());
          break;
        }
        case DeadlinesAgendaTimeRange.MONTH: {
          result = this.dateTimeService.getMonthlyStartEndDate(LocalDate.now());
          break;
        }
        case DeadlinesAgendaTimeRange.TOTAL: {
          dateFrom = LocalDate.now().minusYears(100);
          dateTo = LocalDate.now().plusYears(100);
          result = of(new DateTuple(dateFrom, dateTo));
          break;
        }
        default: {
          dateFrom = LocalDate.now();
          dateTo = LocalDate.now();
          result = of(new DateTuple(dateFrom, dateTo));
        }
      }

      return result.pipe(
        map((dates) => ({
          dates,
          params
        }))
      );
    }),
    switchMap((result) =>
      this.deadlinesService.searchDeadlines(result.dates.firstDate, result.dates.secondDate, '', result.params.skipCount, result.params.maxItems)
    )
  );

  constructor(private dateTimeService: DateTimeService, private deadlinesService: DeadlinesService) {}

  getUserRoleTrigger(): Observable<boolean> {
    return this.deadlinesService.getUserRoleTrigger();
  }

  setContextNavigation(navigation: string): void {
    this.deadlinesService.setContextNavigation(navigation);
  }

  getEventNewLink(): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/calendar/${context}/${id}/event/new`;
  }

  getEventEditLink(deadlineId: string): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/calendar/${context}/${id}/event/edit/${deadlineId}`;
  }

  formatDate(localDate: LocalDate): string {
    const locale = this.deadlinesService.getLocale();
    const pattern = this.dateTimeService.getDatePattern(locale);
    return localDate.format(DateTimeFormatter.ofPattern(pattern));
  }

  setAgendaTimeRange(agendaTimRange: DeadlinesAgendaTimeRange): void {
    this.deadlinesService.setAgendaTimeRange(agendaTimRange);
  }

  getAgendaTimeRange(): DeadlinesAgendaTimeRange {
    return this.deadlinesService.getAgendaTimeRange();
  }

  setContextAndId(context: string, id: string): void {
    if (context && id) {
      this.deadlinesService.setContextAndId(context, id);
    }
  }

  setCalendarDeadlinesSelectedViewType(selectedViewType: CalendarDeadlinesSelectedViewType): void {
    this.deadlinesService.setCalendarDeadlinesSelectedViewType(selectedViewType);
  }

  shouldRedirect(): boolean {
    return !this.deadlinesService.getContextName() || !this.deadlinesService.getContextId();
  }

  triggerSearchDeadlinesByTimeRange(timeRange: string, skipCount: number, maxItems: number) {
    this.searchDeadlinesByTimeRangeSubject.next({ timeRange, skipCount, maxItems });
  }
}
