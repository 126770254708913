import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DashletActionTypes, LoadFavoriteLibraries, LoadRecentLibraries, LoadSearchLibraries } from '../../actions/dashlet-actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchFilterConstants } from '../../constants/search-filter-constants';

@Component({
  selector: 'aca-dashlet-search',
  templateUrl: './dashlet-search.component.html',
  styleUrls: ['./dashlet-search.component.scss']
})
export class DashletSearchComponent implements OnInit, OnChanges {
  @Input()
  lastAction: LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries;

  @Output()
  viewLibraries: EventEmitter<LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries> = new EventEmitter();

  searchFieldControl = new FormControl();
  searchValue$!: Subscription;
  selectedSearchFilter = SearchFilterConstants.SEARCH_FILTER_ALL;

  searchFilterOptions = [
    {
      key: SearchFilterConstants.SEARCH_FILTER_ALL,
      value: 'Alle'
    },
    {
      key: SearchFilterConstants.SEARCH_FILTER_ADVISE,
      value: 'Beratung'
    },
    {
      key: SearchFilterConstants.SEARCH_FILTER_INFRASTRUCTURE,
      value: 'Infrastruktur'
    }
  ];

  isRecentSearchSelected = false;
  isFavoriteSearchSelected = false;

  ngOnChanges() {
    if (this.lastAction) {
      if (this.lastAction.type === DashletActionTypes.LOAD_RECENT_LIBRARIES) {
        this.selectedTypeRecentLibraries();
      } else if (this.lastAction.type === DashletActionTypes.LOAD_FAVORITES_LIBRARIES) {
        this.selectedTypeFavoriteLibraries();
      } else if (this.lastAction.type === DashletActionTypes.LOAD_SEARCH_LIBRARIES) {
        this.selectedTypeSearchLibraries(this.lastAction.payload.term);
      }
    }
  }

  ngOnInit(): void {
    this.searchValue$ = this.searchFieldControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      if (value && value.trim().length >= 3) {
        this.selectedTypeSearchLibraries(value);
        this.viewLibraries.emit(
          new LoadSearchLibraries({
            term: value.trim(),
            searchFilter: this.selectedSearchFilter,
            skipCount: 0,
            maxItems: 25
          })
        );
      }
    });
  }

  changeSelectedSearchFilter(): void {
    if (this.searchFieldControl.value) {
      this.viewLibraries.emit(
        new LoadSearchLibraries({
          term: this.searchFieldControl.value,
          searchFilter: this.selectedSearchFilter,
          skipCount: 0,
          maxItems: 25
        })
      );
    }
  }

  viewRecentLibraries(): void {
    this.selectedTypeRecentLibraries();
    this.viewLibraries.emit(new LoadRecentLibraries({ skipCount: 0, maxItems: 25 }));
  }

  viewFavoriteLibraries(): void {
    this.selectedTypeFavoriteLibraries();
    this.viewLibraries.emit(new LoadFavoriteLibraries({ skipCount: 0, maxItems: 25 }));
  }

  private selectedTypeRecentLibraries(): void {
    this.isRecentSearchSelected = true;
    this.isFavoriteSearchSelected = false;
    this.selectedSearchFilter = SearchFilterConstants.SEARCH_FILTER_ALL;
    this.searchFieldControl.reset();
  }

  private selectedTypeFavoriteLibraries(): void {
    this.isRecentSearchSelected = false;
    this.isFavoriteSearchSelected = true;
    this.selectedSearchFilter = SearchFilterConstants.SEARCH_FILTER_ALL;
    this.searchFieldControl.reset();
  }

  private selectedTypeSearchLibraries(term: string): void {
    this.isRecentSearchSelected = false;
    this.isFavoriteSearchSelected = false;
    this.searchFieldControl.setValue(term);
  }
}
