import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionState } from '@alfresco/adf-extensions';
import { Node, NodeChildAssociation } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { AppStore, getAppSelection } from '@alfresco/aca-shared/store';
import { MatDialog } from '@angular/material/dialog';
import { PdfConversionDialogComponent } from '../pdf-conversion-dialog/pdf-conversion-dialog.component';

@Component({
  selector: 'pdf-conversion-context-item',
  templateUrl: './pdf-conversion-context-item.component.html'
})
export class PdfConversionContextItemComponent implements OnInit {
  private selection$: Observable<SelectionState>;
  private node?: NodeChildAssociation | Node;

  constructor(private store: Store<AppStore>, public dialog: MatDialog) {
    this.selection$ = this.store.select(getAppSelection);
  }

  ngOnInit() {
    this.selection$.subscribe((selectionState) => {
      if (selectionState && selectionState.file && selectionState.file.entry) {
        this.node = selectionState.file.entry;
      }
    });
  }

  openConversionToPdfDialog(): void {
    this.dialog.open(PdfConversionDialogComponent, {
      height: '280px',
      width: '400px',
      data: this.node,
    });
  }
}
