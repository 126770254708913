<aca-page-layout>

  <aca-page-layout-header>
    <adf-toolbar class="adf-toolbar--inline">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </adf-toolbar>
    <adf-breadcrumb root="{{'ACA_FOLDER_RULES.ACTIONS.MANAGE_RULES' | translate}}"></adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="main-content">

      <ng-container *ngIf="(rulesLoading$ | async) || (actionsLoading$ | async); else onLoaded">
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
      </ng-container>

      <ng-template #onLoaded>
        <ng-container *ngIf="folderInfo$ | async; else genericError">
          <adf-toolbar class="adf-toolbar--inline aca-manage-rules__actions-bar">
            <adf-toolbar-title class="aca-manage-rules__actions-bar__title">
              <mat-icon class="icon-aligner">folder</mat-icon>
              <adf-breadcrumb root="{{ (folderInfo$ | async).name }}:{{'ACA_FOLDER_RULES.MANAGE_RULES.TOOLBAR.BREADCRUMB.RULES' | translate}}"
                              class="aca-manage-rules__actions-bar__title__breadcrumb"></adf-breadcrumb>
            </adf-toolbar-title>

            <button mat-flat-button color="primary" (click)="openCreateUpdateRuleDialog()">{{ 'ACA_FOLDER_RULES.MANAGE_RULES.TOOLBAR.ACTIONS.CREATE_RULE' | translate }}</button>
          </adf-toolbar>
          <mat-divider></mat-divider>

          <div class="aca-manage-rules__container" *ngIf="(rules$ | async).length > 0 ; else emptyContent">
            <aca-rules-list [rules]="rules$ | async" (ruleSelected)="onRuleSelected($event)"
                            [selectedRule]="selectedRule" [nodeId]="nodeId"></aca-rules-list>
            <div class="aca-manage-rules__container__rule-details">
              <div class="aca-manage-rules__container__preview">
                <div class="aca-manage-rules__container__preview__toolbar">
                  <span>{{ selectedRule.name }}</span>
                  <div class="aca-manage-rules__container__preview__toolbar__buttons">
                    <button mat-icon-button (click)="onRuleDelete()" id="delete-rule-btn">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                    <button mat-stroked-button (click)="onRuleUpdate()" id="edit-rule-btn">
                      {{ 'ACA_FOLDER_RULES.MANAGE_RULES.TOOLBAR.ACTIONS.EDIT_RULE' | translate }}
                    </button>
                  </div>
                </div>
                <p>{{ selectedRule.description }}</p>
              </div>
              <aca-rule-details
                [actionDefinitions]="actionDefinitions$ | async"
                [readOnly]="true"
                [preview]="true"
                [value]="selectedRule">
              </aca-rule-details>
            </div>
          </div>

          <ng-template #emptyContent>
            <adf-empty-content
              icon="library_books"
              [title]="'ACA_FOLDER_RULES.MANAGE_RULES.EMPTY_RULES_LIST.TITLE' | translate"
              [subtitle]="'ACA_FOLDER_RULES.MANAGE_RULES.EMPTY_RULES_LIST.SUBTITLE' | translate"
            >
            </adf-empty-content>
          </ng-template>
        </ng-container>

        <ng-template #genericError>
          <aca-page-layout-error>
            <aca-generic-error></aca-generic-error>
          </aca-page-layout-error>
        </ng-template>

      </ng-template>

    </div>
  </aca-page-layout-content>

</aca-page-layout>
