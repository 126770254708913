import { ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';
import { PageComponent } from '../../../../../../app/src/app/components/page.component';
import { ContentManagementService } from '../../../../../../app/src/app/services/content-management.service';
import { Store } from '@ngrx/store';
import { AppExtensionService } from '@alfresco/aca-shared';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DocumentListPresetRef } from '@alfresco/adf-extensions';
import { NodePaging, Pagination } from '@alfresco/js-api';
import { UserPreferencesService } from '@alfresco/adf-core';
import { Observable, Subject } from 'rxjs';
import { DashletService } from '../../services/dashlet.service';
import { DashletActionTypes, LoadFavoriteLibraries, LoadRecentLibraries, LoadSearchLibraries } from '../../actions/dashlet-actions';
import { DashletPageRequest } from '../../services/common/model/dashlet-page-request';
import { Router } from "@angular/router";

@Component({
  selector: 'aca-dashlet-view',
  templateUrl: './dashlet-view.component.html',
  styleUrls: ['./dashlet-view.component.scss']
})
export class DashletViewComponent extends PageComponent implements OnInit, OnChanges {
  constructor(
    content: ContentManagementService,
    store: Store<any>,
    extensions: AppExtensionService,
    private breakpointObserver: BreakpointObserver,
    private preferences: UserPreferencesService,
    private changeDetectorRef: ChangeDetectorRef,
    private dashletService: DashletService,
    private router: Router
  ) {
    super(store, extensions, content);
  }
  @Input()
  viewSubject$: Subject<LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries>;
  @Input()
  lastAction: LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries;

  pagination: Pagination = new Pagination({
    skipCount: 0,
    maxItems: 25,
    totalItems: 0
  });

  list: NodePaging;
  isLoading = false;
  isSmallScreen = false;
  columns: DocumentListPresetRef[] = [];

  private static createPagination(action: LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries): Pagination {
    return new Pagination({
      skipCount: action.payload.skipCount,
      maxItems: action.payload.maxItems,
      totalItems: 0
    });
  }

  ngOnChanges() {
    if (this.lastAction) {
      this.pagination = DashletViewComponent.createPagination(this.lastAction);
      this.getList(this.pagination);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions = this.subscriptions.concat([
      this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
        this.isSmallScreen = result.matches;
      })
    ]);
    this.columns = this.extensions.documentListPresets.files || [];

    this.viewSubject$.subscribe((action) => {
      this.lastAction = action;
      this.pagination = DashletViewComponent.createPagination(action);
      this.getList(this.pagination);
    });
  }

  navigateTo(event: any) {
    if (event && event.detail && event.detail.node && event.detail.node.entry && event.detail.node.entry.id) {
      this.router.navigate(['/libraries/' + event.detail.node.entry.id]);
    }
  }

  onChangePageSize(pagination: Pagination) {
    this.preferences.paginationSize = pagination.maxItems;
    this.getList(pagination);
  }

  onChange(pagination: Pagination) {
    this.getList(pagination);
  }

  private getList(pagination: Pagination) {
    this.isLoading = true;
    this.getLibraries(pagination).subscribe(
      (nodePaging: NodePaging) => {
        this.list = nodePaging;
        this.pagination = nodePaging.list.pagination;
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.list = null;
        this.pagination = null;
        this.isLoading = false;
      }
    );
  }

  private getLibraries(pagination: Pagination): Observable<NodePaging> {
    const dashletPagination: DashletPageRequest = {
      skipCount: pagination.skipCount,
      maxItems: pagination.maxItems
    };

    if (this.lastAction.type === DashletActionTypes.LOAD_FAVORITES_LIBRARIES) {
      return this.dashletService.getFavoriteLibraries(dashletPagination);
    } else if (this.lastAction.type === DashletActionTypes.LOAD_RECENT_LIBRARIES) {
      return this.dashletService.getRecentLibraries(dashletPagination);
    } else {
      return this.dashletService.getSearchLibraries({
        pageRequest: dashletPagination,
        searchTerm: this.lastAction.payload.term,
        searchFilter: this.lastAction.payload.searchFilter
      });
    }
  }
}
