import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeadlinesEaktenSearchService } from '../service/deadlines-eakten-search.service';
import { DeadlinesEaktenSearch } from '../../common/model/eakten-search-response';
import { Router } from '@angular/router';
import { DeadlinesContext } from '../../common/model/deadlines-context';

@Component({
  selector: 'app-deadlines-eakten-search',
  templateUrl: './deadlines-eakten-search-field.component.html',
  styleUrls: ['./deadlines-eakten-search-field.component.css']
})
export class DeadlinesEaktenSearchFieldComponent implements OnInit {
  searchFieldControl = new FormControl();
  searchValue$!: Subscription;
  showNavigation = false;
  searchTerm = '';

  constructor(private deadlinesEaktenSearchService: DeadlinesEaktenSearchService, private router: Router) {}

  ngOnInit(): void {
    this.deadlinesEaktenSearchService.showNavigation().subscribe((showNavigation) => (this.showNavigation = showNavigation));

    const usedSearchTerm = this.deadlinesEaktenSearchService.getSearchTerm();
    if (usedSearchTerm) {
      this.searchFieldControl.setValue(usedSearchTerm);
      this.searchTerm = usedSearchTerm;
    }

    this.searchValue$ = this.searchFieldControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchTermValue) => {
      this.searchTerm = searchTermValue;
      this.deadlinesEaktenSearchService.setSearchTerm(this.searchTerm);
    });
  }

  selectedSearchItem(item: DeadlinesEaktenSearch): void {
    const context = DeadlinesContext.EAKTE;
    const id = item.id;
    this.deadlinesEaktenSearchService.setContextProperties(context, id, item.name);
    this.router.navigate([`/deadlines/agenda/${context}/${id}`]);
  }

  loadAggregatedCalendar(): void {
    const context = DeadlinesContext.AGGREGATED_CALENDAR;
    const id = 'calendar';
    this.deadlinesEaktenSearchService.setContextProperties(context, id, '');
    this.router.navigate([`/deadlines/agenda/${context}/${id}`]);
  }
}
