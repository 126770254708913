import { Injectable } from '@angular/core';
import { DeadlinesCalendarType } from '../../common/model/deadlines-calendar-type';
import { DeadlinesService } from '../../common/service/deadlines.service';
import { DeadlinesEvent } from '../../common/model/deadlines-event';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DeadlinesEventResponse } from '../../common/model/deadlines-event-response';
import { DateTimeFormatter, LocalDate, LocalDateTime, LocalTime } from '@js-joda/core';
import { DateTimeService } from '../../common/service/date-time.service';
import { CalendarDeadlinesMonthlyModel, DailyEventsOfMonth } from '../model/calendar-deadlines-monthly-model';
import { CalendarDeadlinesWeeklyModel, DailyEventsOfWeek } from '../model/calendar-deadlines-weekly-model';
import { ActivatedRoute } from '@angular/router';
import { CalendarDeadlinesDetails } from '../model/calendar-deadlines-details';
import { CalendarDeadlinesActionType } from '../model/calendar-deadlines-action-type';
import { DateTime } from '../../common/model/date-time';
import { CalendarDeadlinesSelectedViewType } from '../model/calendar-deadlines-selected-view-type';
import { DeadlinesEventsSearchResponse } from '../../common/model/deadlines-events-search-response';
import { EakteService } from '../../common/service/eakte.service';

@Injectable()
export class CalendarService {
  private readonly WEEKLY_MONTHLY_CALENDAR_ROWS = 7;
  private readonly HOURS_OF_DAY = 24;

  private triggerCalendarCalculation$;
  private triggerUpdateNavigationDate$;

  private calendarDeadlinesWeeklySubject = new Subject<void>();
  private calendarDeadlinesMonthlySubject = new Subject<void>();

  calendarDeadlinesWeeklySubject$ = this.calendarDeadlinesWeeklySubject.asObservable().pipe(
    switchMap(() =>
      this.searchDeadlines(
        this.deadlinesService.getCalendarDateTuple().firstDate,
        this.deadlinesService.getCalendarDateTuple().secondDate,
        '',
        0,
        1000
      )
    ),
    switchMap((deadlineEventsSearchResponse) =>
      this.dateTimeService
        .getDaysBetween(this.deadlinesService.getCalendarDateTuple().firstDate, this.deadlinesService.getCalendarDateTuple().secondDate)
        .pipe(
          switchMap((weeklyDays) =>
            of({
              deadlineEventsSearchResponse,
              weeklyDates: weeklyDays
            })
          )
        )
    ),
    switchMap((daysAndEvents) => {
      const calendarType = this.deadlinesService.getCalendarType();
      const weeklyLabels = this.dateTimeService.getDayLabelsOfWeek(calendarType);
      const weeklyDates = daysAndEvents.weeklyDates;
      const weeklyDayLabels: string[] = [];

      weeklyLabels.forEach((weekDayName, index) => {
        const date = weeklyDates[index];
        const label = `${date.dayOfMonth()} ${weekDayName}`;
        weeklyDayLabels.push(label);
      });

      return of({
        deadlineEvents: daysAndEvents.deadlineEventsSearchResponse.items,
        weeklyDates: daysAndEvents.weeklyDates,
        weeklyDayLabels
      });
    }),
    switchMap((calendarProperties) => {
      const days = calendarProperties.weeklyDates;
      const deadlineEvents = calendarProperties.deadlineEvents;

      const deadlinesWithTimeResult = [...Array(this.HOURS_OF_DAY)].map(() => Array(days.length));
      const deadLinesWithoutTimeResult = [...Array(days.length)];

      let actualDayIndex = -1;

      for (let i = 0; i < days.length; i++) {
        const actualDay = days[i];

        if (actualDayIndex === -1) {
          actualDayIndex = actualDay.compareTo(LocalDate.now()) === 0 ? i : -1;
        }

        let startDateTime = LocalDateTime.of(actualDay.year(), actualDay.month(), actualDay.dayOfMonth())
          .withHour(0)
          .withMinute(0)
          .withSecond(0)
          .withNano(0);

        const actualDate = startDateTime.toLocalDate();
        const dailyDeadlinesWithOutTime: DeadlinesEvent[] = [];
        deadlineEvents.forEach((deadlineEvent) => {
          if (actualDate.compareTo(deadlineEvent.date) === 0) {
            if (!deadlineEvent.time) {
              dailyDeadlinesWithOutTime.push(deadlineEvent);
            }
          }
        });

        let extendedTimeDailyLabel = `${actualDate.dayOfMonth().toString(10)}. ${this.dateTimeService.getMonthName(
          actualDate.month().toString()
        )} ${actualDate.year().toString()}`;

        deadLinesWithoutTimeResult[i] = new DailyEventsOfWeek(actualDay, extendedTimeDailyLabel, dailyDeadlinesWithOutTime);

        for (let j = 0; j < this.HOURS_OF_DAY; j++) {
          const dailyDeadlinesWithTime: DeadlinesEvent[] = [];
          const actualTime = startDateTime.toLocalTime();

          deadlineEvents.forEach((deadlineEvent) => {
            if (deadlineEvent.time && actualDate.compareTo(deadlineEvent.date) === 0) {
              if (actualTime.hour() === deadlineEvent.time.hour()) {
                dailyDeadlinesWithTime.push(deadlineEvent);
              }
            }
          });

          extendedTimeDailyLabel = `${actualTime} - ${actualDate.dayOfMonth().toString(10)}. ${this.dateTimeService.getMonthName(
            actualDate.month().toString()
          )} ${actualDate.year().toString()}`;

          deadlinesWithTimeResult[j][i] = new DailyEventsOfWeek(actualDay, extendedTimeDailyLabel, dailyDeadlinesWithTime).setHourlyTime(actualTime);

          startDateTime = startDateTime.plusHours(1);
        }
      }

      return of(
        new CalendarDeadlinesWeeklyModel(actualDayIndex, calendarProperties.weeklyDayLabels, deadLinesWithoutTimeResult, deadlinesWithTimeResult)
      );
    })
  );

  calendarDeadlinesMonthlySubject$ = this.calendarDeadlinesMonthlySubject.asObservable().pipe(
    switchMap(() =>
      this.searchDeadlines(
        this.deadlinesService.getCalendarDateTuple().firstDate,
        this.deadlinesService.getCalendarDateTuple().secondDate,
        '',
        0,
        10000
      )
    ),
    switchMap((deadlineEventsSearchResponse) =>
      this.dateTimeService
        .getDaysBetween(this.deadlinesService.getCalendarDateTuple().firstDate, this.deadlinesService.getCalendarDateTuple().secondDate)
        .pipe(
          switchMap((monthlyDays) =>
            of({
              deadlineEventsSearchResponse,
              monthlyDays
            })
          )
        )
    ),
    switchMap((daysAndEvents) => {
      const calendarDate = this.deadlinesService.getCalendarDateTime().calendarDate;
      const deadlineEvents = daysAndEvents.deadlineEventsSearchResponse.items;
      const monthlyDays = daysAndEvents.monthlyDays;
      const rows = monthlyDays.length / this.WEEKLY_MONTHLY_CALENDAR_ROWS;
      const result = [...Array(rows)].map(() => Array(this.WEEKLY_MONTHLY_CALENDAR_ROWS));

      let daysIndex = 0;
      let actualDailyEventsOfMonth: DailyEventsOfMonth | null = null;

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < this.WEEKLY_MONTHLY_CALENDAR_ROWS; j++) {
          const actualDate = monthlyDays[daysIndex];

          let dailyLabel = '';
          if (actualDate.dayOfMonth() === 1) {
            dailyLabel = `${actualDate.dayOfMonth().toString(10)} ${this.dateTimeService.getMonthShortcutName(actualDate.month().toString())}`;
          } else {
            dailyLabel = actualDate.dayOfMonth().toString(10);
          }

          const extendedDailyLabel = `${this.dateTimeService.getDayName(actualDate.dayOfWeek().value())}, ${actualDate
            .dayOfMonth()
            .toString(10)}. ${this.dateTimeService.getMonthName(actualDate.month().toString())} ${actualDate.year().toString()}`;

          const deadlineEventsWithTime: DeadlinesEvent[] = [];
          const deadlineEventsWithOutTime: DeadlinesEvent[] = [];
          deadlineEvents.forEach((deadlineEvent) => {
            if (actualDate.compareTo(deadlineEvent.date) === 0) {
              if (!deadlineEvent.time) {
                deadlineEventsWithOutTime.push(deadlineEvent);
              } else {
                deadlineEventsWithTime.push(deadlineEvent);
              }
            }
          });

          deadlineEventsWithTime.sort((a, b) => {
            if (a.time && b.time) {
              return a.time?.compareTo(b.time);
            } else {
              return 0;
            }
          });

          const stepDailyEventsOfMonth = new DailyEventsOfMonth(
            actualDate,
            dailyLabel,
            extendedDailyLabel,
            actualDate.compareTo(LocalDate.now()) === 0,
            deadlineEventsWithOutTime,
            deadlineEventsWithTime
          );

          if (stepDailyEventsOfMonth.localDate.compareTo(calendarDate) === 0) {
            actualDailyEventsOfMonth = stepDailyEventsOfMonth;
          }

          result[i][j] = stepDailyEventsOfMonth;

          daysIndex++;
        }
      }

      return of(new CalendarDeadlinesMonthlyModel(this.dateTimeService.getDayLabelsOfMonth(), result, actualDailyEventsOfMonth));
    })
  );

  constructor(private deadlinesService: DeadlinesService, private dateTimeService: DateTimeService, private eakteService: EakteService) {
    this.triggerCalendarCalculation$ = new BehaviorSubject<boolean>(false);
    this.triggerUpdateNavigationDate$ = new BehaviorSubject<LocalDate>(LocalDate.now());
  }

  getUserRoleTrigger(): Observable<boolean> {
    return this.deadlinesService.getUserRoleTrigger();
  }

  resetCalendarCalculationTrigger(): void {
    this.triggerCalendarCalculation$ = new BehaviorSubject<boolean>(false);
  }

  triggerCalendarCalculation(): void {
    this.triggerCalendarCalculation$.next(true);
  }

  getCalendarCalculationTrigger(): Observable<boolean> {
    return this.triggerCalendarCalculation$.asObservable();
  }

  triggerUpdateNavigationDate(): void {
    this.triggerUpdateNavigationDate$.next(this.getCalendarDate());
  }

  getUpdateNavigationDateTrigger(): Observable<LocalDate> {
    return this.triggerUpdateNavigationDate$.asObservable();
  }

  resetNavigationDateTrigger(localDate: LocalDate): void {
    this.triggerUpdateNavigationDate$ = new BehaviorSubject<LocalDate>(localDate);
  }

  getContextName(): string {
    return this.deadlinesService.getContextName();
  }

  getContextId(): string {
    return this.deadlinesService.getContextId();
  }

  getCalendarType(): DeadlinesCalendarType {
    return this.deadlinesService.getCalendarType();
  }

  setContextNavigation(navigation: string): void {
    this.deadlinesService.setContextNavigation(navigation);
  }

  getContextNavigation(): string {
    return this.deadlinesService.getContextNavigation();
  }

  setContextAndId(context: string, id: string): void {
    if (context && id) {
      this.deadlinesService.setContextAndId(context, id);
    }
  }

  shouldRedirect(): boolean {
    return !this.deadlinesService.getContextName() || !this.deadlinesService.getContextId();
  }

  getLocalDateFromString(localDateString: string): LocalDate {
    try {
      return LocalDate.parse(localDateString);
    } catch (error) {
      return LocalDate.now();
    }
  }

  getCalendarDate(): LocalDate {
    return this.deadlinesService.getCalendarDateTime().calendarDate;
  }

  getCalendarTime(): LocalTime {
    return this.deadlinesService.getCalendarDateTime().calendarTime;
  }

  getCalendarDateTime(): DateTime {
    return this.deadlinesService.getCalendarDateTime();
  }

  saveCalendarDate(localDate: LocalDate): void {
    this.deadlinesService.setCalendarDateTime(localDate, LocalTime.now());
  }

  saveCalendarDateTime(localDate: LocalDate, time?: LocalTime): void {
    this.deadlinesService.setCalendarDateTime(localDate, time);
  }

  saveCalendarNavigationProperties(calendarType: DeadlinesCalendarType, localDate: LocalDate): void {
    this.deadlinesService.setCalendarType(calendarType);
    this.deadlinesService.setCalendarDateTime(localDate, this.getCalendarTime());
  }

  getWeekly(calendarType: DeadlinesCalendarType): boolean {
    return calendarType === DeadlinesCalendarType.WEEKLY || calendarType === DeadlinesCalendarType.WEEKLY_TODAY;
  }

  getWeeklyWorking(calendarType: DeadlinesCalendarType): boolean {
    return calendarType === DeadlinesCalendarType.WEEKLY_WORKING || calendarType === DeadlinesCalendarType.WEEKLY_WORKING_TODAY;
  }

  setCalendarDeadlinesSelectedViewType(selectedViewType: CalendarDeadlinesSelectedViewType): void {
    this.deadlinesService.setCalendarDeadlinesSelectedViewType(selectedViewType);
  }

  getCalendarDeadlinesSelectedViewType(): CalendarDeadlinesSelectedViewType {
    return this.deadlinesService.getCalendarDeadlinesSelectedViewType();
  }

  getCalendarLabel(): Observable<string> {
    const datetime = this.deadlinesService.getCalendarDateTime();
    const calendarType = this.deadlinesService.getCalendarType();

    return of({}).pipe(
      switchMap(() => {
        if (this.getWeekly(calendarType)) {
          return this.dateTimeService.getWeeklyStartEndDate(datetime.calendarDate);
        } else if (this.getWeeklyWorking(calendarType)) {
          return this.dateTimeService.getWorkingWeeklyStartEndDate(datetime.calendarDate);
        } else {
          return this.dateTimeService.getMonthlyStartEndDate(datetime.calendarDate);
        }
      }),
      switchMap((dateTuple) => {
        this.deadlinesService.setCalendarDateTuple(dateTuple);

        if (this.getWeekly(calendarType) || this.getWeeklyWorking(calendarType)) {
          const weeklyStartDateString = this.dateTimeService.getFormattedDay(dateTuple.firstDate.dayOfMonth());
          const weeklyEndDateString = this.dateTimeService.getFormattedDay(dateTuple.secondDate.dayOfMonth());
          const month = this.dateTimeService.getMonthName(dateTuple.firstDate.month().toString());
          const year = dateTuple.firstDate.year();

          if (dateTuple.firstDate.month() === dateTuple.secondDate.month()) {
            return of(`${weeklyStartDateString}-${weeklyEndDateString} ${month}, ${year}`);
          } else {
            const secondMonth = this.dateTimeService.getMonthName(dateTuple.secondDate.month().toString());
            return of(`${weeklyStartDateString} ${month} - ${weeklyEndDateString} ${secondMonth}, ${year}`);
          }
        } else {
          const month = this.dateTimeService.getMonthName(datetime.calendarDate.month().toString());
          const year = dateTuple.firstDate.year();
          return of(`${month} ${year}`);
        }
      })
    );
  }

  getCalendarNavigationLink(): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/${this.getContextNavigation()}/${context}/${id}`;
  }

  getEventNewLink(): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/calendar/${context}/${id}/event/new`;
  }

  getEventDetailsLink(eventId: string | undefined): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/calendar/${context}/${id}/event/details/${eventId}`;
  }

  getEventEditLink(eventId: string | undefined): string {
    const context = this.deadlinesService.getContextName();
    const id = this.deadlinesService.getContextId();
    return `/deadlines/calendar/${context}/${id}/event/edit/${eventId}`;
  }

  formatDate(localDate?: LocalDate): string {
    if (!localDate) {
      return '';
    }
    const locale = this.deadlinesService.getLocale();
    const pattern = this.dateTimeService.getDatePattern(locale);
    return localDate.format(DateTimeFormatter.ofPattern(pattern));
  }

  createOrUpdateDeadline(deadlinesEvent: DeadlinesEvent, isNewDeadline: boolean): Observable<DeadlinesEventResponse> {
    return this.deadlinesService.createOrUpdateDeadline(deadlinesEvent, isNewDeadline);
  }

  deleteDeadline(id: string): Observable<DeadlinesEventResponse> {
    return this.deadlinesService.deleteDeadline(id);
  }

  getDeadlineEvent(id: string): Observable<DeadlinesEventResponse> {
    return this.deadlinesService.getDeadlineEvent(id);
  }

  searchDeadlines(
    dateFrom: LocalDate,
    dateTo: LocalDate,
    searchTerm: string,
    skipCount: number,
    maxItems: number
  ): Observable<DeadlinesEventsSearchResponse> {
    return this.deadlinesService.searchDeadlines(dateFrom, dateTo, searchTerm, skipCount, maxItems);
  }

  loadDeadlineEventDetails(route: ActivatedRoute, type: CalendarDeadlinesActionType): Observable<CalendarDeadlinesDetails> {
    const calendarDeadlinesDetails = new CalendarDeadlinesDetails();
    return route.params.pipe(
      switchMap((params) => {
        const id = params['eventId'];
        if (type === CalendarDeadlinesActionType.EVENT_DETAILS) {
          calendarDeadlinesDetails.setEventDetailsLink(this.getEventDetailsLink(id));
        } else {
          calendarDeadlinesDetails.setEventEditLink(this.getEventEditLink(id));
        }
        return this.getDeadlineEvent(id);
      }),
      switchMap((deadlinesEventResponse) => {
        if (deadlinesEventResponse.isError) {
          calendarDeadlinesDetails.setIsErrorOnLoad(true);
        } else {
          const deadlinesEvent = deadlinesEventResponse.deadlinesEvent;
          calendarDeadlinesDetails.setIsErrorOnLoad(false);
          calendarDeadlinesDetails.setDeadlinesEvent(deadlinesEvent);
          if (deadlinesEvent?.parentToEvents && deadlinesEvent?.parentToEvents?.length > 0) {
            calendarDeadlinesDetails.setSuperiorToEvents(deadlinesEvent.parentToEvents);
          }
        }

        if (calendarDeadlinesDetails.deadlinesEvent?.dependingOn) {
          return this.getDeadlineEvent(calendarDeadlinesDetails.deadlinesEvent?.dependingOn);
        } else {
          return of(null);
        }
      }),
      switchMap((deadlinesEventResponseDependingOn) => {
        if (!deadlinesEventResponseDependingOn?.isError && deadlinesEventResponseDependingOn !== null) {
          calendarDeadlinesDetails.setDependingOnEvent(deadlinesEventResponseDependingOn.deadlinesEvent);
        }
        return this.eakteService.eakteById(calendarDeadlinesDetails.deadlinesEvent.eakteId);
      }),
      switchMap((eakteResponse) => of(calendarDeadlinesDetails.setEakte(eakteResponse)))
    );
  }

  triggerCreateCalendarDeadlinesMonthlyModel() {
    this.calendarDeadlinesMonthlySubject.next();
  }

  triggerCreateCalendarDeadlinesWeeklyModel() {
    this.calendarDeadlinesWeeklySubject.next();
  }

  findEakteIdByNodeId(nodeId: string): Observable<string> {
    return this.eakteService.findEakteIdByNodeId(nodeId);
  }
}
