import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionState } from '@alfresco/adf-extensions';
import { Node, NodeChildAssociation } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { AppStore, getAppSelection } from '@alfresco/aca-shared/store';
import { Router } from '@angular/router';
import { CalendarService } from '../calendar/service/calendar.service';
import { CalendarDeadlinesSelectedViewType } from '../calendar/model/calendar-deadlines-selected-view-type';

@Component({
  selector: 'app-calendar-menu-item',
  templateUrl: './deadlines-context-menu-item.component.html',
  styleUrls: ['./deadlines-context-menu-item.component.css']
})
export class DeadlinesContextMenuItemComponent implements OnInit {
  private selection$: Observable<SelectionState>;
  private node?: NodeChildAssociation | Node;

  constructor(private store: Store<AppStore>, private router: Router, private calendarService: CalendarService) {
    this.selection$ = this.store.select(getAppSelection);
  }

  ngOnInit(): void {
    this.selection$.subscribe((selectionState) => {
      if (selectionState && selectionState.file && selectionState.file.entry) {
        this.node = selectionState.file.entry;
      }
    });
  }

  createNewDeadline(): void {
    this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);

    const url = window.location.href;
    const nodeId = this.node.id;
    let libraryId = '';

    try {
      const splitUrl = url.split('/');
      for (let i = 0; i < splitUrl.length; i++) {
        if (splitUrl[i] === 'libraries') {
          libraryId = splitUrl[i + 1];
        }
      }
    } catch (error) {
      console.warn('Can not get id from library.');
    }

    this.router.navigate([`deadlines/calendar/aggregated/calendar/event/new/${libraryId}/${nodeId}`]);
  }
}
