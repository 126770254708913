<div class='calendar-event-details-container'>
  <header>
    <app-deadlines-main-navigation></app-deadlines-main-navigation>
  </header>

  <ng-template [ngIf]='isLoading' [ngIfElse]='eventDetails'>
    <div class='calendar-event-details-spinner'>
      <mat-progress-spinner mode='indeterminate' class='deadlines-agenda-search-spinner'></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #eventDetails>
    <div class='calendar-event-details-body'>
      <div>
        <a routerLink='{{calendarNavigationLink}}'>Zurück zum Kalender</a>
      </div>

      <div class='calendar-event-details-detail-container'>
        <div>
          <h3 *ngIf='!isErrorOnLoad'>Frist Details</h3>
        </div>

        <div *ngIf='isErrorOnLoad' class='calendar-event-details-error-on-load-and-delete'>
          <div>
            <h2>Es ist ein Fehler aufgetreten!</h2>
          </div>
          <div>Die Frist konnte leider nicht geladen werden.</div>
          <div><a routerLink='{{eventDetailsLink}}'>Bitte nochmal versuchen!</a></div>
        </div>
        <div *ngIf='isErrorOnDelete' class='calendar-event-details-error-on-load-and-delete'>
          <div>
            <h2>Es ist ein Fehler aufgetreten!</h2>
          </div>
          <div>Die Frist konnte leider nicht gel&ouml;scht werden.</div>
          <div>Bitte nochmal versuchen!</div>
        </div>

        <div *ngIf='!isErrorOnLoad'>
          <div *ngIf='!canEventDeleted' class='calendar-event-details-body-hint'>
            Hinweis:<br/>Frist kann nicht gelöscht werden, da sie übergeordnet ist<br/>zu einer oder mehreren anderen
            Fristen.
          </div>

          <div class='divider-top'>
            <mat-divider></mat-divider>
          </div>

          <table>
            <ng-container [ngTemplateOutlet]="deadlineDetails"
                          [ngTemplateOutletContext]="{deadlinesEvent:deadlinesEvent}">
            </ng-container>

            <tr>
              <td class="calendar-event-details-body-table-td-left">Frist ist abh&auml;ngig von:</td>
              <td *ngIf='dependingOnEvent'
                  class='calendar-event-details-body-table-td-right'>
                <table class="calendar-event-details-other-event">
                  <ng-container [ngTemplateOutlet]="deadlineDetails"
                                [ngTemplateOutletContext]="{deadlinesEvent:dependingOnEvent}">
                  </ng-container>
                </table>
              </td>
              <td *ngIf='!dependingOnEvent' class='calendar-event-details-body-table-td-right'>-</td>
            </tr>
            <tr>
              <td class="calendar-event-details-body-table-td-left">Frist ist &uuml;bergeordnet zu:</td>
              <td *ngIf='superiorToEvents.length > 0' class='calendar-event-details-body-table-td-right'>
                <table class="calendar-event-details-other-event">
                  <ng-container *ngFor='let event of superiorToEvents; let last = last;'>
                    <ng-container [ngTemplateOutlet]="deadlineDetails"
                                  [ngTemplateOutletContext]="{deadlinesEvent:event}">
                    </ng-container>
                    <tr *ngIf="!last">
                      <td colspan="2">
                        <mat-divider></mat-divider>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </td>
              <td *ngIf='superiorToEvents.length === 0' class='calendar-event-details-body-table-td-right'>-</td>
            </tr>

            <tr>
              <td class="calendar-event-details-body-table-td-left">E-Akte:</td>
              <td *ngIf='eakte.name' class='calendar-event-details-body-table-td-right'>{{eakte.name}}</td>
              <td *ngIf='!eakte.name' class='calendar-event-details-body-table-td-right'>-</td>
            </tr>
            <tr>
              <td class="calendar-event-details-body-table-td-left">Projektkoordinatoren:</td>
              <td *ngIf='eakte.projektkoordinatoren && eakte.projektkoordinatoren.length > 0'
                  class='calendar-event-details-body-table-td-right'>
                <ul>
                  <li
                    *ngFor='let coordinator of eakte.projektkoordinatoren'>{{coordinator.firstname}} {{coordinator.lastname}}</li>
                </ul>
              </td>
              <td *ngIf='!eakte.projektkoordinatoren || eakte.projektkoordinatoren.length === 0'
                  class='calendar-event-details-body-table-td-right'>-
              </td>
            </tr>
          </table>

          <div class='divider-bottom'>
            <mat-divider></mat-divider>
          </div>


          <div class='calendar-event-details-body-button-bottom'>
            <button *ngIf='canEventDeleted' class='calendar-event-new-actions' mat-raised-button color='primary'
                    (click)='deleteDeadline()'>
              L&ouml;schen
            </button>
            <button class='calendar-event-new-actions' mat-raised-button color='primary' (click)='edit()'>
              Bearbeiten
            </button>
          </div>

        </div>
      </div>

      <div>
        <a routerLink='{{calendarNavigationLink}}'>Zurück zum Kalender</a>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #deadlineDetails let-deadlinesEvent="deadlinesEvent">
  <tr>
    <td class="calendar-event-details-body-table-td-left">Titel:</td>
    <td class='calendar-event-details-body-table-td-right'>{{deadlinesEvent?.title}}</td>
  </tr>
  <tr>
    <td class="calendar-event-details-body-table-td-left">Datum:</td>
    <td class='calendar-event-details-body-table-td-right'>{{formatDate(deadlinesEvent?.date)}}</td>
  </tr>
  <tr>
    <td class="calendar-event-details-body-table-td-left">Zeit:</td>
    <td *ngIf='deadlinesEvent?.time'
        class='calendar-event-details-body-table-td-right'>{{deadlinesEvent?.time}}</td>
    <td *ngIf='!deadlinesEvent?.time' class='calendar-event-details-body-table-td-right'>
      <img src='assets/images/calendar_today.svg' alt='all_day'/>
      (ganzt&auml;gig)
    </td>
  </tr>
  <tr>
    <td class="calendar-event-details-body-table-td-left">Status:</td>
    <td class='calendar-event-details-body-table-td-right'>{{convertState(deadlinesEvent?.state)}}</td>
  </tr>
  <tr>
    <td class="calendar-event-details-body-table-td-left">Quelle:</td>
    <td *ngIf='deadlinesEvent?.source'
        class='calendar-event-details-body-table-td-right'>
      <ng-template [ngIf]='deadlinesEvent.isSourceLink()' [ngIfElse]='sourceAsPlainText'>
        <a href="{{deadlinesEvent.source}}" target="_blank">Datei &ouml;ffnen</a>
      </ng-template>
      <ng-template #sourceAsPlainText>{{deadlinesEvent.source}}</ng-template>
    </td>
    <td *ngIf='!deadlinesEvent?.source' class='calendar-event-details-body-table-td-right'>-</td>
  </tr>
  <tr>
    <td class="calendar-event-details-body-table-td-left">Kategorie:</td>
    <td class='calendar-event-details-body-table-td-right'>{{convertCategory(deadlinesEvent?.category)}}</td>
  </tr>
</ng-template>
