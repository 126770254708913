import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DashletActionService {
  private favoriteSubject = new Subject<void>();

  triggerFavoriteDashletViewReload() {
    this.favoriteSubject.next();
  }

  onFavoriteDashletViewReload(): Observable<void> {
    return this.favoriteSubject.asObservable();
  }
}
