import { Observable, of } from 'rxjs';

export class ErrorHandler {
  static handleError<T>(operation = 'Operation', result?: T) {
    return (error: Error): Observable<T> => {
      //TODO replace with real logging into ELK
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
