import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeadlinesEaktenSearch } from '../../common/model/eakten-search-response';
import { PageEvent } from '@angular/material/paginator';
import { EaktenSearchRequest } from '../../common/model/eakten-search-request';
import { DeadlinesEaktenSearchService } from '../service/deadlines-eakten-search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'deadlines-eakten-search-result',
  templateUrl: './deadlines-eakten-search-result.component.html',
  styleUrls: ['./deadlines-eakten-search-result.component.scss']
})
export class DeadlinesEaktenSearchResultComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  searchTerm = '';
  @Input()
  searchContext = '';
  @Output()
  selectedItem = new EventEmitter<DeadlinesEaktenSearch>();
  userRoleTrigger$: Subscription;

  isLoading = false;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  deadlinesEaktenSearchResult: DeadlinesEaktenSearch[] = [];

  constructor(private deadlinesEaktenSearchService: DeadlinesEaktenSearchService) {}

  ngOnInit(): void {
    this.userRoleTrigger$ = this.deadlinesEaktenSearchService.getUserRoleTrigger().subscribe(() => {
      if (this.searchTerm) {
        this.search(0, 10, this.searchTerm);
      }
    });
  }

  ngOnDestroy(): void {
    this.userRoleTrigger$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchTerm) {
      const changedSearchTerm = changes.searchTerm.currentValue;
      if (!changedSearchTerm || changedSearchTerm.trim().length === 0) {
        this.deadlinesEaktenSearchResult = [];
      }
      if (DeadlinesEaktenSearchResultComponent.validateSearchTerm(changedSearchTerm)) {
        this.pageSize = 10;
        this.pageIndex = 0;
        this.search(0, 10, changedSearchTerm);
      }
    }
  }

  getPageEvent(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    const skipCount = pageEvent.pageIndex * pageEvent.pageSize;
    const maxItems = pageEvent.pageSize;

    if (DeadlinesEaktenSearchResultComponent.validateSearchTerm(this.searchTerm)) {
      this.search(skipCount, maxItems, this.searchTerm);
    }
  }

  selectedSearchItem(item: DeadlinesEaktenSearch): void {
    this.selectedItem.emit(item);
  }

  private search(skipCount: number, maxItems: number, searchTerm: string): void {
    this.isLoading = true;
    this.deadlinesEaktenSearchService
      .eaktenSearch(EaktenSearchRequest.of(skipCount, maxItems, searchTerm, this.deadlinesEaktenSearchService.getUserRole()))
      .subscribe((searchResult) => {
        this.deadlinesEaktenSearchResult = [];
        searchResult?.list?.entries.forEach((item) => {
          this.deadlinesEaktenSearchResult.push(item.entry);
        });
        this.length = searchResult?.list?.pagination?.totalItems;
        this.isLoading = false;
      });
  }

  private static validateSearchTerm(searchTerm: string): boolean {
    return searchTerm !== '' && searchTerm.trim().length >= 3;
  }
}
