import { Component, Input, OnInit } from '@angular/core';
import { CalendarDeadlinesWeeklyModel, DailyEventsOfWeek } from '../../model/calendar-deadlines-weekly-model';
import { DeadlinesEvent } from '../../../common/model/deadlines-event';
import { CalendarService } from '../../service/calendar.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalDate, LocalTime } from '@js-joda/core';
import { CalendarDeadlinesSelectedViewType } from '../../model/calendar-deadlines-selected-view-type';

@Component({
  selector: 'app-calendar-weekly-view',
  templateUrl: './calendar-weekly-view.component.html',
  styleUrls: ['./calendar-weekly-view.component.css']
})
export class CalendarWeeklyViewComponent implements OnInit {
  @Input() calendarDeadlinesWeeklyModel?: CalendarDeadlinesWeeklyModel;

  selectedWeeklyDateIndex = -1;
  timeOptions: string[] = [];
  selectedTimeOption = '09:00';
  selectedDailyEventsOfWeek?: DailyEventsOfWeek | null;

  constructor(private calendarService: CalendarService, private router: Router) {}

  ngOnInit(): void {
    for (let i = 0; i <= 23; i++) {
      let option = '';
      if (i < 10) {
        option = `0${i}:00`;
      } else {
        option = `${i}:00`;
      }
      this.timeOptions.push(option);
    }

    const dateTime = this.calendarService.getCalendarDateTime();
    const date = dateTime.calendarDate;
    const time = dateTime.calendarTime.withMinute(0).withSecond(0).withNano(0);

    const selectedViewType = this.calendarService.getCalendarDeadlinesSelectedViewType();
    if (selectedViewType === CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES) {
      this.selectedViewWithoutTime(date);
    } else {
      this.selectedViewWithTime(date, time);
    }

    this.selectedTimeOption = time.hour() < 10 ? `0${time.hour()}:00` : `${time.hour()}:00`;
    this.scrollTo();
  }

  private selectedViewWithoutTime(date: LocalDate): void {
    this.calendarDeadlinesWeeklyModel?.allDayDeadlines.forEach((item, index) => {
      if (item.localDate.compareTo(date) === 0) {
        this.selectedWeeklyDateIndex = index;
        this.selectedDailyEventsOfWeek = item;
      }
    });
  }

  private selectedViewWithTime(date: LocalDate, time: LocalTime): void {
    this.calendarDeadlinesWeeklyModel?.dailyDeadlinesWithTime.forEach((rowValue) => {
      rowValue.forEach((item, colIndex) => {
        if (item.localDate.compareTo(date) === 0 && item.hourlyTime?.compareTo(time) === 0) {
          this.selectedWeeklyDateIndex = colIndex;
          this.selectedDailyEventsOfWeek = item;
        }
      });
    });
  }

  selectedTime(option: any): void {
    this.selectedTimeOption = option.target.value;
    const date = this.calendarService.getCalendarDate();
    const time = LocalTime.parse(this.selectedTimeOption);
    this.calendarService.saveCalendarDateTime(date, time);
    this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME);
    this.selectedViewWithTime(date, time);
    this.scrollTo();
  }

  showDailyEventsOfWeekWithoutTime(dailyEventsOfWeek: DailyEventsOfWeek, selectedWeeklyDateIndex: number): void {
    this.selectedWeeklyDateIndex = selectedWeeklyDateIndex;
    this.selectedDailyEventsOfWeek = dailyEventsOfWeek;
    this.selectedTimeOption = '00:00';
    this.calendarService.saveCalendarDateTime(dailyEventsOfWeek.localDate, LocalTime.of(0, 0, 0, 0));
    this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);
    this.calendarService.triggerUpdateNavigationDate();
  }

  showDailyEventsOfWeekWithTime(dailyEventsOfWeek: DailyEventsOfWeek, selectedWeeklyDateIndex: number): void {
    this.selectedWeeklyDateIndex = selectedWeeklyDateIndex;
    this.selectedDailyEventsOfWeek = dailyEventsOfWeek;
    this.selectedTimeOption = dailyEventsOfWeek.hourlyTime ? dailyEventsOfWeek.hourlyTime.toString() : '00:00';
    this.calendarService.saveCalendarDateTime(dailyEventsOfWeek.localDate, dailyEventsOfWeek.hourlyTime);
    this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME);
    this.calendarService.triggerUpdateNavigationDate();
  }

  newEventWithoutTime(dailyEventsOfWeek: DailyEventsOfWeek, selectedWeeklyDateIndex: number): void {
    this.showDailyEventsOfWeekWithoutTime(dailyEventsOfWeek, selectedWeeklyDateIndex);
    this.router.navigate([this.calendarService.getEventNewLink()]);
  }

  newEventWithTime(dailyEventsOfWeek: DailyEventsOfWeek, selectedWeeklyDateIndex: number): void {
    this.showDailyEventsOfWeekWithTime(dailyEventsOfWeek, selectedWeeklyDateIndex);
    this.router.navigate([this.calendarService.getEventNewLink()]);
  }

  showDailyEvent(deadlinesEvent: DeadlinesEvent): void {
    this.router.navigate([this.calendarService.getEventDetailsLink(deadlinesEvent.id)]);
  }

  private scrollTo(): void {
    let alreadyScrolled = false;

    interval(100)
      .pipe(
        take(10),
        map(() => {
          const itemToScrollTo = document.getElementById(this.selectedTimeOption);
          if (itemToScrollTo && !alreadyScrolled) {
            itemToScrollTo.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
            alreadyScrolled = true;
          }
        })
      )
      .subscribe();
  }
}
