import { DeadlinesEvent } from '../../common/model/deadlines-event';
import { LocalDate, LocalTime } from '@js-joda/core';

export class CalendarDeadlinesWeeklyModel {
  actualDayIndex: number;
  dailyHeaderOfWeek: string[];
  allDayDeadlines: DailyEventsOfWeek[];
  dailyDeadlinesWithTime: DailyEventsOfWeek[][];

  constructor(actualDayIndex: number, dailyHeaderOfWeek: string[], allDayDeadlines: DailyEventsOfWeek[], dailyDeadlines: DailyEventsOfWeek[][]) {
    this.actualDayIndex = actualDayIndex;
    this.dailyHeaderOfWeek = dailyHeaderOfWeek;
    this.allDayDeadlines = allDayDeadlines;
    this.dailyDeadlinesWithTime = dailyDeadlines;
  }
}

export class DailyEventsOfWeek {
  localDate: LocalDate;
  extendedTimeDailyLabel: string;
  hourlyDeadlineEvents: DeadlinesEvent[];
  hourlyTime?: LocalTime;
  firstEvents: DeadlinesEvent[];
  totalTimeSlotEvents: number;

  constructor(localDate: LocalDate, extendedTimeDailyLabel: string, hourlyDeadlineEvents: DeadlinesEvent[]) {
    this.localDate = localDate;
    this.extendedTimeDailyLabel = extendedTimeDailyLabel;
    this.hourlyDeadlineEvents = hourlyDeadlineEvents;
    this.firstEvents = [];
    if (this.hourlyDeadlineEvents.length >= 1) {
      this.firstEvents.push(this.hourlyDeadlineEvents[0]);
    }
    if (this.hourlyDeadlineEvents.length >= 2) {
      this.firstEvents.push(this.hourlyDeadlineEvents[1]);
    }
    this.totalTimeSlotEvents = this.hourlyDeadlineEvents.length - this.firstEvents.length;
  }

  setHourlyTime(hourlyTime: LocalTime): DailyEventsOfWeek {
    this.hourlyTime = hourlyTime;
    return this;
  }
}
