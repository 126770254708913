export class DeadlinesEaktenSearchPageRequest {
  skipCount: number;
  maxItems: number;

  private constructor(skipCount: number, maxItems: number) {
    this.skipCount = skipCount;
    this.maxItems = maxItems;
  }

  static of(skipCount: number, maxItems: number): DeadlinesEaktenSearchPageRequest {
    return new DeadlinesEaktenSearchPageRequest(skipCount, maxItems);
  }
}

export class EaktenSearchRequest {
  pageRequest: DeadlinesEaktenSearchPageRequest;
  searchTerm: string;
  role: string;

  private constructor(pageRequest: DeadlinesEaktenSearchPageRequest, searchTerm: string, role: string) {
    this.pageRequest = pageRequest;
    this.searchTerm = searchTerm;
    this.role = role;
  }

  static of(skipCount: number, maxItems: number, searchTerm: string, role: string): EaktenSearchRequest {
    return new EaktenSearchRequest(DeadlinesEaktenSearchPageRequest.of(skipCount, maxItems), searchTerm, role);
  }
}
