import { LocalDate } from '@js-joda/core';

export class DateTuple {
  firstDate: LocalDate;
  secondDate: LocalDate;

  constructor(firstDate: LocalDate, secondDate: LocalDate) {
    this.firstDate = firstDate;
    this.secondDate = secondDate;
  }
}
