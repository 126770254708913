import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashletComponent } from './components/dashlet.component';
import { DashletSearchComponent } from './components/dashlet-search/dashlet-search.component';
import { DashletViewComponent } from './components/dashlet-view/dashlet-view.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule, DataColumnModule, DataTableModule, DirectiveModule, PaginationModule, TemplateModule, ToolbarModule } from '@alfresco/adf-core';
import { AppLayoutModule } from '../../../../app/src/app/components/layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { DashletService } from './services/dashlet.service';
import { RecentService } from './services/recent-libraries/recent.service';
import { FavoriteService } from './services/favorite-libraries/favorite.service';
import { SearchService } from './services/search-libraries/search.service';
import { AuthService } from './services/auth/auth.service';
import { DocumentListModule } from '@alfresco/adf-content-services';
import { ExtensionService, ExtensionsModule } from '@alfresco/adf-extensions';
import { DirectivesModule } from '../../../../app/src/app/directives/directives.module';
import { MatSelectModule } from '@angular/material/select';
import { ToggleDashletFavoriteComponent } from './toolbar/toggle-dashlet-favorite/toggle-dashlet-favorite.component';
import { MatIconModule } from '@angular/material/icon';
import { DashletActionService } from './services/dashlet.action.service';
import * as navigationRules from '../rules/dashlet.navigation.rules';
import * as appRules from '../rules/dashlet.app.rules';

@NgModule({
  declarations: [DashletComponent, DashletSearchComponent, DashletViewComponent, ToggleDashletFavoriteComponent],
  imports: [
    CoreModule.forChild(),
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ToolbarModule,
    AppLayoutModule,
    TranslateModule,
    DocumentListModule,
    DataTableModule,
    TemplateModule,
    DataColumnModule,
    ExtensionsModule,
    PaginationModule,
    DirectivesModule,
    MatSelectModule,
    DirectiveModule,
    MatIconModule
  ],
  exports: [DashletComponent],
  providers: [RecentService, FavoriteService, SearchService, AuthService, DashletService, DashletActionService]
})
export class ProjectDashletModule {
  constructor(extensions: ExtensionService) {
    extensions.setComponents({
      'app.dashlet.toolbar.toggleDashletFavorite': ToggleDashletFavoriteComponent,
      'project.dashlet.component': DashletComponent
    });

    extensions.setEvaluators({
      canToggleDashletFavorite: appRules.canToggleDashletFavorite,
      'app.dashlet.navigation.isProjectDashlet': navigationRules.isProjectDashlet,
      'app.dashlet.navigation.isNotProjectDashlet': navigationRules.isNotProjectDashlet
    });
  }
}
