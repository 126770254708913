import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarService } from '../../service/calendar.service';
import { DeadlinesCalendarType } from '../../../common/model/deadlines-calendar-type';
import { CalendarDeadlinesWeeklyModel } from '../../model/calendar-deadlines-weekly-model';
import { CalendarDeadlinesMonthlyModel } from '../../model/calendar-deadlines-monthly-model';
import { DeadlinesNavigation } from '../../../common/model/deadlines-navigation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar-container.component.html',
  styleUrls: ['./calendar-container.component.css']
})
export class CalendarContainerComponent implements OnInit, OnDestroy {
  isLoading = false;
  calendarType: DeadlinesCalendarType = DeadlinesCalendarType.MONTHLY;
  calendarDeadlinesMonthlyModel?: CalendarDeadlinesMonthlyModel;
  calendarDeadlinesWeeklyModel?: CalendarDeadlinesWeeklyModel;
  calendarCalculationTrigger$: Subscription;
  userRoleTrigger$: Subscription;
  calendarDeadlinesWeeklySubject$: Subscription;
  calendarDeadlinesMonthlySubject$: Subscription;

  constructor(private calendarService: CalendarService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.calendarService.setContextAndId(this.activatedRoute.snapshot.paramMap.get('context'), this.activatedRoute.snapshot.paramMap.get('id'));

    if (this.calendarService.shouldRedirect()) {
      this.router.navigate(['/deadlines/electronic-file-lookup']);
    }

    this.calendarService.setContextNavigation(DeadlinesNavigation.CALENDAR);
    this.calendarService.resetCalendarCalculationTrigger();
    this.calendarCalculationTrigger$ = this.calendarService.getCalendarCalculationTrigger().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.createCalendarModel();
      }
    });

    this.calendarDeadlinesWeeklySubject$ = this.calendarService.calendarDeadlinesWeeklySubject$.subscribe((calendarDeadlinesWeeklyModel) => {
      this.calendarDeadlinesWeeklyModel = calendarDeadlinesWeeklyModel;
      this.isLoading = false;
    });

    this.calendarDeadlinesMonthlySubject$ = this.calendarService.calendarDeadlinesMonthlySubject$.subscribe((calendarDeadlinesMonthlyModel) => {
      this.calendarDeadlinesMonthlyModel = calendarDeadlinesMonthlyModel;
      this.isLoading = false;
    });

    this.userRoleTrigger$ = this.calendarService.getUserRoleTrigger().subscribe(() => {
      this.isLoading = true;
      this.createCalendarModel();
    });
  }

  ngOnDestroy() {
    this.calendarCalculationTrigger$.unsubscribe();
    this.userRoleTrigger$.unsubscribe();
    this.calendarDeadlinesWeeklySubject$.unsubscribe();
    this.calendarDeadlinesMonthlySubject$.unsubscribe();
  }

  isWeeklySelection(): boolean {
    return this.calendarService.getWeekly(this.calendarType) || this.calendarService.getWeeklyWorking(this.calendarType);
  }

  isMonthlySelection(): boolean {
    return !this.isWeeklySelection();
  }

  private createCalendarModel(): void {
    this.calendarType = this.calendarService.getCalendarType();
    if (this.calendarService.getWeekly(this.calendarType) || this.calendarService.getWeeklyWorking(this.calendarType)) {
      this.calendarService.triggerCreateCalendarDeadlinesWeeklyModel();
    } else {
      this.calendarService.triggerCreateCalendarDeadlinesMonthlyModel();
    }
  }
}
