import { Injectable } from '@angular/core';
import { AppConfigService } from '@alfresco/adf-core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EaktenSearchRequest } from '../model/eakten-search-request';
import { EMPTY, Observable, of } from 'rxjs';
import { EaktenSearchResponse } from '../model/eakten-search-response';
import { catchError, expand, map } from 'rxjs/operators';
import { ErrorHandler } from '../error/error.handler';
import { Eakte } from '../model/eakte';
import { ContentApiService } from '@alfresco/aca-shared';

@Injectable()
export class EakteService {
  private readonly PZS_EAKTE_ID = 'pzs:eakteId';
  private readonly uri: string;
  private readonly EAKTEN_PATH = '/api/ui/eakten/';
  private readonly SEARCH_PATH = `${this.EAKTEN_PATH}search`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private contentApiService: ContentApiService
  ) {
    this.uri = this.appConfig.get<string>('ecmHost');
  }

  eaktenSearch(searchRequest: EaktenSearchRequest): Observable<EaktenSearchResponse> {
    return this.http.post<EaktenSearchResponse>(`${this.uri}${this.SEARCH_PATH}`, searchRequest, this.authService.createHttpOptions()).pipe(
      map((result) => new EaktenSearchResponse(result.list)),
      catchError(ErrorHandler.handleError<EaktenSearchResponse>('searchEakten', new EaktenSearchResponse({})))
    );
  }

  eakteById(id: string): Observable<Eakte> {
    return this.http
      .get<Eakte>(`${this.EAKTEN_PATH}${id}`, this.authService.createHttpOptions())
      .pipe(catchError(ErrorHandler.handleError<Eakte>('eakteById', new Eakte())));
  }

  findEakteIdByNodeId(nodeId: string): Observable<string> {
    return this.contentApiService.getNode(nodeId).pipe(
      expand((nodeResult) => {
        if (!nodeResult.entry) {
          return EMPTY;
        }

        const node = nodeResult.entry;
        const eakteId = node.properties[this.PZS_EAKTE_ID];

        if (eakteId) {
          return of(eakteId);
        }

        if (!node.parentId) {
          return EMPTY;
        }

        return this.contentApiService.getNode(node.parentId);
      })
    );
  }
}
