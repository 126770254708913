<div *ngIf='!showPrintPreview' class='deadlines-agenda-container'>

  <header>
    <app-deadlines-main-navigation></app-deadlines-main-navigation>
    <a routerLink='{{eventNewLink}}' class="deadlines-agenda-new-event-link">Neue Frist anlegen</a>
    <mat-form-field appearance='fill'>
      <mat-label>Zeitraum wählen</mat-label>
      <mat-select [(value)]='timeRange' (selectionChange)='searchDeadlinesByTimeRange($event.value)'>
        <mat-option [value]='dailyTimeRange()'>Heute</mat-option>
        <mat-option [value]='weeklyTimeRange()'>Woche</mat-option>
        <mat-option [value]='monthlyTimeRange()'>Monat</mat-option>
        <mat-option [value]='totalTimeRange()'>Gesamt</mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf='deadlinesEvents.length !== 0' mat-button (click)='printPreview()'
            class='deadlines-agenda-preview-button'>
      <img src='assets/images/print_black_24dp.svg' alt='print'/> Vorschau
    </button>
    <span *ngIf='deadlinesEvents.length === 0' class='deadlines-agenda-empty-preview'></span>
  </header>

  <ng-template [ngIf]='isLoading' [ngIfElse]='searchResult'>
    <div class='deadlines-agenda-search-result'>
      <mat-progress-spinner mode='indeterminate' class='deadlines-agenda-search-spinner'></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #searchResult>
    <ng-container *ngIf='deadlinesEvents.length !== 0'>
      <div class='deadlines-agenda-search-result'>

        <div class='deadlines-agenda-search-result-list'>
          <mat-accordion>

            <ng-container *ngFor='let deadlinesEvent of deadlinesEvents; let last = last; index as i'>
              <mat-expansion-panel [expanded]='deadlineEventStep === i' (opened)='setDeadlineEventStep(i)' hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class='deadlines-agenda-search-result-list-item-title'>
                      <div>
                        <img src='assets/images/ft_ic_folder.svg' alt='folder'>
                      </div>
                      <div>
                        <div class="deadlines-agenda-search-result-list-item-title">
                          <div class="deadlines-agenda-search-result-list-item-title-header">{{deadlinesEvent.title}}</div>
                          <div class="deadlines-agenda-search-result-list-item-title-header">Datum:&nbsp;{{formatDate(deadlinesEvent.date)}}</div>
                          <div>E-Akte:&nbsp;{{deadlinesEvent.eakteId}}</div>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <table>
                  <tr>
                    <td>Titel:</td>
                    <td
                      class='deadlines-agenda-search-result-list-event-property'>
                      <a routerLink='{{getEventEditLink(deadlinesEvent.id)}}'>{{deadlinesEvent.title}}</a></td>
                  </tr>
                  <tr>
                    <td>Datum:</td>
                    <td
                      class='deadlines-agenda-search-result-list-event-property'>{{formatDate(deadlinesEvent.date)}}</td>
                  </tr>
                  <tr>
                    <td>Zeit:</td>
                    <td *ngIf='deadlinesEvent.time'
                        class='deadlines-agenda-search-result-list-event-property'>{{deadlinesEvent.time}}</td>
                    <td *ngIf='!deadlinesEvent.time' class='deadlines-agenda-search-result-list-event-property'>
                      <img src='assets/images/calendar_today.svg' alt='all_day'/>
                      (ganzt&auml;gig)
                    </td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td
                      class='deadlines-agenda-search-result-list-event-property'>{{convertState(deadlinesEvent.state)}}</td>
                  </tr>
                  <tr>
                    <td>Quelle:</td>
                    <ng-template [ngIf]='deadlinesEvent.isSourceLink()' [ngIfElse]='sourceAsPlainText'>
                      <td class='deadlines-agenda-search-result-list-event-property'><a href="{{deadlinesEvent.source}}" target="_blank">Datei &ouml;ffnen</a></td>
                    </ng-template>
                    <ng-template #sourceAsPlainText>
                      <td class='deadlines-agenda-search-result-list-event-property'>{{deadlinesEvent.source}}</td>
                    </ng-template>
                  </tr>
                  <tr>
                    <td>Kategorie:</td>
                    <td
                      class='deadlines-agenda-search-result-list-event-property'>{{convertCategory(deadlinesEvent.category)}}</td>
                  </tr>
                  <tr>
                    <td>E-Akte:</td>
                    <td
                      class='deadlines-agenda-search-result-list-event-property'>{{deadlinesEvent.eakteId}}</td>
                  </tr>
                </table>

                <mat-action-row>
                  <button *ngIf='i > 0' mat-button color='primary' (click)='prevDeadlineEventStep()'>Vorherige</button>
                  <button *ngIf='!last' mat-button color='primary' (click)='nextDeadlineEventStep()'>N&auml;chste
                  </button>
                  <button *ngIf='last' mat-button color='primary' (click)='nextDeadlineEventStep()'>Ende</button>
                </mat-action-row>

              </mat-expansion-panel>
            </ng-container>

          </mat-accordion>
        </div>

      </div>

      <footer>
        <mat-divider></mat-divider>
        <mat-paginator [length]='length'
                       [pageSize]='pageSize'
                       [pageIndex]='pageIndex'
                       [pageSizeOptions]='pageSizeOptions'
                       [showFirstLastButtons]=true
                       (page)='getPageEvent($event)'
                       aria-label='Select page'>
        </mat-paginator>
      </footer>

    </ng-container>

    <ng-container *ngIf='deadlinesEvents.length === 0'>
      <div class='deadlines-agenda-search-result'>
        <h3>Keine Ergebnisse.</h3>
        <div class='deadlines-agenda-no-search-result'>Anderen Zeitraum wählen oder <a routerLink='{{eventNewLink}}'>neue
          Frist</a> anlegen.
        </div>
      </div>
    </ng-container>

  </ng-template>

</div>


<div *ngIf='showPrintPreview' class='deadlines-agenda-print-container'>

  <div class='deadlines-agenda-print-header'>
    <table>
      <tr>
        <td>
          <button mat-button (click)='closePreview()'>Zur&uuml;ck</button>
        </td>
        <td>
          <button printSectionId="print-section" ngxPrint mat-button><img src='assets/images/print_black_24dp.svg'
                                                                          alt='print'/>
            Drucken
          </button>
        </td>
      </tr>
    </table>
    <mat-divider></mat-divider>
  </div>

  <div id="print-section">
    <div *ngFor='let deadlinesEvent of deadlinesEvents; let last = last'>

      <table>
        <tr>
          <td class='cell-title'>Titel:</td>
          <td class='cell-description'>{{deadlinesEvent.title}}</td>
        </tr>
        <tr>
          <td class='cell-title'>Datum:</td>
          <td class='cell-description'>{{formatDate(deadlinesEvent.date)}}</td>
        </tr>
        <tr>
          <td class='cell-title'>Zeit:</td>
          <td *ngIf='deadlinesEvent.time' class='cell-description'>{{deadlinesEvent.time}}</td>
          <td *ngIf='!deadlinesEvent.time' class='cell-description'>ganzt&auml;gig</td>
        </tr>
        <tr>
          <td class='cell-title'>Status:</td>
          <td class='cell-description'>{{convertState(deadlinesEvent.state)}}</td>
        </tr>
        <tr>
          <td class='cell-title'>Quelle:</td>
          <td class='cell-description'>{{deadlinesEvent.source}}</td>
        </tr>
        <tr>
          <td class='cell-title'>Kategorie:</td>
          <td class='cell-description'>{{convertCategory(deadlinesEvent.category)}}</td>
        </tr>
        <tr>
          <td>E-Akte:</td>
          <td class='cell-description'>{{deadlinesEvent.eakteId}}</td>
        </tr>
      </table>

      <hr *ngIf='!last'>

    </div>
  </div>

  <div class='deadlines-agenda-print-header'>
    <mat-divider></mat-divider>
    <table>
      <tr>
        <td>
          <button mat-button (click)='closePreview()'>Zur&uuml;ck</button>
        </td>
        <td>
          <button printSectionId="print-section" ngxPrint mat-button><img src='assets/images/print_black_24dp.svg'
                                                                          alt='print'/>
            Drucken
          </button>
        </td>
      </tr>
    </table>
  </div>

</div>
