<form class="aca-rule-details__form" [formGroup]="form">

  <ng-container *ngIf="!preview">
    <div class="aca-rule-details__form__row">
      <label for="rule-details-name-input">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.NAME' | translate }}</label>
      <div>
        <mat-form-field floatLabel='never'>
          <input
            id="rule-details-name-input"
            matInput type="text" formControlName="name" data-automation-id="rule-details-name-input"
            [placeholder]="getPlaceholder('name') | translate">
          <mat-error>{{ getErrorMessage(name) | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="aca-rule-details__form__row">
      <label for="rule-details-description-textarea">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.DESCRIPTION' | translate }}</label>
      <div>
        <mat-form-field floatLabel='never'>
        <textarea
          id="rule-details-description-textarea"
          matInput formControlName="description" data-automation-id="rule-details-description-textarea"
          [placeholder]="getPlaceholder('description') | translate">
        </textarea>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <hr>

  <div class="aca-rule-details__form__row aca-rule-details__form__triggers">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.WHEN' | translate }}</div>
    <div>
      <aca-rule-triggers formControlName="triggers" data-automation-id="rule-details-triggers-component"></aca-rule-triggers>
      <mat-error class="rule-details-error">{{ getErrorMessage(triggers) | translate }}</mat-error>
    </div>
  </div>

  <hr>

  <aca-rule-composite-condition formControlName="conditions"></aca-rule-composite-condition>
  <mat-error class="rule-details-error">{{ getErrorMessage(conditions) | translate }}</mat-error>

  <hr>

  <div class="aca-rule-details__form__row aca-rule-details__form__actions">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.PERFORM_ACTIONS' | translate }}</div>
    <aca-rule-action-list
      formControlName="actions"
      [actionDefinitions]="actionDefinitions"
      [aspects]="aspects"
      [readOnly]="readOnly">
    </aca-rule-action-list>
  </div>

  <hr>

  <div class="aca-rule-details__form__row">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.OPTIONS' | translate }}</div>
    <aca-rule-options [form]="form" [preview]="preview" data-automation-id="rule-details-options-component"></aca-rule-options>
  </div>

</form>
