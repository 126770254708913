<div class="sidenav">
  <ng-container [ngSwitch]="mode">
    <div class="section action-menu" [ngClass]="'section--' + mode">
      <app-main-action [expanded]="mode === 'expanded'"></app-main-action>
      <app-create-menu [expanded]="mode === 'expanded'"></app-create-menu>
    </div>

    <div class="section-sub-actions">
      <div *ngFor="let group of groups; trackBy: trackByGroupId" class="section" [ngClass]="'section--' + mode">
        <ng-container *ngSwitchCase="'expanded'">
          <mat-list-item *ngFor="let item of group.items; trackBy: trackByLinkId">
            <ng-container *ngIf="!item.component">
              <app-expand-menu [item]="item"></app-expand-menu>
            </ng-container>

            <ng-container *ngIf="item.component">
              <adf-dynamic-component [data]="{ item: item, state: 'expanded' }" [id]="item.component"></adf-dynamic-component>
            </ng-container>
          </mat-list-item>
        </ng-container>

        <ng-container *ngSwitchCase="'collapsed'">
          <div class="list-item" *ngFor="let item of group.items; trackBy: trackByLinkId">
            <ng-container *ngIf="!item.component">
              <app-button-menu [item]="item"></app-button-menu>
            </ng-container>

            <ng-container *ngIf="item.component">
              <adf-dynamic-component [data]="{ item: item, state: 'collapsed' }" [id]="item.component"> </adf-dynamic-component>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
