import { LocalDate, LocalTime } from '@js-joda/core';
import { enumKeys } from '../util/enum-helper';

export class DeadlinesEvent {
  id?: string;
  title: string;
  date: LocalDate;
  time?: LocalTime;
  state: DeadlinesEventState;
  source: string;
  category: DeadlinesEventCategory;
  dependingOn?: string;
  eakteId?: string;
  isProjectEvent: boolean;
  parentToIds?: string[];
  parentToEvents?: DeadlinesEvent[];
  createdBy: string;

  constructor(
    title: string,
    date: LocalDate,
    state: DeadlinesEventState,
    source: string,
    category: DeadlinesEventCategory,
    isProjectEvent: boolean,
    createdBy: string
  ) {
    this.title = title;
    this.date = date;
    this.state = state;
    this.source = source;
    this.category = category;
    this.isProjectEvent = isProjectEvent;
    this.createdBy = createdBy;
  }

  setId(id: string): DeadlinesEvent {
    this.id = id;
    return this;
  }

  setTitle(titel: string): DeadlinesEvent {
    this.title = titel;
    return this;
  }

  setDate(date: LocalDate): DeadlinesEvent {
    this.date = date;
    return this;
  }

  setTime(time: LocalTime): DeadlinesEvent {
    this.time = time;
    return this;
  }

  setState(state: DeadlinesEventState): DeadlinesEvent {
    this.state = state;
    return this;
  }

  setSource(source: string): DeadlinesEvent {
    this.source = source;
    return this;
  }

  setCategory(category: DeadlinesEventCategory): DeadlinesEvent {
    this.category = category;
    return this;
  }

  setDependingOn(dependingOn: string): DeadlinesEvent {
    this.dependingOn = dependingOn;
    return this;
  }

  setEakteId(eakteId: string): DeadlinesEvent {
    this.eakteId = eakteId;
    return this;
  }

  setIsProjectEvent(isProjectEvent: boolean): DeadlinesEvent {
    this.isProjectEvent = isProjectEvent;
    return this;
  }

  setParentToIds(parentToIds?: Array<string>): DeadlinesEvent {
    this.parentToIds = parentToIds;
    return this;
  }

  setParentToEvents(parentToEvents?: Array<DeadlinesEvent>): DeadlinesEvent {
    this.parentToEvents = parentToEvents;
    return this;
  }

  setCreatedBy(createdBy: string): DeadlinesEvent {
    this.createdBy = createdBy;
    return this;
  }

  isSourceLink(): boolean {
    return this.source?.includes('libraries');
  }

  public static of(otherEvent: any): DeadlinesEvent {
    const title: string = otherEvent.titel;
    const date: LocalDate = LocalDate.parse(otherEvent.datum);
    const state: DeadlinesEventState = otherEvent.status;
    const source: string = otherEvent.quelle;
    const category: DeadlinesEventCategory = otherEvent.kategorie;
    const isProjectEvent: boolean = otherEvent.projekttraegerFrist;
    const createdBy: string = otherEvent.erstelltVon;

    const deadlinesEvent = new DeadlinesEvent(title, date, state, source, category, isProjectEvent, createdBy)
      .setId(otherEvent.id)
      .setEakteId(otherEvent.eakteId)
      .setDependingOn(otherEvent.abhaengigVon);

    if (otherEvent.uhrzeit) {
      deadlinesEvent.setTime(LocalTime.parse(otherEvent.uhrzeit));
    }

    if (otherEvent.uebergeordnetZuIds && otherEvent.uebergeordnetZuIds.length > 0) {
      const ids: string[] = [];
      otherEvent.uebergeordnetZuIds.forEach((item: string) => ids.push(item));
      deadlinesEvent.setParentToIds(ids);
    } else {
      deadlinesEvent.setParentToIds([]);
    }

    if (otherEvent.uebergeordnetZuFristen && otherEvent.uebergeordnetZuFristen.length > 0) {
      const deadlinesEvents: DeadlinesEvent[] = [];
      otherEvent.uebergeordnetZuFristen.forEach((item: any) => deadlinesEvents.push(DeadlinesEvent.of(item)));
      deadlinesEvent.setParentToEvents(deadlinesEvents);
    } else {
      deadlinesEvent.setParentToEvents([]);
    }

    return deadlinesEvent;
  }

  toFrist(): Frist {
    return new Frist(this);
  }

  static getState(state: string): string {
    for (const key of enumKeys(DeadlinesEventState)) {
      if (state === key) {
        return DeadlinesEventState[key];
      }
    }
    return DeadlinesEventState.offen;
  }

  static getCategory(category: string): string {
    for (const key of enumKeys(DeadlinesEventCategory)) {
      if (category === key) {
        return DeadlinesEventCategory[key];
      }
    }
    return DeadlinesEventCategory.MARKTERKUNDUNGSVERFAHREN;
  }
}

export class Frist {
  id?: string;
  titel?: string;
  datum?: LocalDate;
  uhrzeit?: LocalTime;
  status?: DeadlinesEventState;
  quelle?: string;
  kategorie?: DeadlinesEventCategory;
  abhaengigVon?: string;
  eakteId?: string;
  projekttraegerFrist?: boolean;
  erstelltVon?: string;

  constructor(deadlinesEvent: DeadlinesEvent) {
    this.id = deadlinesEvent.id;
    this.titel = deadlinesEvent.title;
    this.datum = deadlinesEvent.date;
    this.uhrzeit = deadlinesEvent.time;
    this.status = deadlinesEvent.state;
    this.quelle = deadlinesEvent.source;
    this.kategorie = deadlinesEvent.category;
    this.abhaengigVon = deadlinesEvent.dependingOn;
    this.eakteId = deadlinesEvent.eakteId;
    this.projekttraegerFrist = deadlinesEvent.isProjectEvent;
    this.erstelltVon = deadlinesEvent.createdBy;
  }
}

export enum DeadlinesEventState {
  offen = 'offen',
  erledigt = 'erledigt',
  ueberfaellig = 'überfällig'
}

enum DeadlinesEventCategory {
  MARKTERKUNDUNGSVERFAHREN = 'Markterkundungsverfahren',
  NACHFORDERUNGEN = 'Nachforderungen',
  MEILENSTEINPLAN = 'Meilensteinplan',
  BEWILLIGUNGSZEITRAUM = 'Bewilligungszeitraum',
  AUSWAHLVERFAHREN = 'Auswahlverfahren',
  NEBENBESTIMMUNGEN = 'Individuelle Nebenbestimmungen',
  BERICHTERSTATTUNG = 'Berichterstattung (§ 11 Gigabit-Rahmenregelung)',
  VERWENDUNGSNACHWEIS = 'Verwendungsnachweis',
  BEARBEITUNGSFRISTEN = 'Bearbeitungsfristen',
  SONSTIGE = 'Sonstige (nicht im Dokument)'
}
