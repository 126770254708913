import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService, AuthenticationService } from '@alfresco/adf-core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PdfConversionResult } from '../model/pdf-conversion-result';
import { PdfConvertedNodeEntry } from '../model/pdf-converted-node-entry';

@Injectable()
export class PdfConversionService {
  constructor(
    private authenticationService: AuthenticationService,
    private appConfig: AppConfigService,
    private http: HttpClient,
  ) {}

  convertDocument(nodeId: string): Observable<PdfConversionResult> {
    return this.http
      .post<PdfConvertedNodeEntry>(
        this.getConversionUri(),
        {
          nodeRef: nodeId,
          targetType: 'PDF',
        },
        this.createHttpOptions(),
      )
      .pipe(
        map((result) => {
          if (result && result.entry && result.entry.id) {
            return {
              nodeId: result.entry.id,
              isError: false,
            };
          }
          return PdfConversionService.getErrorResult();
        }),
        catchError(() => of(PdfConversionService.getErrorResult())),
      );
  }

  private static getErrorResult(): PdfConversionResult {
    return {
      nodeId: null,
      isError: true,
    };
  }

  private getConversionUri(): string {
    return `${this.appConfig.get<string>(
      'ecmHost',
    )}/alfresco/service/conversion`;
  }

  private createHttpOptions(): { headers: HttpHeaders } {
    const token = this.authenticationService.getToken();
    return {
      headers: new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    };
  }
}
