<div class="deadlines-search-result-dialog-container">

  <div>
    <button mat-button mat-dialog-close class='dialog-close-action' (click)='closeDialog()'>Schließen
    </button>
    <mat-divider></mat-divider>
  </div>

  <div class="deadline-search-result-dialog-result">
    <deadlines-eakten-search-result [searchTerm]="searchTerm" [searchContext]="searchContext"
                                    (selectedItem)="selectedSearchItem($event)"></deadlines-eakten-search-result>
  </div>

</div>
