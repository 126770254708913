import { RuleContext } from '@alfresco/adf-extensions';
import { isPreview } from "@alfresco/aca-shared/rules";

/**
 * Checks if a **Project-Dashlet** route is activated.
 * JSON ref: `app.dashlet.navigation.isProjectDashlet`
 */
export function isProjectDashlet(context: RuleContext): boolean {
  const { url } = context.navigation;
  return url && (url === '/' || url.startsWith('/project-dashlet')) && !isPreview(context);
}

/**
 * Checks if a **Project-Dashlet** route is activated.
 * JSON ref: `app.dashlet.navigation.isNotProjectDashlet`
 */
export function isNotProjectDashlet(context: RuleContext): boolean {
  return !isProjectDashlet(context);
}
