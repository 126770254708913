import { LocalDate, LocalTime } from '@js-joda/core';

export class DateTime {
  calendarDate: LocalDate;
  calendarTime: LocalTime;

  constructor(calendarDate: LocalDate, calendarTime: LocalTime) {
    this.calendarDate = calendarDate;
    this.calendarTime = calendarTime;
  }
}
