import { NgModule } from '@angular/core';
import { OpenNodeInNewTabComponent } from './open-node-in-new-tab.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ExtensionService } from '@alfresco/adf-extensions';
import * as canOpenNodeInNewTabRules from '../rules/open-node-in-new-tab-rules';

@NgModule({
  declarations: [OpenNodeInNewTabComponent],
  imports: [MatIconModule, MatMenuModule],
  exports: [OpenNodeInNewTabComponent]
})
export class OpenNodeInNewTabModule {
  constructor(extensions: ExtensionService) {
    extensions.setComponents({
      'app.context.menu.openNodeInNewTab': OpenNodeInNewTabComponent
    });

    extensions.setEvaluators({
      canOpenNodeInNewTab: canOpenNodeInNewTabRules.canOpenNodeInNewTab
    });
  }
}
