import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeadlinesNavigationService } from '../service/deadlines-navigation.service';
import { DeadlinesContext } from '../../common/model/deadlines-context';

@Component({
  selector: 'app-deadlines-main-navigation',
  templateUrl: './deadlines-main-navigation.component.html',
  styleUrls: ['./deadlines-main-navigation.component.css']
})
export class DeadlinesMainNavigationComponent implements OnInit {
  context = '';
  navigationPath = '';
  activeSearchLink = false;
  activeAgendaLink = false;
  activeCalendarLink = false;
  userRoleChecked = false;

  constructor(private route: ActivatedRoute, private deadlinesNavigationService: DeadlinesNavigationService) {}

  ngOnInit(): void {
    this.setActiveLink(this.route.snapshot.url.join(''));

    const navigationContext = this.deadlinesNavigationService.getNavigationContext();
    const navigationId = this.deadlinesNavigationService.getNavigationId();

    if (navigationContext && navigationId) {
      this.navigationPath = `/${navigationContext}/${navigationId}`;

      const navigationTitle = this.deadlinesNavigationService.getNavigationTitle()
        ? this.deadlinesNavigationService.getNavigationTitle()
        : navigationId;

      this.context = navigationContext === DeadlinesContext.EAKTE ? `E-Akte: ${navigationTitle}` : `Aggregierter Kalender`;

      this.userRoleChecked = this.deadlinesNavigationService.getUserRole() !== '';
    }
  }

  setActiveLink(url: string): void {
    this.deadlinesNavigationService.getActiveLink(url).subscribe((deadlinesNavigationActiveLink) => {
      this.activeSearchLink = deadlinesNavigationActiveLink.activeSearchLink;
      this.activeAgendaLink = deadlinesNavigationActiveLink.activeAgendaLink;
      this.activeCalendarLink = deadlinesNavigationActiveLink.activeCalendarLink;
    });
  }

  updateUserRole(value) {
    this.userRoleChecked = value;
    this.deadlinesNavigationService.setUserRole(this.userRoleChecked ? 'PT_GLOBAL_R' : '');
    this.deadlinesNavigationService.triggerUserRole(this.userRoleChecked);
  }

  hasRole(): boolean {
    return this.deadlinesNavigationService.hasRole();
  }
}
