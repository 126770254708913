<aca-page-layout>
    <aca-page-layout-header>
        <div class="dashlet-header">
            <div class="dashlet-headline">Projektübersicht</div>
            <adf-toolbar class="adf-toolbar--inline">
                <aca-dashlet-search [lastAction]="initAction" (viewLibraries)="viewLibraries($event)"></aca-dashlet-search>
            </adf-toolbar>
        </div>
    </aca-page-layout-header>

    <aca-page-layout-content>
        <aca-dashlet-view [viewSubject$]="viewSubject$" [lastAction]="initAction" class="dashlet-view"></aca-dashlet-view>
    </aca-page-layout-content>
</aca-page-layout>
