import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EaktenSearchRequest } from '../../common/model/eakten-search-request';
import { EaktenSearchResponse } from '../../common/model/eakten-search-response';
import { DeadlinesNavigationService } from '../../deadlines-main-navigation/service/deadlines-navigation.service';
import { DeadlinesService } from '../../common/service/deadlines.service';
import { EakteService } from '../../common/service/eakte.service';

@Injectable()
export class DeadlinesEaktenSearchService {
  constructor(
    private deadlinesNavigationService: DeadlinesNavigationService,
    private deadlinesService: DeadlinesService,
    private eakteService: EakteService
  ) {}

  getUserRoleTrigger(): Observable<boolean> {
    return this.deadlinesService.getUserRoleTrigger();
  }

  getUserRole(): string {
    return this.deadlinesService.getUserRole();
  }

  setContextProperties(context: string, id: string, title: string) {
    this.deadlinesService.setContextProperties(context, id, title);
  }

  setSearchTerm(searchTerm: string): void {
    this.deadlinesService.setSearchTerm(searchTerm);
  }

  getSearchTerm(): string {
    return this.deadlinesService.getSearchTerm();
  }

  showNavigation(): Observable<boolean> {
    return this.deadlinesNavigationService.showNavigation();
  }

  eaktenSearch(searchRequest: EaktenSearchRequest): Observable<EaktenSearchResponse> {
    return this.eakteService.eaktenSearch(searchRequest);
  }
}
