import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Node, NodeChildAssociation } from '@alfresco/js-api';
import { CounterSignatureService } from '../service/counter-signature.service';
import { CounterSignatureResult } from '../model/counter-signature-result';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'counter-signature-dialog',
  templateUrl: './counter-signature-dialog.component.html',
  styleUrls: ['./counter-signature-dialog.component.scss']
})
export class CounterSignatureDialogComponent implements OnInit {
  selectedNode: NodeChildAssociation | Node;
  isLoading = false;
  signedDocument = false;
  counterSignatureResult: CounterSignatureResult = new CounterSignatureResult();

  counterSignatureForm: FormGroup = new FormGroup({
    role: new FormControl('', [Validators.required, Validators.maxLength(75)])
  });

  constructor(
    private counterSignatureService: CounterSignatureService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<CounterSignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public node: NodeChildAssociation | Node
  ) {
    this.selectedNode = node;
  }

  ngOnInit() {
    setTimeout(() => {
      this.counterSignatureForm.reset();
      this.renderer.selectRootElement('#roleInput').focus();
    }, 100);
  }

  signDocument(counterSignatureForm: any): void {
    if (this.counterSignatureForm.valid) {
      this.isLoading = true;
      this.counterSignatureService.signDocument(this.selectedNode.id, counterSignatureForm.role).subscribe((result) => {
        this.signedDocument = true;
        this.counterSignatureResult = result;
        this.isLoading = false;
      });
    }
  }

  shouldReload(): void {
    if (this.successfullySigned() && this.selectedNode instanceof Node) {
      location.reload();
    }
  }

  successfullySigned(): boolean {
    return this.counterSignatureResult.userSuccessfullySigned && !this.counterSignatureResult.isError;
  }

  hasError = (controlName: string, errorName: string) => this.counterSignatureForm.controls[controlName].hasError(errorName);

  hasErrorCounterSignatureResult(): boolean {
    return this.counterSignatureResult.isError;
  }

  hasInvalidNumberOfSignatures(): boolean {
    return this.counterSignatureResult.hasInvalidNumberOfSignatures();
  }
}
