export class EaktenSearchResponse {
  list: DeadlinesEaktenSearchList;

  constructor(list: any) {
    this.list = new DeadlinesEaktenSearchList(list);
  }
}

export class DeadlinesEaktenSearchList {
  pagination: DeadlinesEaktenSearchPagination;
  entries: DeadlinesEaktenSearchEntry[] = [];

  constructor(list: any) {
    this.pagination = new DeadlinesEaktenSearchPagination(list?.pagination);
    list?.entries?.forEach((item: any) => {
      this.entries.push(new DeadlinesEaktenSearchEntry(item.entry));
    });
  }
}

export class DeadlinesEaktenSearchPagination {
  count: number;
  hasMoreItems: boolean;
  totalItems: number;
  skipCount: number;
  maxItems: number;

  constructor(pagination: any) {
    this.count = pagination?.count;
    this.hasMoreItems = pagination?.hasMoreItems;
    this.totalItems = pagination?.totalItems;
    this.skipCount = pagination?.skipCount;
    this.maxItems = pagination?.maxItems;
  }
}

export class DeadlinesEaktenSearchEntry {
  entry: DeadlinesEaktenSearch;

  constructor(entry: any) {
    this.entry = new DeadlinesEaktenSearch(entry);
  }
}

export class DeadlinesEaktenSearch {
  id: string;
  name: string;

  constructor(deadlinesEaktenSearch: any) {
    this.id = deadlinesEaktenSearch?.id;
    this.name = deadlinesEaktenSearch?.name;
  }
}
