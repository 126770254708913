import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarDeadlinesEakteDialogData } from '../../calendar/model/calendar-deadlines-eakte-dialog-data';
import { DeadlinesEaktenSearch } from '../../common/model/eakten-search-response';

@Component({
  selector: 'deadlines-eakten-search-result-dialog',
  templateUrl: './deadlines-eakten-search-result-dialog.component.html',
  styleUrls: ['./deadlines-eakten-search-result-dialog.component.scss']
})
export class DeadlinesEaktenSearchResultDialogComponent {
  searchTerm = '';
  searchContext = 'in-dialog';

  constructor(
    public dialogRef: MatDialogRef<DeadlinesEaktenSearchResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarDeadlinesEakteDialogData
  ) {
    this.searchTerm = data.searchTerm;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectedSearchItem(item: DeadlinesEaktenSearch): void {
    this.data.deadlinesEaktenSearch = item;
    this.dialogRef.close(this.data);
  }
}
