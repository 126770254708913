import { Component, OnInit } from '@angular/core';
import { DashletActionTypes, DashletRouterTypes, LoadFavoriteLibraries, LoadRecentLibraries, LoadSearchLibraries } from '../actions/dashlet-actions';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SearchFilterConstants } from '../constants/search-filter-constants';
import { DashletActionService } from '../services/dashlet.action.service';

@Component({
  selector: 'aca-dashlet',
  templateUrl: './dashlet.component.html',
  styleUrls: ['./dashlet.component.scss']
})
export class DashletComponent implements OnInit {
  viewSubject$: Subject<LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries>;
  initAction: LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries;

  constructor(private route: ActivatedRoute, private dashletActionService: DashletActionService) {}

  ngOnInit() {
    this.viewSubject$ = new Subject<LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries>();

    this.route.queryParams.subscribe((params) => {
      const type = params[DashletRouterTypes.PARAM_SEARCH_TYPE];
      const skipCount = params[DashletRouterTypes.PARAM_SEARCH_SCIP_COUNT] || 0;
      const maxItems = params[DashletRouterTypes.PARAM_SEARCH_MAX_ITEMS] || 25;

      if (type === DashletRouterTypes.SEARCH_TYPE_RECENT_LIBRARIES) {
        this.initAction = new LoadRecentLibraries({
          skipCount: skipCount,
          maxItems: maxItems
        });
      } else if (!type || type === DashletRouterTypes.SEARCH_TYPE_FAVORITE_LIBRARIES) {
        this.initAction = new LoadFavoriteLibraries({
          skipCount: skipCount,
          maxItems: maxItems,
          where: '(EXISTS(target/site))'
        });
      } else if (type === DashletRouterTypes.SEARCH_TYPE_SEARCH_LIBRARIES) {
        this.initAction = new LoadSearchLibraries({
          term: params[DashletRouterTypes.PARAM_SEARCH_TERM] || '',
          searchFilter: SearchFilterConstants.SEARCH_FILTER_ALL,
          skipCount: skipCount,
          maxItems: maxItems
        });
      }
    });

    this.dashletActionService.onFavoriteDashletViewReload().subscribe(() => {
      if (this.initAction.type === DashletActionTypes.LOAD_FAVORITES_LIBRARIES) {
        this.viewLibraries(
          new LoadFavoriteLibraries({
            skipCount: this.initAction.payload.skipCount,
            maxItems: this.initAction.payload.maxItems
          })
        );
      }
    });
  }

  viewLibraries(action: LoadRecentLibraries | LoadFavoriteLibraries | LoadSearchLibraries): void {
    this.initAction = action;
    this.viewSubject$.next(action);
  }
}
