<div class='calendar-weekly-view-container'>

  <div class='calendar-weekly-view-date-selection'>
    <select (change)='selectedTime($event)'>
      <option *ngFor='let option of timeOptions' value='{{option}}'
              [selected]='option === selectedTimeOption'>{{option}}</option>
    </select>
  </div>

  <div class='calendar-weekly-view-events-container'>
    <div class='calendar-weekly-view-table'>

      <table class='fixed_table'>
        <thead>
        <tr>
          <td *ngFor='let header of calendarDeadlinesWeeklyModel?.dailyHeaderOfWeek; index as i'
              [ngClass]="selectedWeeklyDateIndex === i ? 'calendar-weekly-view-table-header-cell selected-table-item' : 'calendar-weekly-view-table-header-cell'">
            <div>
              <div *ngIf='calendarDeadlinesWeeklyModel?.actualDayIndex === i'
                   class='calendar-weekly-view-table-header-cell-today'></div>
              <div *ngIf='calendarDeadlinesWeeklyModel?.actualDayIndex !== i'
                   class='calendar-weekly-view-table-header-cell-not-today'></div>
              <div>{{header}}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td *ngFor='let data of calendarDeadlinesWeeklyModel?.allDayDeadlines; index as i'
              [ngClass]="data === selectedDailyEventsOfWeek ? 'calendar-weekly-view-table-data-cell selected-table-item': 'calendar-weekly-view-table-data-cell'"
              (click)='showDailyEventsOfWeekWithoutTime(data, i)'>

            <div class='data-cell-time-new'>
              <div class='data-cell-image'><img src='assets/images/calendar_today.svg' alt='all_day'></div>

              <div>
                <button (click)='newEventWithoutTime(data, i)'>+</button>
              </div>
            </div>

            <ng-template [ngIf]='data.firstEvents' [ngIfElse]='noFirstEvents'>
              <div class='data-cell-events'>
                <div
                  *ngFor='let event of data.firstEvents'>{{ (event.title.length > 20) ? (event.title | slice:0:20) + '...' : (event.title) }}</div>
              </div>
            </ng-template>
            <ng-template #noFirstEvents>
              <div class='data-cell-events'></div>
            </ng-template>

            <ng-template [ngIf]='data.totalTimeSlotEvents > 0' [ngIfElse]='noTotalEvents'>
              <div class='data-cell-total'>+ {{data.totalTimeSlotEvents}}</div>
            </ng-template>
            <ng-template #noTotalEvents>
              <div class='data-cell-total'></div>
            </ng-template>

          </td>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor='let row of calendarDeadlinesWeeklyModel?.dailyDeadlinesWithTime; index as z'>
          <td *ngFor='let data of row; index as i'
              [ngClass]="data === selectedDailyEventsOfWeek ? 'calendar-weekly-view-table-data-cell selected-table-item': 'calendar-weekly-view-table-data-cell'"
              (click)='showDailyEventsOfWeekWithTime(data, i)'>

            <span *ngIf='z >= 0 && i === 0' id='{{data?.hourlyTime}}'></span>

            <div class='data-cell-time-new'>
              <div class='data-cell-time'>{{data?.hourlyTime}}</div>
              <div>
                <button (click)='newEventWithTime(data, i)'>+</button>
              </div>
            </div>

            <ng-template [ngIf]='data.firstEvents' [ngIfElse]='noFirstEvents'>
              <div class='data-cell-events'>
                <div
                  *ngFor='let event of data.firstEvents'>{{ (event.title.length > 20) ? (event.title | slice:0:20) + '...' : (event.title) }}</div>
              </div>
            </ng-template>
            <ng-template #noFirstEvents>
              <div class='data-cell-events'></div>
            </ng-template>

            <ng-template [ngIf]='data.totalTimeSlotEvents > 0' [ngIfElse]='noTotalEvents'>
              <div class='data-cell-total'>+ {{data.totalTimeSlotEvents}}</div>
            </ng-template>
            <ng-template #noTotalEvents>
              <div class='data-cell-total'></div>
            </ng-template>

          </td>
        </tr>
        </tbody>
      </table>

    </div>


    <div class='calendar-weekly-view-details'>

      <div class='calendar-weekly-view-details-header'>
        <div class='calendar-weekly-view-details-header-items'>
          <div *ngIf='selectedDailyEventsOfWeek && !selectedDailyEventsOfWeek.hourlyTime'>
            <img src='assets/images/calendar_today.svg' alt='all_day'>
            - {{selectedDailyEventsOfWeek?.extendedTimeDailyLabel}}
          </div>
          <div *ngIf='selectedDailyEventsOfWeek && selectedDailyEventsOfWeek.hourlyTime'>
            {{selectedDailyEventsOfWeek?.extendedTimeDailyLabel}}
          </div>
          <div *ngIf='!selectedDailyEventsOfWeek'></div>
        </div>
      </div>

      <div class='calendar-weekly-view-details-events'>
        <div *ngFor='let data of selectedDailyEventsOfWeek?.hourlyDeadlineEvents'
             class='calendar-weekly-view-details-event' (click)='showDailyEvent(data)'>
          <div>
            <img src='assets/images/ft_ic_folder.svg' alt='folder'>
          </div>
          <div
            class='calendar-weekly-view-details-event-title'>{{ (data.title.length > 20) ? (data.title | slice:0:20) + '...' : (data.title) }}</div>
        </div>
      </div>

    </div>

  </div>

</div>
