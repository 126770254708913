import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../service/calendar.service';
import { DeadlinesCalendarType } from '../../../common/model/deadlines-calendar-type';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { convert, LocalDate, LocalTime, nativeJs } from '@js-joda/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CalendarSearchEventDialogComponent } from '../calendar-search-event-dialog/calendar-search-event-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDeadlinesSelectedViewType } from '../../model/calendar-deadlines-selected-view-type';

@Component({
  selector: 'app-calendar-nav',
  templateUrl: './calendar-nav.component.html',
  styleUrls: ['./calendar-nav.component.css']
})
export class CalendarNavComponent implements OnInit {
  private WEEKLY_CHANGE = 7;
  private MONTHLY_CHANGE = 1;

  searchFieldControl = new FormControl();
  searchValue$!: Subscription;
  calendarLabel = '';
  date = new FormControl(new Date());
  calendarType: DeadlinesCalendarType = DeadlinesCalendarType.WEEKLY_WORKING_TODAY;
  isWeeklyWorkingView = false;
  isWeeklyView = true;
  isMonthlyView = false;
  isActualDayView = true;

  constructor(
    private calendarService: CalendarService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      let calendarType = params['calendarType'];
      if (!calendarType) {
        calendarType = this.calendarService.getCalendarType();
      }

      const date = params['date'];
      let localDate;
      if (!date) {
        localDate = this.calendarService.getCalendarDate();
      } else {
        localDate = this.calendarService.getLocalDateFromString(date);
      }

      this.initCalendarNavigation(calendarType, localDate);
      this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
      this.calendarService.resetNavigationDateTrigger(localDate);
    });

    this.calendarService.getUpdateNavigationDateTrigger().subscribe((localDate) => {
      this.isActualDayView = localDate.compareTo(LocalDate.now()) === 0;
      this.date.setValue(convert(localDate).toDate());
      this.setCalendarType();
      this.saveCalendarNavigationProperties();
    });

    this.searchValue$ = this.searchFieldControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((searchTerm) => {
      if (searchTerm && searchTerm.trim().length >= 3) {
        this.openDialog(searchTerm);
      }
    });
  }

  openDialog(searchTerm: string): void {
    const dialogRef = this.dialog.open(CalendarSearchEventDialogComponent, {
      width: '600px',
      height: '700px',
      data: { searchTerm, deadlinesEvent: null, id: '' }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.deadlinesEvent) {
        let time = LocalTime.now().withHour(0).withMinute(0).withSecond(0).withNano(0);
        if (data.deadlinesEvent.time) {
          time = data.deadlinesEvent.time;
          this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.DEADLINES_WITH_TIME);
        } else {
          this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);
        }
        this.date.setValue(convert(data.deadlinesEvent.date).toDate());
        this.calendarService.saveCalendarDateTime(data.deadlinesEvent.date, time);
        this.pickDateView();
        this.searchFieldControl.setValue('');
      }
    });
  }

  newCalendarEvent(): void {
    this.calendarService.setCalendarDeadlinesSelectedViewType(CalendarDeadlinesSelectedViewType.ALL_DAY_DEADLINES);
    this.router.navigate([this.calendarService.getEventNewLink()]);
  }

  previousView(): void {
    const nowLocalDate = LocalDate.now();
    let localDateFromDate = LocalDate.from(nativeJs(this.date.value));
    if (this.isWeeklyViewSelected()) {
      localDateFromDate = localDateFromDate.minusDays(this.WEEKLY_CHANGE);
    } else if (this.isMonthlyViewSelected()) {
      localDateFromDate = localDateFromDate.minusMonths(this.MONTHLY_CHANGE);
    }
    this.isActualDayView = nowLocalDate.compareTo(localDateFromDate) === 0;
    this.date.setValue(convert(localDateFromDate).toDate());

    this.setCalendarType();
    this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
  }

  nextView(): void {
    const nowLocalDate = LocalDate.now();
    let localDateFromDate = LocalDate.from(nativeJs(this.date.value));
    if (this.isWeeklyViewSelected()) {
      localDateFromDate = localDateFromDate.plusDays(this.WEEKLY_CHANGE);
    } else if (this.isMonthlyViewSelected()) {
      localDateFromDate = localDateFromDate.plusMonths(this.MONTHLY_CHANGE);
    }
    this.isActualDayView = nowLocalDate.compareTo(localDateFromDate) === 0;
    this.date.setValue(convert(localDateFromDate).toDate());

    this.setCalendarType();
    this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
  }

  pickDateView(): void {
    const nowLocalDate = LocalDate.now();
    const localDateFromDate = LocalDate.from(nativeJs(this.date.value));
    this.isActualDayView = nowLocalDate.compareTo(localDateFromDate) === 0;

    this.setCalendarType();
    this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
  }

  weeklyWorkingView(): void {
    if (!this.isWeeklyWorkingView) {
      this.isMonthlyView = false;
      this.isWeeklyView = false;
      this.isWeeklyWorkingView = !this.isWeeklyWorkingView;

      this.setCalendarType();
      this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
    }
  }

  weeklyView(): void {
    if (!this.isWeeklyView) {
      this.isMonthlyView = false;
      this.isWeeklyView = !this.isWeeklyView;
      this.isWeeklyWorkingView = false;

      this.setCalendarType();
      this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
    }
  }

  monthlyView(): void {
    if (!this.isMonthlyView) {
      this.isWeeklyView = false;
      this.isMonthlyView = !this.isMonthlyView;
      this.isWeeklyWorkingView = false;

      this.setCalendarType();
      this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
    }
  }

  actualDayView(): void {
    this.isActualDayView = !this.isActualDayView;
    this.date.setValue(convert(LocalDate.now()).toDate());

    this.setCalendarType();
    this.saveCalendarNavigationPropertiesAndTriggerCalendarCalculation();
  }

  private saveCalendarNavigationProperties(): void {
    this.calendarService.saveCalendarNavigationProperties(this.calendarType, LocalDate.from(nativeJs(this.date.value)));
    this.updateURLWithNewParamsWithoutReloading();
  }

  private saveCalendarNavigationPropertiesAndTriggerCalendarCalculation(): void {
    this.saveCalendarNavigationProperties();
    this.calendarService.getCalendarLabel().subscribe((label) => {
      this.calendarLabel = label;
      this.calendarService.triggerCalendarCalculation();
    });
  }

  private initCalendarNavigation(calendarType: string, localDate: LocalDate): void {
    this.date.setValue(convert(localDate).toDate());

    this.isWeeklyWorkingView = false;
    this.isWeeklyView = false;
    this.isMonthlyView = false;
    this.isActualDayView = false;

    switch (calendarType) {
      case DeadlinesCalendarType.WEEKLY_WORKING: {
        this.calendarType = DeadlinesCalendarType.WEEKLY_WORKING;
        this.isWeeklyWorkingView = true;
        break;
      }
      case DeadlinesCalendarType.WEEKLY_WORKING_TODAY: {
        this.calendarType = DeadlinesCalendarType.WEEKLY_WORKING_TODAY;
        this.date.setValue(convert(LocalDate.now()).toDate());
        this.isWeeklyWorkingView = true;
        this.isActualDayView = true;
        break;
      }
      case DeadlinesCalendarType.WEEKLY: {
        this.calendarType = DeadlinesCalendarType.WEEKLY;
        this.isWeeklyView = true;
        break;
      }
      case DeadlinesCalendarType.MONTHLY: {
        this.calendarType = DeadlinesCalendarType.MONTHLY;
        this.isMonthlyView = true;
        break;
      }
      case DeadlinesCalendarType.MONTHLY_TODAY: {
        this.calendarType = DeadlinesCalendarType.MONTHLY_TODAY;
        this.date.setValue(convert(LocalDate.now()).toDate());
        this.isMonthlyView = true;
        this.isActualDayView = true;
        break;
      }
      default: {
        this.calendarType = DeadlinesCalendarType.WEEKLY_TODAY;
        this.date.setValue(convert(LocalDate.now()).toDate());
        this.isWeeklyView = true;
        this.isActualDayView = true;
      }
    }
  }

  private isWeeklyViewSelected(): boolean {
    return (
      this.calendarType === DeadlinesCalendarType.WEEKLY ||
      this.calendarType === DeadlinesCalendarType.WEEKLY_WORKING ||
      this.calendarType === DeadlinesCalendarType.WEEKLY_TODAY ||
      this.calendarType === DeadlinesCalendarType.WEEKLY_WORKING_TODAY
    );
  }

  private isMonthlyViewSelected(): boolean {
    return this.calendarType === DeadlinesCalendarType.MONTHLY || this.calendarType === DeadlinesCalendarType.MONTHLY_TODAY;
  }

  private isTodayViewSelected(): boolean {
    return (
      this.calendarType === DeadlinesCalendarType.WEEKLY_WORKING_TODAY ||
      this.calendarType === DeadlinesCalendarType.WEEKLY_TODAY ||
      this.calendarType === DeadlinesCalendarType.MONTHLY_TODAY
    );
  }

  private setCalendarType(): void {
    if (this.isWeeklyWorkingView && !this.isActualDayView) {
      this.calendarType = DeadlinesCalendarType.WEEKLY_WORKING;
    } else if (this.isWeeklyView && !this.isActualDayView) {
      this.calendarType = DeadlinesCalendarType.WEEKLY;
    } else if (this.isMonthlyView && !this.isActualDayView) {
      this.calendarType = DeadlinesCalendarType.MONTHLY;
    } else if (this.isWeeklyWorkingView && this.isActualDayView) {
      this.calendarType = DeadlinesCalendarType.WEEKLY_WORKING_TODAY;
    } else if (this.isWeeklyView && this.isActualDayView) {
      this.calendarType = DeadlinesCalendarType.WEEKLY_TODAY;
    } else {
      this.calendarType = DeadlinesCalendarType.MONTHLY_TODAY;
    }
  }

  private updateURLWithNewParamsWithoutReloading(): void {
    let path = location.href.split('#')[1];

    if (path.includes('?')) {
      path = path.split('?')[0];
    }

    path = `${path}?calendarType=${this.calendarType}`;

    if (!this.isTodayViewSelected()) {
      path = `${path}&date=${LocalDate.from(nativeJs(this.date.value)).toString()}`;
    }

    this.location.replaceState(path);
  }
}
