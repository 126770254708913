<div class="deadlines-search-result-container">

  <ng-template [ngIf]='isLoading' [ngIfElse]='searchResult'>
    <div class='body'>
      <mat-progress-spinner mode='indeterminate' class='deadlines-search-spinner'></mat-progress-spinner>
    </div>
    <footer></footer>
  </ng-template>

  <ng-template #searchResult>
    <ng-container *ngIf='deadlinesEaktenSearchResult.length !== 0'>
      <div class='body'>
        <mat-selection-list [multiple]='false'
                            [class]="searchContext ? 'deadlines-search-result-dialog' : 'deadlines-search-result'">
          <ng-container *ngFor='let eakte of deadlinesEaktenSearchResult; let last = last'>
            <mat-list-option (click)='selectedSearchItem(eakte)'>
              <div class='deadlines-search-result-row-container'>
                <div>
                  <img src='assets/images/ft_ic_folder.svg' alt='folder'>
                </div>
                <div>{{eakte.name}}</div>
              </div>
            </mat-list-option>
            <mat-divider *ngIf='!last'></mat-divider>
          </ng-container>
        </mat-selection-list>
      </div>

      <footer>
        <mat-divider></mat-divider>
        <mat-paginator [length]='length'
                       [pageSize]='pageSize'
                       [pageIndex]='pageIndex'
                       [pageSizeOptions]='pageSizeOptions'
                       [showFirstLastButtons]=true
                       (page)='getPageEvent($event)'
                       aria-label='Select page'>
        </mat-paginator>
      </footer>

    </ng-container>

    <ng-container *ngIf='deadlinesEaktenSearchResult.length === 0'>
      <div class='body'>
        <h3>Keine Ergebnisse.</h3>
      </div>
      <footer></footer>
    </ng-container>

  </ng-template>

</div>
