<ng-container *ngIf="(mainAction$ | async) as action">
    <ng-container [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="actionTypes.button">
            <button
                *ngIf="expanded"
                mat-stroked-button
                [id]="action.id"
                (click)="runAction(action.actions.click)"
                [disabled]="action.disabled"
                class="app-main-action-button"
                data-automation-id="app-main-action-button"
            >
                {{action.title | translate}}
            </button>

            <button
                *ngIf="expanded === false"
                mat-icon-button
                [id]="action.id"
                (click)="runAction(action.actions.click)"
                [disabled]="action.disabled"
                data-automation-id="app-main-action-icon"
                title="{{ action.title| translate }}"
            >
                <mat-icon class="main-action-menu-icon">{{action.icon}}</mat-icon>
            </button>
        </ng-container>
    </ng-container>
</ng-container>

