import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionState } from '@alfresco/adf-extensions';
import { Store } from '@ngrx/store';
import { AppStore, getAppSelection } from '@alfresco/aca-shared/store';
import { MatDialog } from '@angular/material/dialog';
import { CounterSignatureDialogComponent } from '../counter-signature-dialog/counter-signature-dialog.component';
import { NodeChildAssociation, Node } from '@alfresco/js-api';

@Component({
  selector: 'counter-signature-context-item',
  templateUrl: './counter-signature-context-item.component.html',
})
export class CounterSignatureContextItemComponent implements OnInit {
  private selection$: Observable<SelectionState>;
  private node?: NodeChildAssociation | Node;

  constructor(private store: Store<AppStore>, public dialog: MatDialog) {
    this.selection$ = this.store.select(getAppSelection);
  }

  ngOnInit() {
    this.selection$.subscribe((selectionState) => {
      if (selectionState && selectionState.file && selectionState.file.entry) {
        this.node = selectionState.file.entry;
      }
    });
  }

  openCounterSignatureDialog(): void {
    this.dialog.open(CounterSignatureDialogComponent, {
      height: '400px',
      width: '600px',
      data: this.node
    });
  }
}
