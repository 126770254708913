import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NodePaging } from '@alfresco/js-api';
import { FavoriteService } from './favorite-libraries/favorite.service';
import { SearchService } from './search-libraries/search.service';
import { map } from 'rxjs/operators';
import { DashletUtil } from './common/util/dashlet.util';
import { AppConfigService } from '@alfresco/adf-core';
import { RecentService } from './recent-libraries/recent.service';
import { DashletSearchRequest } from './common/model/dashlet.search.request';
import { DashletPageRequest } from './common/model/dashlet-page-request';

@Injectable()
export class DashletService {
  private pzsUiApi: string;

  constructor(
    private recentService: RecentService,
    private favoriteService: FavoriteService,
    private searchService: SearchService,
    private appConfig: AppConfigService
  ) {
    this.pzsUiApi = `${this.appConfig.get<string>('ecmHost')}/api/ui`;
  }

  getRecentLibraries(pagination: DashletPageRequest): Observable<NodePaging> {
    return this.recentService.getRecentLibraries(this.pzsUiApi, pagination).pipe(map((result) => DashletUtil.convertToNodePaging(result)));
  }

  getFavoriteLibraries(pagination: DashletPageRequest): Observable<NodePaging> {
    return this.favoriteService.getFavoriteLibraries(this.pzsUiApi, pagination).pipe(map((result) => DashletUtil.convertToNodePaging(result)));
  }

  getSearchLibraries(dashletSearchRequest: DashletSearchRequest): Observable<NodePaging> {
    return this.searchService.getSearchLibraries(this.pzsUiApi, dashletSearchRequest).pipe(map((result) => DashletUtil.convertToNodePaging(result)));
  }
}
