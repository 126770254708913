import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarDeadlinesEventDialogData } from '../../model/calendar-deadlines-event-dialog-data';
import { CalendarService } from '../../service/calendar.service';
import { DeadlinesEvent } from '../../../common/model/deadlines-event';
import { PageEvent } from '@angular/material/paginator';
import { LocalDate } from '@js-joda/core';

@Component({
  selector: 'app-calendar-search-event-dialog',
  templateUrl: './calendar-search-event-dialog.component.html',
  styleUrls: ['./calendar-search-event-dialog.component.css']
})
export class CalendarSearchEventDialogComponent implements OnInit {
  isLoading = false;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  deadlinesEvents: DeadlinesEvent[] = [];

  constructor(
    public dialogRef: MatDialogRef<CalendarSearchEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarDeadlinesEventDialogData,
    private calendarService: CalendarService
  ) {}

  ngOnInit(): void {
    this.search(0, 10, this.data.searchTerm);
  }

  getPageEvent(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    const skipCount = pageEvent.pageIndex * pageEvent.pageSize;
    const maxItems = pageEvent.pageSize;
    const searchTerm = this.data.searchTerm;

    this.search(skipCount, maxItems, searchTerm);
  }

  selectedSearchItem(deadlinesEvent: DeadlinesEvent): void {
    this.data.deadlinesEvent = deadlinesEvent;
    this.dialogRef.close(this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  formatDate(localDate: LocalDate): string {
    return this.calendarService.formatDate(localDate);
  }

  private search(skipCount: number, maxItems: number, searchTerm: string): void {
    this.isLoading = true;

    const now = LocalDate.now();
    const dateFrom = now.minusYears(100);
    const dateTo = now.plusYears(100);

    this.calendarService.searchDeadlines(dateFrom, dateTo, searchTerm, skipCount, maxItems).subscribe((deadlinesEventsSearchResponse) => {
      if (this.data.id !== '') {
        this.deadlinesEvents = deadlinesEventsSearchResponse.items.filter((deadlinesEventItem) => deadlinesEventItem.id !== this.data.id);
      } else {
        this.deadlinesEvents = deadlinesEventsSearchResponse.items;
      }
      this.length = deadlinesEventsSearchResponse.pagination.total;
      this.isLoading = false;
    });
  }
}
