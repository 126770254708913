import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeadlinesEaktenSearchFieldComponent } from './deadlines-eakten-search/component/deadlines-eakten-search-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeadlinesEaktenSearchService } from './deadlines-eakten-search/service/deadlines-eakten-search.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './common/service/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CalendarContainerComponent } from './calendar/component/calendar-container/calendar-container.component';
import { DeadlinesMainNavigationComponent } from './deadlines-main-navigation/component/deadlines-main-navigation.component';
import { DeadlinesAgendaComponent } from './deadlines-agenda/component/deadlines-agenda.component';
import { RouterModule } from '@angular/router';
import { DeadlinesNavigationService } from './deadlines-main-navigation/service/deadlines-navigation.service';
import { MatSelectModule } from '@angular/material/select';
import { DeadlinesAgendaService } from './deadlines-agenda/service/deadlines-agenda.service';
import { DeadlinesService } from './common/service/deadlines.service';
import { DateTimeService } from './common/service/date-time.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { CalendarNavComponent } from './calendar/component/calendar-nav/calendar-nav.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { CalendarService } from './calendar/service/calendar.service';
import { CalendarEventEditComponent } from './calendar/component/calendar-event-edit/calendar-event-edit.component';
import { CalendarMonthlyViewComponent } from './calendar/component/calendar-montly-view/calendar-monthly-view.component';
import { CalendarWeeklyViewComponent } from './calendar/component/calendar-weekly-view/calendar-weekly-view.component';
import { CalendarEventDetailsComponent } from './calendar/component/calendar-event-details/calendar-event-details.component';
import { CustomDateAdapter } from './common/util/custom-date-adapter';
import { CalendarSearchEventDialogComponent } from './calendar/component/calendar-search-event-dialog/calendar-search-event-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPrintModule } from 'ngx-print';
import { EakteService } from './common/service/eakte.service';
import { DeadlinesEaktenSearchResultComponent } from './deadlines-eakten-search/component/deadlines-eakten-search-result.component';
import { DeadlinesEaktenSearchResultDialogComponent } from './deadlines-eakten-search/component/deadlines-eakten-search-result-dialog.component';
import { ExtensionService } from '@alfresco/adf-extensions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DeadlinesContextMenuItemComponent } from './deadlines-context-menu-item/deadlines-context-menu-item.component';
import * as canCreateEventRules from './rules/calendar-menu-item-rules';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    DeadlinesEaktenSearchFieldComponent,
    DeadlinesEaktenSearchResultComponent,
    CalendarContainerComponent,
    DeadlinesMainNavigationComponent,
    DeadlinesAgendaComponent,
    CalendarNavComponent,
    CalendarEventEditComponent,
    CalendarMonthlyViewComponent,
    CalendarWeeklyViewComponent,
    CalendarEventDetailsComponent,
    CalendarSearchEventDialogComponent,
    DeadlinesEaktenSearchResultDialogComponent,
    DeadlinesContextMenuItemComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    RouterModule,
    MatSelectModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    NgxPrintModule,
    MatMenuModule,
    FormsModule
  ],
  providers: [
    DeadlinesEaktenSearchService,
    AuthService,
    DeadlinesNavigationService,
    DeadlinesAgendaService,
    DeadlinesService,
    DateTimeService,
    CalendarService,
    EakteService,
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class DeadlinesModule {
  //TODO may be read from alf service after ADW integration
  constructor(private dateAdapter: DateAdapter<Date>, extensions: ExtensionService) {
    this.dateAdapter.setLocale('de');

    extensions.setComponents({
      'deadlines.eakten.search.field.component': DeadlinesEaktenSearchFieldComponent,
      'deadlines.agenda.component': DeadlinesAgendaComponent,
      'deadlines.calendar.event.edit.component': CalendarEventEditComponent,
      'deadlines.calendar.event.details.component': CalendarEventDetailsComponent,
      'deadlines.calendar.container.component': CalendarContainerComponent,
      'deadlines.context.menu.deadlineMenuCreateNewEvent': DeadlinesContextMenuItemComponent
    });

    extensions.setEvaluators({
      canCreateEvent: canCreateEventRules.canCreateEvent
    });
  }
}
