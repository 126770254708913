<div
  [class]="showNavigation ? 'deadlines-search-field-container' : 'deadlines-search-field-container deadlines-search-field-container-margin'">

  <app-deadlines-main-navigation *ngIf='showNavigation'></app-deadlines-main-navigation>

  <div class="deadline-search-field-content">
    <div>
      <button class="deadlines-search-field-button" (click)="loadAggregatedCalendar()">Aggregierten Kalender laden
      </button>
      (Alle Fristen meiner E-Akten)
    </div>
    <div class="deadline-search-field-content-margin">oder</div>
    <div>
      <mat-form-field appearance='fill' class='deadlines-search-field'>
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Suche E-Akte (Fristenkalender einer E-Akte laden)</mat-label>
        <input matInput [formControl]='searchFieldControl' autocomplete='off'/>
      </mat-form-field>
    </div>
  </div>

  <div class="deadline-search-result">
    <deadlines-eakten-search-result [searchTerm]="searchTerm"
                                    (selectedItem)="selectedSearchItem($event)"></deadlines-eakten-search-result>
  </div>

</div>
